import { SaleEnergyPriceType } from './sizeCalcConsumer';
import { Calculation } from './sizeCalculation';

export interface  Dimensioning {
    id                                  ?: number;
    calculationId                       ?: number;
    dimensioningPeakPowerType           ?: PeakPowerType;
    withAccumulation                    ?: boolean;
    dimensioningAccumulationType        ?: AccumulationType;
    dimensioningSelfConsumptionTipology ?: SelfConsumptionType;
    withCompensation                    ?: boolean;
    dimensioningTaxes                   ?: DeductibilityType;
    dimensioningPeakPowerRangeType      ?: PeakPowerRangeType;
    dimensioningAccumulationRangeType   ?: AccumulationRangeType;
    dimensioningMatrixVariable          ?: number;
    dimensioningSelectionCriteria       ?: SelectionCriteria;
    dimensioningCovegare                ?: number;
    dimensioningUtilization             ?: number;
    dimensioningSurface                 ?: number;
    dimensioningUserMinPeakPower        ?: number;
    dimensioningUserMaxPeakPower        ?: number;
    dimensioningPeakPower               ?: number;
    dimensioningUserMinAccumulation     ?: number;
    dimensioningUserMaxAccumulation     ?: number;
    dimensioningAccumulation            ?: number;
    dimensioningIPCGeneral              ?: number;
    dimensioningIPCElectricity          ?: number;
    dimensioningElectricityTaxes        ?: number;
    dimensioningElectricitySaleTaxes    ?: number;
    dimensioningPurchaseVAT             ?: number;
    dimensioningSalesVAT                ?: number;
}

export interface DimensioningResult {
    peakPower   ?: number;
    surface     ?: number;
    dimResult   ?: DimResult;
}

export interface Result {
    peakPower            ?: number;
    surface              ?: number;
    accumulationResults  ?: DimResult[];
    isOptimal            ?: boolean;
}

export interface DimResult {
    accumulation     ?: number;
    isOptimal        ?: boolean;
    energeticResults ?: EnergeticResults;
    economicResults  ?: EconomicResults;
    costResults      ?: CostResults;
}

export interface ResultItem extends DimResult {
    uuid       ?: string;
    index      ?: number;
    surface    ?: number;
    peakPower  ?: number;
}

export interface CostResults {
    execution       ?: number;
    installation    ?: number;
    accumulation    ?: number;
    exploitation    ?: number;
    accumulationWp  ?: number;
    executionWp     ?: number;
    exploitationWp  ?: number;
    installationWp  ?: number;
}

export interface EconomicResults {
    income                ?: number;
    profitability         ?: number;
    selfConsumptionSaving ?: number;
    compensationSaving    ?: number;
    energySale            ?: number;
}

export interface EnergeticResults {
    coverage         ?: number;
    utilization      ?: number;
    solarResource    ?: number;
    consumption      ?: number;
    generation       ?: number;
    excedentary      ?: number;
    excedentaryPorc  ?: number;
    selfConsumption  ?: number;
}

export interface DimensioningResults {
    minPeakPower           ?: number;
    maxPeakPower           ?: number;
    minAccumulation        ?: number;
    maxAccumulation        ?: number;
    peakPowerResults       ?: Result[];
}
export interface DimComparativeResults {
    resultA: DimComparativeResult;
    resultB: DimComparativeResult;
}

export interface DimComparativeResult {
    coverage            ?: number;
    profitability       ?: number;
    surface             ?: number;
    totalCost           ?: number;
    totalSaving         ?: number;
    utilization         ?: number;
}

export interface  SizeComparativeInputData {
    calculation         ?: Calculation;
    dataA               ?: SizeComparativeData;
    dataB               ?: SizeComparativeData;
}

export interface SizeComparativeData {
    peakPower	            ?: number;
    tipology	            ?: SelfConsumptionType;
    withCompensation	    ?: boolean;
    withAccumulation	    ?: boolean;
    accumulationCapacity	?: number;
    dimensioningTaxes	    ?: DeductibilityType;
    saleEnergyPriceType     ?: SaleEnergyPriceType;
}

export enum PeakPowerType {
    'AUTOMATIC' = 1,
    'USER_DEFINED' = 2
}

export enum AccumulationType {
    'AUTOMATIC' = 1,
    'USER_DEFINED' = 2
}

export enum SelfConsumptionType {
    'WITH_EXCESS' = 1,
    'WITHOUT_EXCESS' = 2
}

export enum DeductibilityType {
    'DEDUCTIBLE' = 1,
    'NON_DEDUCTIBLE' = 2
}

export enum PeakPowerRangeType {
    'AUTOMATIC' = 1,
    'USER_DEFINED' = 2
}

export enum AccumulationRangeType {
    'AUTOMATIC' = 1,
    'USER_DEFINED' = 2
}

export enum RangeDimensionedType {
    'COVERAGE' = 1,
    'AREA' = 2,
    'EXPLOITATION' = 3,
    'COST' = 4,
    'SAVING' = 5
}

export enum SelectionCriteria {
    'AREA' = 1,
    'COVERAGE' = 2,
    'UTILIZATION' = 3,
    'PROFITABILITY' = 4
}
