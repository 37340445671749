import { Injectable } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { Subscription, BehaviorSubject, Observable, forkJoin } from 'rxjs';
import { EntitySelectorDialogComponent } from './entity-selector-dialog.component';
import { Entity } from '../pplus/models/entity';
import { AppConsts } from '../../../../shared/AppConsts';
import { PplusOrganitationsService } from '../pplus/services/pplus-organitations.service';
import { AppSessionService } from '@shared/common/session/app-session.service';

@Injectable({
  providedIn: 'root'
})
export class EntitySelectorService {
    private modalSubscription: Subscription;
    public modalRef: BsModalRef;
    private modalConfig = {
        animated: true,
        keyboard: true,
        backdrop: true,
        ignoreBackdropClick: false,
        class: 'modal-lg'
    };

    public entities: Entity[] = [];
    public selectedEntities: Entity[] = [];
    public selectedEntitiesIds: number[];
    public selectedEntities$: BehaviorSubject<Entity[]> = new BehaviorSubject<Entity[]>(null);

    constructor(
        private modalService: BsModalService,
        private pplusOrganitationsService: PplusOrganitationsService,
        private _sessionService: AppSessionService) {}

    init() {
        if (this._sessionService.user) {
            forkJoin([
                this.pplusOrganitationsService.getOrganitations(),
                this.pplusOrganitationsService.getDefaultOrganizationByDomain()
            ]).subscribe(([entities, defaultEntity]) => {
                this.entities = entities;
                const organizationString = localStorage.getItem(AppConsts.localStorageKeys.Organizations);
                if (organizationString) {
                    const organizations: Entity[] = JSON.parse(organizationString);
                    if (organizations) {
                        this.selectedEntities = organizations;
                        this.selectedEntitiesIds = organizations.map(e => +e.id);
                        this.selectedEntities$.next(this.selectedEntities);
                    }
                } else if ((!organizationString || organizationString  === '[]') && defaultEntity !== null) {
                    const entity = this.findEntity(defaultEntity, this.entities);
                    if (entity) {
                        this.selectedEntities = [entity];
                        this.selectedEntitiesIds = [defaultEntity];
                        this.selectedEntities$.next(this.selectedEntities);
                        localStorage.setItem(AppConsts.localStorageKeys.Organizations, JSON.stringify(this.selectedEntities) );
                    }
                }
            });
        }
    }

    getEntities() {
        return this.entities;
    }

    openEntitySelector() {
        this.modalRef = this.modalService.show(EntitySelectorDialogComponent, this.modalConfig);
        this.modalRef.content.entities = this.entities;
        this.modalRef.content.currentEntities = this.selectedEntitiesIds;
        this.modalRef.content.initEntities();
        this.modalSubscription = this.modalRef.content.onClose.subscribe(result => {
           this.selectedEntities = result;
           this.selectedEntitiesIds = result.map( (entity: Entity) => entity.id);

           this.selectedEntities$.next(this.selectedEntities);

           this.modalSubscription.unsubscribe();

           localStorage.setItem(AppConsts.localStorageKeys.Organizations, JSON.stringify(this.selectedEntities));
           window.location.reload();
           delete this.modalRef;
        });
    }

    getSelectEntities(): Observable<Entity[]> {
        return this.selectedEntities$;
    }


    findEntity(entityId: number, entities: Entity[]) {
        let selected: Entity = null;
        entities.forEach( entity => {
                if (entity.id === entityId && !selected) {
                    selected = entity;
                } else if (entity.children && entity.children.length && !selected) {
                    selected = this.findEntity(entityId, entity.children);
                }
        });

        return selected;
      }
}
