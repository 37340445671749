import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pplus-energy-sticky-card',
  templateUrl: './pplus-energy-sticky-card.component.html',
  styleUrls: ['./pplus-energy-sticky-card.component.scss']
})
export class PplusEnergyStickyCardComponent implements OnInit {

  @Input() label = '';
  @Input() value;
  @Input() unit = '';
  @Input() color;

  constructor() { }

  ngOnInit() {
  }

}
