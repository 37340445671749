import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Optional, Output, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { CellValueChangedEvent, GridApi, GridOptions, RowValueChangedEvent } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { PplusBoolFilterComponent } from './pplus-checkbox-floating-filter/pplus-bool-filter/pplus-bool-filter.component';
import { PplusCheckboxFloatingFilter } from './pplus-checkbox-floating-filter/pplus-checkbox-floating-filter';
import { PplusTableEditCheckboxComponent } from './pplus-table-edit-checkbox/pplus-table-edit-checkbox.component';
import { PplusTableEditInputComponent } from './pplus-table-edit-input/pplus-table-edit-input.component';
import { PplusTableEditNumberComponent } from './pplus-table-edit-number/pplus-table-edit-number.component';
import { PplusTableEditRangeSelectComponent } from './pplus-table-edit-range-select/pplus-table-edit-range-select.component';
import { PplusTableEditSelectComponent } from './pplus-table-edit-select/pplus-table-edit-select.component';
import { PplusTableMoreOptionsRendererComponent } from './pplus-table-more-options-renderer/pplus-table-more-options-renderer.component';
import { PplusTableTooltipComponent } from './pplus-table-tooltip/pplus-table-tooltip.component';



@Component({
  selector: 'pplus-table',
  templateUrl: './pplus-table.component.html',
  styleUrls: ['./pplus-table.component.scss']
})
export class PplusTableComponent implements OnInit, ControlValueAccessor {

  public _options;
  public skipCount = 0;
  firstPage = false;
  @Input() set options(value: any) {
    this._options = value;
    this.fillTable();
  }

  @Input() class;
  @Input() pagination = false;

  @Input() modules = [];

  @Output() gridReady = new EventEmitter<any>();

  @Output() nextPageEvent = new EventEmitter<any>();
  @Output() previousPageEvent = new EventEmitter<any>();
  @Output() moreOptionsEvent = new EventEmitter<any>();

  rowClassRules = {
    'white': function (params) {
      return params.node.rowIndex % 2 === 0;
    }
  };

  private defaultOptions = {
    editType: 'fullRow',
    suppressClickEdit: true,
    stopEditingWhenGridLosesFocus: true,
    rowClassRules: this.rowClassRules,
    rowHeight: 30,
    headerHeight: 25,
    domLayout: 'autoHeight'
  };

  frameworkComponents = {
    'inputEditor': PplusTableEditInputComponent,
    'numberEditor': PplusTableEditNumberComponent,
    'selectEditor': PplusTableEditSelectComponent,
    'rangeEditor': PplusTableEditRangeSelectComponent,
    'checkActive': PplusTableEditCheckboxComponent,
    'checkboxFilter': PplusCheckboxFloatingFilter,
    'boolFilter': PplusBoolFilterComponent,
    'moreOptions': PplusTableMoreOptionsRendererComponent,
    'customTooltip': PplusTableTooltipComponent
  };

  isVisible = false;
  gridOptions: GridOptions;
  gridOptions$: Observable<GridOptions>;

  private gridApi: GridApi;
  private gridColumnApi;
  row: Map<string, number>;
  column: Map<string, string>;
  context: any;

  domLayout = 'autoHeight';

  value: any;
  onChangeFn = (_: any) => { };
  onTouched = (_: any) => { };

  constructor(
    private elementRef: ElementRef,
    @Self() @Optional() private ngControl: NgControl
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
    this.context = {componentParent: this};
  }
  ngOnInit() {

    const element = this.elementRef.nativeElement;

  }

  /**
   * Write form value to the DOM element (model => view)
   */
  writeValue(value: any): void {
    this.value = value;
  }

  /**
   * Write form disabled state to the DOM element (model => view)
   */
  setDisabledState(isDisabled: boolean): void {

  }

  /**
   * Update form when DOM element value changes (view => model)
   */
  registerOnChange(fn: any): void {
    // Store the provided function as an internal method.
    this.onChangeFn = fn;
  }

  /**
   * Update form when DOM element is blurred (view => model)
   */
  registerOnTouched(fn: any): void {
    // Store the provided function as an internal method.
    this.onTouched = fn;
  }

  onChangeValue(event) {
  }


  fillTable() {
    this.gridOptions = { ...this.defaultOptions, ...this._options };
    if (this.gridApi) {
      this.gridApi.setRowData(this._options.rowData);
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.setDomLayout('autoHeight');
    this.gridReady.emit(params);
  }

  deselectAllRows() {
    if (this.gridApi) {
        this.gridApi.deselectAll();
    }
  }


  hideColumn(name, columnDefs) {

    const colDef = columnDefs.find(obj => {
        if(obj.headerName !== undefined && obj.headerName === name){
            obj.hide = true;
            return obj;
        }
    });

    this.gridColumnApi.setColumnVisible(colDef.field, false);
  }

  showColumn(name, columnDefs) {

    const colDef = columnDefs.find(obj => {
        if(obj.headerName !== undefined && obj.headerName === name){
            obj.hide = false;
            return obj;
        }
    });

    this.gridColumnApi.setColumnVisible(colDef.field, true);
  }

  hideShowColumnDefsTable(filteredColumns, columnDefs){
    filteredColumns.forEach((value, index) => {
        if (filteredColumns[index].selected) {
            this.showColumn(value.name, columnDefs);
        }else{
            this.hideColumn(value.name, columnDefs);
        }
    });
  }

  rowChanged(event: RowValueChangedEvent) {
    let tableData = [];
    this.gridApi.forEachNode(node => tableData.push(node.data));
    this.onChangeFn(this.value);
    this.value = tableData;
  }

  cellChanged(event: CellValueChangedEvent) {
    let tableData = [];
    this.gridApi.forEachNode(node => tableData.push(node.data));
    this.onChangeFn(this.value);
    this.value = tableData;
  }

  @HostListener('window:resize')
  onResize() {
    if (!this.gridApi) { return; }
    setTimeout(() => {
      this.gridApi.sizeColumnsToFit();
    });
  }

  openProfileView(event) {
    console.log('event in table => ', event);
  }

  openMoreOptions(cell: any) {
    this.moreOptionsEvent.emit(cell);
  }

  onGridSizeChanged(e) {
      this.onResize();
  }

  onPageSizeChanged(newPageSize) {
    const pageSizevalue = (<HTMLInputElement>document.getElementById('page-size')).value;
    this.gridApi.paginationSetPageSize(Number(pageSizevalue));
  }

  previousPage() {
    this.skipCount -= 5;
    if(this.skipCount < 5){
        this.firstPage = true;
    }else{
        this.firstPage = false;
    }
    this.previousPageEvent.emit(this.skipCount);
  }

  nextPage() {
    this.skipCount += 5;
    this.nextPageEvent.emit(this.skipCount);
  }

}
