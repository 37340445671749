export interface ComparativeTableDataModel {
    horizontalHeaders: string[];
    verticalHeaders: string[];
    data: any[][];
    dataType: ComparativeDataTypeEnum[];
}

export enum ComparativeDataTypeEnum {
    PERCENTAGE = 'percentage',
    NUMBER = 'number'
}
