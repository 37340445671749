import { Component, EventEmitter, Input, OnInit, Optional, Output, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'pplus-switch-icons',
  templateUrl: './pplus-switch-icons.component.html',
  styleUrls: ['./pplus-switch-icons.component.scss']
})
export class PplusSwitchIconsComponent implements OnInit, ControlValueAccessor {

  @Input() options;
  @Input() disabled = false;
  @Input() value;

  @Output() onChange: EventEmitter<any> = new EventEmitter();
  selectedButton: any;

  constructor(
    @Self() @Optional() private ngControl: NgControl
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }

  }

  onChangeFn = (_: any) => { };

  ngOnInit() {
  }

  /**
   * Write form value to the DOM element (model => view)
   */
  writeValue(value: any): void {
    this.value = value;
  }

  /**
   * Write form disabled state to the DOM element (model => view)
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Update form when DOM element value changes (view => model)
   */
  registerOnChange(fn: any): void {
    // Store the provided function as an internal method.
    this.onChangeFn = fn;
  }

  /**
   * Update form when DOM element is blurred (view => model)
   */
  registerOnTouched(fn: any): void {
    // Store the provided function as an internal method.
    this.onTouched = fn;
  }

  onChangeEvent(event) {
    this.onChangeFn(this.value);
    this.onChange.emit(this.value);
  }

  private onTouched() { }

}
