import { Injectable} from '@angular/core';
import { Observable, of } from 'rxjs';
import { Plant } from '../models/plant';
import { HttpClient } from '@angular/common/http';
import { AppConsts } from '@shared/AppConsts';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PplusPlantsService {

    constructor(private http: HttpClient) {
    }

    getPlants(): Observable<Plant[]> {
        return this.http.get<Plant[]>(AppConsts.remoteServiceBaseUrl + '/api/services/app/Installations/GetTree').pipe(
            map((response: any) =>  response.result.plants)
        );
    }

}
