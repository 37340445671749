import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConsts } from '@shared/AppConsts';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class PplusOrganitationsService {

    organizationOwnerId = undefined;

    constructor(
        private http: HttpClient,
    ) {
    }

    getOrganitations() {
        const headers = {
            'X-Skip-EntityInterceptor': ''
        };

        return this.http.get(AppConsts.remoteServiceBaseUrl + '/api/services/app/Organizations/GetTree', { headers }).pipe(
            map((response: any) => response.result)
        );
    }

    getDefaultOrganizationByDomain(): Observable<number> {
        const headers = {
            'X-Skip-EntityInterceptor': ''
        };
        return this.http.get<number>(AppConsts.remoteServiceBaseUrl + '/api/services/app/Organizations/GetOrganizationIdFromDomain?domain=' + document.location.hostname, { headers }).pipe(
            map((response: any) => response.result),
            tap((organizationOwnerId) => {
                this.organizationOwnerId = organizationOwnerId;
            })
        );
    }

}
