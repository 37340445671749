import { TabsService } from '@app/shared/common/tabs/tabs.service';
import { Component, OnInit, Input, ViewChild, ViewContainerRef, HostBinding } from '@angular/core';

@Component({
  selector: 'app-tab-content',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss']
})
export class TabContentComponent implements OnInit {

  @Input() tabI: any;
  @ViewChild('container', { static: true, read: ViewContainerRef }) entry: ViewContainerRef;
  @HostBinding('hidden')
  hidden = true;
  isNameEditable = false;

  constructor(
    private tabsService: TabsService,
  ) { }

  ngOnInit() {

    this.tabsService._selectedTab.subscribe( (selected: number) => {
        if (selected !== null) {
            const currentTab = this.tabsService.getSelectedTab();
            if (currentTab.instance.tabI.uuid === this.tabI.uuid) {
                this.hidden = false;
            } else {
                this.hidden = true;
            }
        }

    });
  }

}
