import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
    selector: 'pplus-donut-chart',
    templateUrl: './donut-chart.component.html',
    styleUrls: ['./donut-chart.component.scss']
})
export class PplusDonutChartComponent implements OnChanges {

    @Input() data: DonutChartData;

    chartOptions = {
        legend: [{
            show: false
        }],
        grid: {
            left: '-1%',
            right: '-1%',
            bottom: '0',
            containLabel: false
        },
        series: [
            {
                name: 'nombre',
                type: 'pie',
                radius: ['65%', '85%'],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: 'center'
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: '18',
                        fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false
                },
                data: []
            }
        ],
        color: []
    };

    constructor() { }

    ngOnChanges(changes: SimpleChanges): void {
        this.chartOptions.legend[0] = {...this.chartOptions.legend[0], ...this.data.legend[0]};
        this.chartOptions.series[0].data = this.data.data;
        this.chartOptions.color = this.data.colors;
        this.chartOptions = {...this.chartOptions, ...this.chartOptions};
    }

}

export interface DonutChartData {
    legend?: any;
    data: DonutChartItemData[];
    colors: string[];
}

export interface DonutChartItemData {
    name: string;
    value: number;
}
