import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, IAfterGuiAttachedParams, RowNode } from 'ag-grid-community';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'pplus-table-checkbox-select-renderer',
  templateUrl: './pplus-table-checkbox-select-renderer.component.html',
  styleUrls: ['./pplus-table-checkbox-select-renderer.component.scss']
})
export class PplusTableCheckboxSelectRendererComponent implements ICellRendererAngularComp {

  params: any;
  selected = false;

  constructor() {}

  refresh(params: any): boolean {
    console.log('entra en refresh');
    return false;
  }
  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.selected = this.params.node.isSelected();
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    //TODO
  }

  toggle() {
      const row: RowNode = this.params.node;
      row.setSelected(!row.isSelected());
      this.params.api.redrawRows();

  }
}


