import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SlideColors } from '../../components/pplus-slide-toggle/pplus-slide-colors-enum';

@Component({
  selector: 'pplus-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class PplusLineChartComponent implements OnInit, OnChanges {

  @Input() data: LineChartData;

  lineWidth = 1;

  chartOptions = {
    xAxis: {
      type: 'category',
      data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
    },
    yAxis: {
      type: 'value',
      splitLine: {
        lineStyle: {
          type: 'dashed',
        }
      }
    },
    legend: [{
        show: false
    }],
    series: [],
    color: ['#ffffff', '#ffffff']
  };


  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    this.setData();
  }

  setData() {
    this.chartOptions.xAxis.data = this.data.divisors;
    let series: LineEchartSerie[] = [];
    this.data.series.forEach((serie) => {
      let echartSerie: LineEchartSerie = {
        data: serie.data,
        type: 'line',
        lineStyle: {
          width: serie.width ? serie.width : this.lineWidth,
          color: serie.color
        },
        name: serie.name ? serie.name : ''
      };
      series.push(echartSerie);
    });
    this.chartOptions.series = series;
    this.chartOptions.legend[0] =  {...this.chartOptions.legend[0], ...this.data.legend};
    this.chartOptions = {...this.chartOptions, ...this.chartOptions};
  }

  ngOnInit() {
  }

  setSerie() {

  }

}

export interface LineChartData {

  series: LineChartSerie[];
  divisors: string[];
  legend?: any;

}

export interface LineChartSerie {
  data: number[];
  color: string;
  width?: number;
  name?: string;
}

export interface LineEchartSerie {
  data: number[];
  type: string;
  name?: string;
  lineStyle: {
    width: number,
    color: string
  }
}
