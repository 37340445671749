import { Component, OnInit, Input, Output, EventEmitter, Self, Optional } from '@angular/core';
import { NgControl, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-pplus-checkbox',
  templateUrl: './pplus-checkbox.component.html',
  styleUrls: ['./pplus-checkbox.component.scss']
})
export class PplusCheckboxComponent implements OnInit, ControlValueAccessor {


  @Input() disabled: boolean;
  @Input() readonly: boolean;
  @Input() binary = true;
  @Input() label: string;
  @Input() value = false;
  @Input() styleClass: string;



  @Output() onChange: EventEmitter<any> = new EventEmitter();
  @Output() onClick: EventEmitter<any> = new EventEmitter();

  selected: any;

  constructor(
    @Self() @Optional() private ngControl: NgControl
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }
  ngOnInit() {
  }

  writeValue(obj: any): void {
    if (this.binary) {
        this.value = obj;
    } else {
        this.selected = obj;
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }
  registerOnTouched(fn: any): void {
      this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
      this.disabled = isDisabled;
  }

    onChangeFn = (_: any) => { };
    onTouched = (_: any) => { };

    onChangeValue(event) {
        if (this.binary) {
            this.onChangeFn(this.value);
            this.onChange.emit(this.value);
        } else {
            this.onChangeFn(this.selected);
            this.onChange.emit(this.selected);
        }

        this.onClick.emit(event);
    }

}
