import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, IAfterGuiAttachedParams } from 'ag-grid-community';

@Component({
  selector: 'pplus-table-icon-renderer',
  templateUrl: './pplus-table-icon-renderer.component.html',
  styleUrls: ['./pplus-table-icon-renderer.component.scss']
})
export class PplusTableIconRendererComponent implements ICellRendererAngularComp {

  params: any;
  editActive = false;

  constructor() { }
  refresh(params: any): boolean {
    //TODO
    return false;
  }
  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    //TODO
  }

  btnClickedHandler() {
    console.log(this.params.node);
    this.params.clicked(this.params.node);
  }

}
