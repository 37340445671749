import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EntitySelectorService } from './entity-selector.service';

@Injectable()
export class EntityInterceptor implements HttpInterceptor {

  constructor(
    private entitySelectorService: EntitySelectorService
  ) {}


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.headers.has('X-Skip-EntityInterceptor')) {
        const headers = req.headers.delete('X-Skip-EntityInterceptor');
        return next.handle(req.clone({ headers }));
    }

    const selected: number[] = this.entitySelectorService.selectedEntities.map(entity => entity.id);
    let headers = req.headers;

    if (!selected || !selected.length ) {
        headers = headers.set('X-Organizations', '');
        return next.handle(req.clone({headers}));
    } else {

        headers = headers.set('X-Organizations', selected.map( entity => entity.toString()));
        return next.handle(req.clone({headers}));
    }

  }
}
