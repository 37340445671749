import { Component, AfterViewInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'pplus-table-edit-checkbox',
  templateUrl: './pplus-table-edit-checkbox.component.html'
})

export class PplusTableEditCheckboxComponent implements ICellRendererAngularComp {

    params: any;
    value: number;

    @ViewChild('input', { read: ViewContainerRef, static: false }) public input;

    agInit(params): void {
        this.params = params;
        this.value = params.value;
    }

    refresh(params?: any): boolean {
        return params;
    }
}
