import { Injectable, ComponentRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TabContentComponent } from './tab.component';

@Injectable({
  providedIn: 'root'
})
export class TabsService {
  // FIXME: create tab class
  tabList: ComponentRef<TabContentComponent>[];
  selected: number;
  _tabList: BehaviorSubject<any[]> = new BehaviorSubject(null);
  _selectedTab: BehaviorSubject<number> = new BehaviorSubject(null);
  private _currentAppUrl: string = null;

  constructor() {
      this.tabList = [];
  }

  hasTabs(): boolean {
    return this.tabList.length > 0;
  }

  addTab(component: ComponentRef<TabContentComponent>) {
    this.tabList.push(component);
    this.selectTab(this.tabList.length - 1);
    this._tabList.next(this.tabList);
  }

  removeTab(tab) {
      const index = this.tabList.indexOf(tab);
      if (index !== -1) {
          this.tabList[index].hostView.destroy();
          this.tabList[index].destroy();
          this.tabList.splice(index, 1);
          this._tabList.next(this.tabList);
          if (this.tabList.length > 0) {
            this.selectTab(this.tabList.length - 1);
          } else {
            this.selectTab(null);
          }
      }
  }

  selectTab(index: number) {
    this.selected = index;
    this.tabList.forEach(t => {
        t.instance.hidden = true;
    });
    this._selectedTab.next(this.selected);

  }

  deleteCurrentTab() {
    if (this.selected !== null) {
        this.tabList[this.selected].hostView.destroy();
        this.tabList[this.selected].destroy();
        this.tabList.splice(this.selected, 1);
        this._tabList.next(this.tabList);
        if (this.tabList.length > 0) {
            this.selectTab(this.tabList.length - 1);
        } else {
            this.selectTab(null);
        }
    }
  }

  tabByUUID(uuid: string) {
    return this.tabList.find( tab => tab.instance.tabI.uuid === uuid);
  }

  getSelectedTab(): ComponentRef<TabContentComponent> {
    if (this.tabList.length && this.selected !== null && this.selected !== undefined) {
        return this.tabList[this.selected];
    } else if (this.tabList.length) {
        return this.tabList[0];
    } else {
        return null;
    }

  }

  public get currentAppUrl(): string {
    return this._currentAppUrl;
  }

  public set currentAppUrl(value: string) {
    this._currentAppUrl = value;
  }
}
