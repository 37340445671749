import { Component } from '@angular/core';
import { IFloatingFilter, IFloatingFilterParams} from 'ag-grid-community';
import { AgFrameworkComponent} from 'ag-grid-angular';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
export interface AgBoolFloatingFilterParams extends IFloatingFilterParams {
    state: boolean;
    currentValue: boolean;
}
@Component({
    selector: 'pplus-checkbox-floating-filter',
    template: `<pplus-tristate-checkbox [(ngModel)]="state" (ngModelChange)="valueChanged()" [label]="name"></pplus-tristate-checkbox>`
})
export class PplusCheckboxFloatingFilter
implements IFloatingFilter, AgFrameworkComponent<AgBoolFloatingFilterParams> {

    params: AgBoolFloatingFilterParams;
    state: boolean;
    currentValue: boolean;
    valueToUse = '';
    name = this._appLocalizationService.l('ALL');

    constructor(private _appLocalizationService: AppLocalizationService) {}

    agInit(params: AgBoolFloatingFilterParams): void {
        this.params = params;
        this.state = null;
    }

    onParentModelChanged(parentModel: any): void {
        if (!parentModel) {
            this.state = null;
        } else {
            this.state = parentModel.filter === 'True';
        }
    }

    setValue() {
        if (this.valueToUse === '') {
            this.valueToUse = 'True';
            this.name = this._appLocalizationService.l('ACTIVE');
        } else if (this.valueToUse === 'True') {
            this.valueToUse = 'False';
            this.name = this._appLocalizationService.l('INACTIVE');
        } else{
            this.valueToUse = '';
            this.name = this._appLocalizationService.l('ALL');
        }
    }

    valueChanged() {
        this.setValue();
        this.params.parentFilterInstance((instance: any) => instance.onFloatingFilterChanged('contains', this.valueToUse));
    }
}
