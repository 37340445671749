import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InstallationSelection } from '@app/shared/common/pplus/models/view/InstallationSelection';

@Component({
  selector: 'pplus-header-select',
  templateUrl: './pplus-header-select.component.html',
  styleUrls: ['./pplus-header-select.component.scss']
})
export class PplusHeaderSelectComponent implements OnInit {

  @Input() installationSelected: InstallationSelection;
  @Input() unit = 'Wp';

  @Output() onClick = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }
}
