import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PplusSizeService } from '@app/size/common/services/size.service';
import { DecimalPipe } from '@angular/common';
import { RangeDimensionedType, ResultItem } from '@app/size/common/models/sizeCalcDimensioning';


@Component({
    selector: 'app-pplus-matrix-collapsed',
    templateUrl: './pplus-matrix-collapsed.component.html',
    styleUrls: ['./pplus-matrix-collapsed.component.scss']
})
export class PplusMatrixCollapsedComponent implements OnInit {

    RangeDimensionedType = RangeDimensionedType;

    @Input() optionSelected: ResultItem;
    @Input() options: ResultItem[];
    @Input() elementToShow: RangeDimensionedType;
    @Input() disabled: boolean;
    @Output() onChange: EventEmitter<any> = new EventEmitter();



    constructor(private pplusSizeService: PplusSizeService, private _decimalPipe: DecimalPipe) { }

    ngOnInit() {
        this.pplusSizeService.updateDimensioningRowSelected(this.options);

    }

    getValue(index: number): string {
        let value = '';
        let option = this.options[index];
        switch (this.elementToShow) {
            case RangeDimensionedType.AREA:
                value = this._decimalPipe.transform(option.surface, '1.2-2', 'es-Es') + 'm²';
                break;
            case RangeDimensionedType.COST:
                value = this._decimalPipe.transform(option.costResults.installation, '1.2-2', 'es-Es') + '€';
                break;
            case RangeDimensionedType.COVERAGE:
                value = this._decimalPipe.transform(100 * option.energeticResults.coverage, '1.2-2', 'es-Es') + '%';
                break;
            case RangeDimensionedType.EXPLOITATION:
                value = this._decimalPipe.transform(100 * option.energeticResults.utilization, '1.2-2', 'es-Es') + '%';
                break;
            case RangeDimensionedType.SAVING:
                value = this._decimalPipe.transform(option.economicResults.income, '1.2-2', 'es-Es') + '€';
                break;
        }
        return value;
    }
}
