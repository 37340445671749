import { Component, OnInit } from '@angular/core';
import { AppNavigationService } from '@app/shared/layout/nav/app-navigation.service';
import { AppMenu } from '@app/shared/layout/nav/app-menu';
import { AppAvailableModel, AppModel } from '@shared/common/ui/components/pplus-application-card/appModel';
import { DomSanitizer } from '@angular/platform-browser';
import { PplusSettingsService } from '@app/shared/common/pplus/services/pplus-settings.service';

@Component({
  selector: 'pplus-dashboard',
  templateUrl: './pplus-dashboard.component.html',
  styleUrls: ['./pplus-dashboard.component.scss']
})
export class PplusDashboardComponent implements OnInit {

  menu: AppMenu;

  apps: AppModel[] = [];

  organizationLogo;

  constructor(
    private appNavigationService: AppNavigationService, 
    private domSanitizer: DomSanitizer,
    private pplusSettingsService: PplusSettingsService
    ) { }

  ngOnInit() {
    this.appNavigationService.getAvailableMenu().subscribe((response: AppAvailableModel[]) => {
      if (response) {
        this.apps = response.map((item) => {
          let imageURL;
          if (item.imageBase64) {
            imageURL = this.domSanitizer.bypassSecurityTrustUrl(item.imageBase64);
            if (!item.imageBase64.includes('data:image')) {
              imageURL = this.domSanitizer.bypassSecurityTrustUrl('data:image/svg+xml;base64,' + item.imageBase64);
            }
          }
          return {
            title: item.name,
            description: item.description,
            img: item.imageBase64 ? imageURL : null,
            src: item.principalModuleRoute
          };
        });
      }
    });

    this.organizationLogo = this.pplusSettingsService.getLogo() ? this.domSanitizer.bypassSecurityTrustUrl(this.pplusSettingsService.getLogo()) : '';
  }

  openPVWeb() {
    window.open('https://proyectapv.com/contacto/', '_blank');
  }

}
