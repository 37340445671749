import { Component, EventEmitter, Output } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, IAfterGuiAttachedParams } from 'ag-grid-community';

@Component({
    selector: 'pplus-table-more-options-renderer',
    templateUrl: './pplus-table-more-options-renderer.component.html',
    styleUrls: ['./pplus-table-more-options-renderer.component.scss']
})
export class PplusTableMoreOptionsRendererComponent implements ICellRendererAngularComp {

    params: any;

    constructor() { }
    refresh(params: any): boolean {
        //TODO
        return false;
    }

    agInit(params: ICellRendererParams): void {
        this.params = params;
    }

    afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
        //TODO
    }

    public doEdit() {
        this.params.context.componentParent.openMoreOptions(this.params.data.id);
    }

}
