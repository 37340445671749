import { DecimalPipe } from '@angular/common';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { RangeDimensionedType, DimResult, ResultItem } from '@app/size/common/models/sizeCalcDimensioning';

@Component({
    selector: 'app-pplus-matrix-expanded',
    templateUrl: './pplus-matrix-expanded.component.html',
    styleUrls: ['./pplus-matrix-expanded.component.scss']
})
export class PplusMatrixExpandedComponent {

    @Input() data;
    @Input() elementToShow: RangeDimensionedType;
    @Output() onChange = new EventEmitter();
    @Input() cellSelected: DimResult;

    constructor(
        private _decimalPipe: DecimalPipe
    ) { }

    getOptionSelected(item: ResultItem) {
        let value = '';
        switch (this.elementToShow) {
            case RangeDimensionedType.AREA:
                value = this._decimalPipe.transform(item.surface, '1.2-2', 'es-Es') + 'm²';
                break;
            case RangeDimensionedType.COST:
                value = this._decimalPipe.transform(item.costResults.installation, '1.2-2', 'es-Es') + '€';
                break;
            case RangeDimensionedType.COVERAGE:
                value = this._decimalPipe.transform(100 * item.energeticResults.coverage, '1.2-2', 'es-Es') + '%';
                break;
            case RangeDimensionedType.EXPLOITATION:
                value = this._decimalPipe.transform(100 * item.energeticResults.utilization, '1.2-2', 'es-Es') + '%';
                break;
            case RangeDimensionedType.SAVING:
                value = this._decimalPipe.transform(item.economicResults.income, '1.2-2', 'es-Es') + '€';
                break;
        }
        return value;
    }

    onCellChange(item: ResultItem, rowIndex: number) {
        this.onChange.emit([rowIndex, item.index]);
    }

}
