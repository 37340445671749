import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { ButtonModule } from 'primeng/button';
import { DialogService as PrimeNgDialogService } from 'primeng/api';

@NgModule({
  declarations: [ConfirmDialogComponent],
  imports: [
    CommonModule,
    TranslateModule,
    DynamicDialogModule,
    ButtonModule,
  ],
  entryComponents: [
    ConfirmDialogComponent
  ],
  providers: [PrimeNgDialogService],
})
export class DialogsModule { }
