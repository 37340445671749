import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabSetComponent } from './tabset.component';
import { TabContentComponent } from './tab.component';
import { TranslateModule } from '@ngx-translate/core';
import { UtilsModule } from '@shared/utils/utils.module';
import { TabWrapperItemComponent } from './tab-wrapper-item/tab-wrapper-item.component';

@NgModule({
  declarations: [
    TabSetComponent,
    TabContentComponent,
    TabWrapperItemComponent
  ],
  exports: [
    TabSetComponent,
    TabContentComponent,
    TabWrapperItemComponent
  ],
  imports: [
    CommonModule,
    UtilsModule,
    FormsModule
  ],
  entryComponents: [
    TabSetComponent,
    TabContentComponent
  ]
})
export class PPlusTabsModule { }
