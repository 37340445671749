import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Toast } from 'primeng/toast';
import { deflate } from 'zlib';

@Component({
  selector: 'app-pplus-toast',
  templateUrl: './pplus-toast.component.html',
  styleUrls: ['./pplus-toast.component.scss']
})
export class PplusToastComponent implements OnInit {

  @Input() header: string;
  @Input() content: string;
  @Input() toastType = 'Closed';
  @Input() toastSeverity: string;
  @Output() onClose: EventEmitter<any> = new EventEmitter();

  icon: string;


  isExpanded: Boolean = false;


  constructor() { }

  ngOnInit() {
    this.isExpanded = this.toastType.toLowerCase() === 'expanded';
    switch (this.toastSeverity.toLowerCase()) {
      case 'advice':
        this.icon = 'size-adviceicon';
        break;
      case 'error':
        this.icon = 'size-erroricon';
        break;
      case 'info':
        this.icon = 'size-infoicon';
        break;
      case 'validate':
        this.icon = 'size-validateicon';
        break;
      default:
        this.icon = 'size-validateicon';
    }
  }

}

//Posibles configuraciones:

/*-----Componente con Icono-----*/

/*--Componente advice--*/
//<app-pplus-toast [toastSeverity]="'advice'" [header]="'ADVICE'" [content]="'...'"></app-pplus-toast>

/*--Componente error--*/
//<app-pplus-toast [toastSeverity]="'error'" [header]="'ERROR'" [content]="'...'"></app-pplus-toast>

/*--Componente info--*/
//<app-pplus-toast [toastSeverity]="'info'" [header]="'INFO'" [content]="'...'"></app-pplus-toast>

/*--Componente validate--*/
//<app-pplus-toast [toastSeverity]="'validate'" [header]="'VALIDATE'" [content]="'...'"></app-pplus-toast>

/*-----Componente sin Icono-----*/

/*--Componente advice--*/
//<app-pplus-toast [toastSeverity]="'advice'" [toastType]="'expanded'" [header]="'ADVICE'" [content]="'...'"></app-pplus-toast>

/*--Componente error--*/
//<app-pplus-toast [toastSeverity]="'error'" [toastType]="'expanded'"  [header]="'ERROR'" [content]="'...'"></app-pplus-toast>

/*--Componente info--*/
//<app-pplus-toast [toastSeverity]="'info'" [toastType]="'expanded'"  [header]="'INFO'" [content]="'...'"></app-pplus-toast>

/*--Componente validate--*/
//<app-pplus-toast [toastSeverity]="'validate'" [toastType]="'expanded'"  [header]="'VALIDATE'" [content]="'...'"></app-pplus-toast>
