import {
    AfterContentInit, Component,
    ContentChildren, ElementRef, EventEmitter,
    forwardRef, Inject, Input,
    OnChanges, OnDestroy, OnInit, Output,
    QueryList, SimpleChanges, TemplateRef
} from '@angular/core';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { Subscription } from 'rxjs';
import { DomHandler } from '../../../../../../node_modules/primeng/components/common/api';
import { BlockableUI } from '../../../../../../node_modules/primeng/components/common/blockableui';
import { PrimeTemplate } from '../../../../../../node_modules/primeng/components/common/shared';
import { ObjectUtils } from '../../../../../../node_modules/primeng/components/utils/objectutils';
import { PplusTreeMode } from './pplus-tree.interfaces';



export interface TreeNode {
    label?: string;
    data?: any;
    icon?: any;
    expandedIcon?: any;
    collapsedIcon?: any;
    children?: TreeNode[];
    leaf?: boolean;
    expanded?: boolean;
    type?: string;
    parent?: TreeNode;
    partialSelected?: boolean;
    styleClass?: string;
    draggable?: boolean;
    droppable?: boolean;
    selectable?: boolean;
    key?: string;
}

@Component({
    selector: 'pplus-treeNode',
    styleUrls: ['./pplus-tree.component.scss'],
    template: `
        <ng-template [ngIf]="node">
            <li *ngIf="tree.droppableNodes" class="ui-treenode-droppoint" [ngClass]="{'ui-treenode-droppoint-active ui-state-highlight':draghoverPrev}"
            (drop)="onDropPoint($event,-1)" (dragover)="onDropPointDragOver($event)" (dragenter)="onDropPointDragEnter($event,-1)" (dragleave)="onDropPointDragLeave($event)"></li>
            <li *ngIf="!tree.horizontal" role="treeitem" [ngClass]="['ui-treenode',node.styleClass||'', isLeaf() ? 'ui-treenode-leaf': '']">
                <div class="ui-treenode-content" (click)="onNodeClick($event)" (contextmenu)="onNodeRightClick($event)" (touchend)="onNodeTouchEnd()"
                    (drop)="onDropNode($event)" (dragover)="onDropNodeDragOver($event)" (dragenter)="onDropNodeDragEnter($event)" (dragleave)="onDropNodeDragLeave($event)"
                    [draggable]="tree.draggableNodes" (dragstart)="onDragStart($event)" (dragend)="onDragStop($event)" tabIndex="0"
                    [ngClass]="{'ui-treenode-selectable':tree.selectionMode && node.selectable !== false,'ui-treenode-dragover':draghoverNode, 'ui-treenode-content-selected':isSelected()}"
                    (keydown)="onKeyDown($event)" [attr.aria-posinset]="this.index + 1" [attr.aria-expanded]="this.node.expanded" [attr.aria-selected]="isSelected()">
                    <span class="ui-tree-toggler pi pi-fw ui-unselectable-text" [ngClass]="{'pi-caret-right':!node.expanded,'pi-caret-down':node.expanded}"
                            (click)="toggle($event)">
                    </span>
                    <div class="ui-chkbox" *ngIf="tree.selectionMode == 'checkbox' || tree.mode == PplusTreeMode.MULTIPLE">
                        <div class="ui-chkbox-box ui-widget ui-corner-all ui-state-default" [ngClass]="{'ui-state-disabled': node.selectable === false, 'selected': isSelected()}">
                            <span class="ui-chkbox-icon ui-clickable pi"
                            [ngClass]="{'pi-check':isSelected(),'pi-minus':node.partialSelected}"></span>
                        </div>
                    </div>
                    <span [class]="getIcon()" *ngIf="node.icon||node.expandedIcon||node.collapsedIcon"></span>
                    <span class="ui-treenode-label ui-corner-all"[ngClass]="{'ui-state-highlight':isSelected()}">
                            <span *ngIf="!tree.getTemplateForNode(node)">{{node.label}}</span>
                            <span *ngIf="tree.getTemplateForNode(node)">
                                <ng-container *ngTemplateOutlet="tree.getTemplateForNode(node); context: {$implicit: node}"></ng-container>
                            </span>
                    </span>
                </div>
                <ul class="ui-treenode-children" style="display: none;" *ngIf="node.children && node.expanded" [style.display]="node.expanded ? 'block' : 'none'" role="group">
                    <pplus-treeNode *ngFor="let childNode of node.children;let firstChild=first;let lastChild=last; let index=index; trackBy: tree.nodeTrackBy" [node]="childNode" [parentNode]="node"
                        [firstChild]="firstChild" [lastChild]="lastChild" [index]="index" [separator]="separator"></pplus-treeNode>
                </ul>
            </li>
            <li *ngIf="tree.droppableNodes&&lastChild" class="ui-treenode-droppoint" [ngClass]="{'ui-treenode-droppoint-active ui-state-highlight':draghoverNext}"
            (drop)="onDropPoint($event,1)" (dragover)="onDropPointDragOver($event)" (dragenter)="onDropPointDragEnter($event,1)" (dragleave)="onDropPointDragLeave($event)"></li>
            <table *ngIf="tree.horizontal" [class]="node.styleClass">
                <tbody>
                    <tr>
                        <td class="ui-treenode-connector" *ngIf="!root">
                            <table class="ui-treenode-connector-table">
                                <tbody>
                                    <tr>
                                        <td [ngClass]="{'ui-treenode-connector-line':!firstChild}"></td>
                                    </tr>
                                    <tr>
                                        <td [ngClass]="{'ui-treenode-connector-line':!lastChild}"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td class="ui-treenode" [ngClass]="{'ui-treenode-collapsed':!node.expanded,'bordered': separator}">
                            <div class="ui-treenode-content ui-state-default ui-corner-all"
                                [ngClass]="{'ui-treenode-selectable':tree.selectionMode,'ui-state-highlight':isSelected()}" (click)="onNodeClick($event)" (contextmenu)="onNodeRightClick($event)"
                                (touchend)="onNodeTouchEnd()">
                                <span class="ui-tree-toggler pi pi-fw ui-unselectable-text" [ngClass]="{'pi-plus':!node.expanded,'pi-minus':node.expanded}" *ngIf="!isLeaf()"
                                        (click)="toggle($event)"></span
                                ><span [class]="getIcon()" *ngIf="node.icon||node.expandedIcon||node.collapsedIcon"></span
                                ><span class="ui-treenode-label ui-corner-all" >
                                        <span *ngIf="!tree.getTemplateForNode(node)">{{node.label}}</span>
                                        <span *ngIf="tree.getTemplateForNode(node)">
                                        <ng-container *ngTemplateOutlet="tree.getTemplateForNode(node); context: {$implicit: node}"></ng-container>
                                        </span>
                                </span>
                            </div>
                        </td>
                        <td class="ui-treenode-children-container" *ngIf="node.children && node.expanded" [style.display]="node.expanded ? 'table-cell' : 'none'">
                            <div class="ui-treenode-children">
                                <pplus-treeNode *ngFor="let childNode of node.children;let firstChild=first;let lastChild=last; trackBy: tree.nodeTrackBy" [node]="childNode"
                                        [firstChild]="firstChild" [lastChild]="lastChild" [separator]="separator"></pplus-treeNode>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-template>
    `
})
export class PPlusTreeNodeComponent implements OnInit {

    static ICON_CLASS: string = 'ui-treenode-icon ';

    @Input() node: TreeNode;

    @Input() parentNode: TreeNode;

    @Input() root: boolean;

    @Input() index: number;

    @Input() firstChild: boolean;

    @Input() lastChild: boolean;

    @Input() separator = false;

    tree: PplusTreeComponent;

    PplusTreeMode = PplusTreeMode;

    constructor(@Inject(forwardRef(() => PplusTreeComponent)) tree) {
        this.tree = tree as PplusTreeComponent;
    }

    draghoverPrev: boolean;

    draghoverNext: boolean;

    draghoverNode: boolean

    ngOnInit() {
        this.node.parent = this.parentNode;

        if (this.parentNode) {
            this.tree.syncNodeOption(this.node, this.tree.value, 'parent', this.tree.getNodeWithKey(this.parentNode.key, this.tree.value));
        }
    }

    getIcon() {
        let icon: string;

        if (this.node.icon)
            icon = this.node.icon;
        else
            icon = this.node.expanded && this.node.children && this.node.children.length ? this.node.expandedIcon : this.node.collapsedIcon;

        return PPlusTreeNodeComponent.ICON_CLASS + ' ' + icon;
    }

    isLeaf() {
        return this.tree.isNodeLeaf(this.node);
    }

    toggle(event: Event) {
        if (this.node.expanded)
            this.collapse(event);
        else
            this.expand(event);
    }

    expand(event: Event) {
        this.node.expanded = true;
        this.tree.onNodeExpand.emit({ originalEvent: event, node: this.node });
    }

    collapse(event: Event) {
        this.node.expanded = false;
        this.tree.onNodeCollapse.emit({ originalEvent: event, node: this.node });
    }

    onNodeClick(event: MouseEvent) {
        this.tree.onNodeClick(event, this.node);
    }

    onNodeTouchEnd() {
        this.tree.onNodeTouchEnd();
    }

    onNodeRightClick(event: MouseEvent) {
        this.tree.onNodeRightClick(event, this.node);
    }

    isSelected() {
        return this.tree.isSelected(this.node);
    }

    onKeyDown(event: KeyboardEvent) {
        const nodeElement = (<HTMLDivElement>event.target).parentElement.parentElement;

        if (nodeElement.nodeName !== 'pplus-treeNode') {
            return;
        }

        switch (event.which) {
            //down arrow
            case 40:
                const listElement = (this.tree.droppableNodes) ? nodeElement.children[1].children[1] : nodeElement.children[0].children[1];
                if (listElement && listElement.children.length > 0) {
                    this.focusNode(listElement.children[0]);
                }
                else {
                    const nextNodeElement = nodeElement.nextElementSibling;
                    if (nextNodeElement) {
                        this.focusNode(nextNodeElement);
                    }
                    else {
                        let nextSiblingAncestor = this.findNextSiblingOfAncestor(nodeElement);
                        if (nextSiblingAncestor) {
                            this.focusNode(nextSiblingAncestor);
                        }
                    }
                }

                event.preventDefault();
                break;

            //up arrow
            case 38:
                if (nodeElement.previousElementSibling) {
                    this.focusNode(this.findLastVisibleDescendant(nodeElement.previousElementSibling));
                }
                else {
                    let parentNodeElement = this.getParentNodeElement(nodeElement);
                    if (parentNodeElement) {
                        this.focusNode(parentNodeElement);
                    }
                }

                event.preventDefault();
                break;

            //right arrow
            case 39:
                if (!this.node.expanded) {
                    this.expand(event);
                }

                event.preventDefault();
                break;

            //left arrow
            case 37:
                if (this.node.expanded) {
                    this.collapse(event);
                }
                else {
                    let parentNodeElement = this.getParentNodeElement(nodeElement);
                    if (parentNodeElement) {
                        this.focusNode(parentNodeElement);
                    }
                }

                event.preventDefault();
                break;

            //enter
            case 13:
                this.tree.onNodeClick(event, this.node);
                event.preventDefault();
                break;

            default:
                //no op
                break;
        }
    }

    findNextSiblingOfAncestor(nodeElement) {
        let parentNodeElement = this.getParentNodeElement(nodeElement);
        if (parentNodeElement) {
            if (parentNodeElement.nextElementSibling)
                return parentNodeElement.nextElementSibling;
            else
                return this.findNextSiblingOfAncestor(parentNodeElement);
        }
        else {
            return null;
        }
    }

    findLastVisibleDescendant(nodeElement) {
        const childrenListElement = nodeElement.children[0].children[1];
        if (childrenListElement && childrenListElement.children.length > 0) {
            const lastChildElement = childrenListElement.children[childrenListElement.children.length - 1];

            return this.findLastVisibleDescendant(lastChildElement);
        }
        else {
            return nodeElement;
        }
    }

    getParentNodeElement(nodeElement) {
        const parentNodeElement = nodeElement.parentElement.parentElement.parentElement;

        return parentNodeElement.tagName === 'pplus-treeNode' ? parentNodeElement : null;
    }

    focusNode(element) {
        if (this.tree.droppableNodes)
            element.children[1].children[0].focus();
        else
            element.children[0].children[0].focus();
    }
}

@Component({
    selector: 'pplus-tree',
    styleUrls: ['./pplus-tree.component.scss'],
    template: `
        <div [ngClass]="{'ui-tree ui-widget ui-widget-content ui-corner-all':true,'ui-tree-selectable':selectionMode,'ui-treenode-dragover':dragHover,'ui-tree-loading': loading}" [ngStyle]="style" [class]="styleClass" *ngIf="!horizontal"
            (drop)="onDrop($event)" (dragover)="onDragOver($event)" (dragenter)="onDragEnter($event)" (dragleave)="onDragLeave($event)">
            <div class="ui-tree-loading-mask ui-widget-overlay" *ngIf="loading"></div>
            <div class="ui-tree-loading-content" *ngIf="loading">
                <i [class]="'ui-tree-loading-icon pi-spin ' + loadingIcon"></i>
            </div>

            <div *ngIf="tools" class="ui-tree-tools">
                <div class="buttons">
                    <div class="tool-button" (click)="toggleExpanded()">
                        {{'T_EXPANDSHRINK' | localize}}
                    </div>
                    <div class="tool-button ml-3" (click)="toggleCheckedview()">
                        {{'T_SEECHECKED' | localize}}
                    </div>
                </div>
                <i class="fas fa-search pointer" (click)="toggleSearch()"></i>
            </div>
            <div *ngIf="filter" class="ui-tree-filter-container">
                <span class="ui-tree-filter-icon pi pi-search"></span>
                <input #filter type="text" autocomplete="off" class="ui-tree-filter ui-inputtext ui-widget ui-state-default ui-corner-all" [attr.placeholder]="filterPlaceholder"
                    (keydown.enter)="$event.preventDefault()" (input)="onFilter($event)">
                <pplus-button *ngIf="entities" label="{{'T_SELECT' | localize}}" [styleClass]="'pplus-button-primary-circular'" class="ml-2" (click)="select()"></pplus-button>
            </div>
            
            <div class="tree-shadow">
                <ul class="ui-tree-container" *ngIf="getRootNode()" role="tree" [attr.aria-label]="ariaLabel" [attr.aria-labelledby]="ariaLabelledBy">
                    <pplus-treeNode *ngFor="let node of getRootNode(); let firstChild=first;let lastChild=last; let index=index; trackBy: nodeTrackBy" [node]="node"
                    [firstChild]="firstChild" [lastChild]="lastChild" [index]="index" [separator]="separator"></pplus-treeNode>
                </ul>
            </div>
            <div class="ui-tree-empty-message" *ngIf="!loading && (value == null || value.length === 0 || ( filteredNodes !== undefined && filteredNodes.length === 0))">{{emptyMessage}}</div>
        </div>
        <div [ngClass]="{'ui-tree ui-tree-horizontal ui-widget ui-widget-content ui-corner-all':true,'ui-tree-selectable':selectionMode}"  [ngStyle]="style" [class]="styleClass" *ngIf="horizontal">
            <div class="ui-tree-loading ui-widget-overlay" *ngIf="loading"></div>
            <div class="ui-tree-loading-content" *ngIf="loading">
                <i [class]="'ui-tree-loading-icon pi-spin ' + loadingIcon"></i>
            </div>
            <table *ngIf="value&&value[0]">
                <pplus-treeNode [node]="value[0]" [root]="true" [separator]="separator"></pplus-treeNode>
            </table>
            <div class="ui-tree-empty-message" *ngIf="!loading && (value == null || value.length === 0)">{{emptyMessage}}</div>
        </div>
    `
})
export class PplusTreeComponent implements OnInit, AfterContentInit, OnDestroy, BlockableUI, OnChanges {

    @Input() entities: boolean;

    @Input() value: TreeNode[];

    @Input() selectionMode: string;

    @Input() selection: any;

    @Output() selectionChange: EventEmitter<any> = new EventEmitter();

    @Output() onNodeSelect: EventEmitter<any> = new EventEmitter();

    @Output() onNodeUnselect: EventEmitter<any> = new EventEmitter();

    @Output() onNodeExpand: EventEmitter<any> = new EventEmitter();

    @Output() onNodeCollapse: EventEmitter<any> = new EventEmitter();

    @Output() onNodeContextMenuSelect: EventEmitter<any> = new EventEmitter();

    @Output() onNodeDrop: EventEmitter<any> = new EventEmitter();

    @Output() onSelect: EventEmitter<any> = new EventEmitter();

    @Input() style: any;

    @Input() styleClass: string;

    @Input() contextMenu: any;

    @Input() layout: string = 'vertical';

    @Input() draggableScope: any;

    @Input() droppableScope: any;

    @Input() draggableNodes: boolean;

    @Input() droppableNodes: boolean;

    @Input() metaKeySelection: boolean = true;

    @Input() propagateSelectionUp: boolean = true;

    @Input() propagateSelectionDown: boolean = true;

    @Input() propagateSelectionOnClick: boolean = false;

    @Input() loading: boolean;

    @Input() loadingIcon: string = 'pi pi-spinner';

    @Input() emptyMessage: string = this.lozalizeService.l('T_NOMATCHESFOUND');

    @Input() ariaLabel: string;

    @Input() ariaLabelledBy: string;

    @Input() validateDrop: boolean;

    @Input() filter: boolean;

    @Input() filterBy = 'label';

    @Input() filterMode = 'lenient';

    @Input() filterPlaceholder = 'Buscar';

    @Input() nodeTrackBy: Function = (index: number, item: any) => item;

    @Input() forceExpand = false;

    @Input() selectAllNodes = false;

    @Input() filterOnlyChecked = false;

    @Input() separator = false;

    @Input() tools = false;

    @Input() mode: PplusTreeMode;

    @ContentChildren(PrimeTemplate) templates: QueryList<any>;

    public templateMap: any;

    public nodeTouched: boolean;

    public dragNodeTree: PplusTreeComponent;

    public dragNode: TreeNode;

    public dragNodeSubNodes: TreeNode[];

    public dragNodeIndex: number;

    public dragNodeScope: any;

    public dragHover: boolean;

    public dragStartSubscription: Subscription;

    public dragStopSubscription: Subscription;

    public filteredNodes: TreeNode[];

    public allSelected = false;

    private propagateDisabledOnFilter = false;

    PplusTreeMode = PplusTreeMode;

    constructor(
        public el: ElementRef,
        private lozalizeService: AppLocalizationService
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.forceExpand && !changes.forceExpand.firstChange) {
            if (this.forceExpand) {
                this.expandAll();
            } else {
                this.collapseAll();
            }
        } if (changes.selectAllNodes && !changes.selectAllNodes.firstChange) {
            if (this.selectAllNodes) {
                this.selectAll();
            } else {
                this.unSelectAll();
            }
        } if (changes.filterOnlyChecked && !changes.filterOnlyChecked.firstChange) {
            if (this.filterOnlyChecked) {
                this.viewChecked();
            } else {
                this.viewAll();
            }
        }
    }

    ngOnInit() {

    }

    get horizontal(): boolean {
        return this.layout == 'horizontal';
    }

    ngAfterContentInit() {
        if (this.templates.length) {
            this.templateMap = {};
        }

        this.templates.forEach((item) => {
            this.templateMap[item.name] = item.template;
        });
    }

    onNodeClick(event, node: TreeNode) {
        let eventTarget = (<Element>event.target);

        if (DomHandler.hasClass(eventTarget, 'ui-tree-toggler')) {
            return;
        }
        else if (this.selectionMode) {

            if (node.selectable === false) {
                return;
            }

            let index = this.findIndexInSelection(node);
            let selected = (index >= 0);

            if (this.isCheckboxSelectionMode()) {
                if (selected && !this.checkAllChildrenSelection(node)) {

                    if (this.propagateSelectionDown && node.children && node.children.length > 0 && !this.checkChildrenSelection(node)) {// Only nodes with clidrens
                        this.propagateDown(node, true);
                    }
                    else {
                        this.selection = this.selection.filter((val, i) => i != index);
                    }

                    // if (this.propagateSelectionUp && node.parent) {
                    //     this.propagateUp(node.parent, false);
                    // }

                    this.selectionChange.emit(this.selection);
                    this.onNodeUnselect.emit({ originalEvent: event, node: node });
                } else if (this.checkAllChildrenSelection(node)) {
                    this.propagateDown(node, false);
                    // if (node.parent) {
                    //     this.propagateUp(node.parent, false);
                    // }
                    this.selectionChange.emit(this.selection);
                } else {
                    if (this.propagateSelectionDown && !this.propagateSelectionOnClick)
                        this.propagateDown(node, true);
                    else
                        this.selection = [...this.selection || [], node];

                    // if (this.propagateSelectionUp && node.parent) {
                    //     this.propagateUp(node.parent, true);
                    // }

                    this.selectionChange.emit(this.selection);
                    this.onNodeSelect.emit({ originalEvent: event, node: node });
                }
            }
            else {
                let metaSelection = this.nodeTouched ? false : this.metaKeySelection;
                if (metaSelection) {
                    let metaKey = (event.metaKey || event.ctrlKey);
                    if (selected) {
                        if (this.isSingleSelectionMode()) {
                            this.selectionChange.emit(null);
                        }
                        else {
                            this.selection = this.selection.filter((val, i) => i != index);
                            this.selectionChange.emit(this.selection);
                        }

                        this.onNodeUnselect.emit({ originalEvent: event, node: node });
                    }
                    else {
                        if (this.isSingleSelectionMode()) {
                            this.selectionChange.emit(node);
                        }
                        else if (this.isMultipleSelectionMode()) {
                            this.selection = (!metaKey) ? [] : this.selection || [];
                            this.selection = [...this.selection, node];
                            this.selectionChange.emit(this.selection);
                        }

                        this.onNodeSelect.emit({ originalEvent: event, node: node });
                    }
                }
                else {
                    if (this.isSingleSelectionMode()) {
                        if (selected) {
                            this.selection = null;
                            this.onNodeUnselect.emit({ originalEvent: event, node: node });
                        }
                        else {
                            this.selection = node;
                            this.onNodeSelect.emit({ originalEvent: event, node: node });
                        }
                    }
                    else {
                        if (selected) {
                            this.selection = this.selection.filter((val, i) => i != index);
                            this.onNodeUnselect.emit({ originalEvent: event, node: node });
                        }
                        else {
                            this.selection = [...this.selection || [], node];
                            this.onNodeSelect.emit({ originalEvent: event, node: node });
                        }
                    }

                    this.selectionChange.emit(this.selection);
                }
            }
        }
        this.nodeTouched = false;
    }

    checkChildrenSelection(node: TreeNode): boolean {
        if (node.children && node.children.length) {
            let selectedCount: number = 0;
            for (let child of node.children) {
                if (this.isSelected(child)) {
                    selectedCount++;
                }
            }
            return selectedCount > 0;
        }
        return false;
    }

    checkAllChildrenSelection(node: TreeNode): boolean {
        if (node.children && node.children.length) {
            let selectedCount: number = 0;
            for (let child of node.children) {
                if (this.isSelected(child)) {
                    selectedCount++;
                }
            }
            return selectedCount == node.children.length;
        }
        return false;
    }

    onNodeTouchEnd() {
        this.nodeTouched = true;
    }

    onNodeRightClick(event: MouseEvent, node: TreeNode) {
        if (this.contextMenu) {
            let eventTarget = (<Element>event.target);

            if (eventTarget.className && eventTarget.className.indexOf('ui-tree-toggler') === 0) {
                return;
            }
            else {
                let index = this.findIndexInSelection(node);
                let selected = (index >= 0);

                if (!selected) {
                    if (this.isSingleSelectionMode())
                        this.selectionChange.emit(node);
                    else
                        this.selectionChange.emit([node]);
                }

                this.contextMenu.show(event);
                this.onNodeContextMenuSelect.emit({ originalEvent: event, node: node });
            }
        }
    }

    findIndexInSelection(node: TreeNode) {
        let index: number = -1;
        if (this.selectionMode && this.selection) {
            if (this.isSingleSelectionMode()) {
                if (Array.isArray(this.selection) && this.selection.length > 0) {
                    let areNodesEqual = (this.selection[0].key && this.selection[0].key === node.key) || this.selection[0] == node;
                    index = areNodesEqual ? 0 : - 1;
                } else {
                    let areNodesEqual = (this.selection.key && this.selection.key === node.key) || this.selection == node;
                    index = areNodesEqual ? 0 : - 1;
                }
            }
            else {
                for (let i = 0; i < this.selection.length; i++) {
                    let selectedNode = this.selection[i];
                    let areNodesEqual = (selectedNode.key && selectedNode.key === node.key) || selectedNode == node || (node.label == selectedNode.label);
                    if (areNodesEqual) {
                        index = i;
                        break;
                    }
                }
            }
        }

        return index;
    }

    syncNodeOption(node, parentNodes, option, value?: any) {
        // to synchronize the node option between the filtered nodes and the original nodes(this.value)
        const _node = this.hasFilteredNodes() ? this.getNodeWithKey(node.key, parentNodes) : null;
        if (_node) {
            _node[option] = value || node[option];
        }
    }

    hasFilteredNodes() {
        return this.filter && this.filteredNodes && this.filteredNodes.length;
    }

    getNodeWithKey(key: string, nodes: TreeNode[]) {
        for (let node of nodes) {
            if (node.key === key) {
                return node;
            }

            if (node.children) {
                let matchedNode = this.getNodeWithKey(key, node.children);
                if (matchedNode) {
                    return matchedNode;
                }
            }
        }
    }

    propagateUp(node: TreeNode, select: boolean) {
        if (node.children && node.children.length) {
            let selectedCount: number = 0;
            let childPartialSelected: boolean = false;
            for (let child of node.children) {
                if (this.isSelected(child)) {
                    selectedCount++;
                }
                else if (child.partialSelected) {
                    childPartialSelected = true;
                }
            }

            if (select && selectedCount == node.children.length) {
                this.selection = [...this.selection || [], node];
                node.partialSelected = false;
            }
            else {
                if (!select) {
                    let index = this.findIndexInSelection(node);
                    if (index >= 0) {
                        this.selection = this.selection.filter((val, i) => i != index);
                    }
                }

                if (childPartialSelected || selectedCount > 0 && selectedCount != node.children.length)
                    node.partialSelected = true;
                else
                    node.partialSelected = false;
            }
            //this.syncNodeOption(node, this.filteredNodes, 'partialSelected');
        }

        let parent = node.parent;
        if (parent && parent.label !== node.label) {
            this.propagateUp(parent, select);
        }
    }

    propagateDown(node: TreeNode, select: boolean) {
        let index = this.findIndexInSelection(node);

        if (select && index == -1) {
            this.selection = [...this.selection || [], node];
        }
        else if (!select && index > -1) {
            this.selection = this.selection.filter((val, i) => i != index);
        }

        node.partialSelected = false;

        this.syncNodeOption(node, this.filteredNodes, 'partialSelected');

        if (node.children && node.children.length) {
            for (let child of node.children) {
                this.propagateDown(child, select);
            }
        }
    }

    isSelected(node: TreeNode) {
        return this.findIndexInSelection(node) != -1;
    }

    isSingleSelectionMode() {
        return this.selectionMode && this.selectionMode == 'single';
    }

    isMultipleSelectionMode() {
        return this.selectionMode && this.selectionMode == 'multiple';
    }

    isCheckboxSelectionMode() {
        return this.selectionMode && this.selectionMode == 'checkbox';
    }

    isNodeLeaf(node) {
        return node.leaf == false ? false : !(node.children && node.children.length);
    }

    getRootNode() {
        return this.filteredNodes ? this.filteredNodes : this.value;
    }

    getTemplateForNode(node: TreeNode): TemplateRef<any> {
        if (this.templateMap)
            return node.type ? this.templateMap[node.type] : this.templateMap['default'];
        else
            return null;
    }

    onDragOver(event) {
        if (this.droppableNodes && (!this.value || this.value.length === 0)) {
            event.dataTransfer.dropEffect = 'move';
            event.preventDefault();
        }
    }



    allowDrop(dragNode: TreeNode, dropNode: TreeNode, dragNodeScope: any): boolean {
        if (!dragNode) {
            //prevent random html elements to be dragged
            return false;
        }
        else if (this.isValidDragScope(dragNodeScope)) {
            let allow: boolean = true;
            if (dropNode) {
                if (dragNode === dropNode) {
                    allow = false;
                }
                else {
                    let parent = dropNode.parent;
                    while (parent != null) {
                        if (parent === dragNode) {
                            allow = false;
                            break;
                        }
                        parent = parent.parent;
                    }
                }
            }

            return allow;
        }
        else {
            return false;
        }
    }

    isValidDragScope(dragScope: any): boolean {
        let dropScope = this.droppableScope;

        if (dropScope) {
            if (typeof dropScope === 'string') {
                if (typeof dragScope === 'string')
                    return dropScope === dragScope;
                else if (dragScope instanceof Array)
                    return (<Array<any>>dragScope).indexOf(dropScope) != -1;
            }
            else if (dropScope instanceof Array) {
                if (typeof dragScope === 'string') {
                    return (<Array<any>>dropScope).indexOf(dragScope) != -1;
                }
                else if (dragScope instanceof Array) {
                    for (let s of dropScope) {
                        for (let ds of dragScope) {
                            if (s === ds) {
                                return true;
                            }
                        }
                    }
                }
            }
            return false;
        }
        else {
            return true;
        }
    }

    onFilter(event) {
        let filterValue = event.target.value;
        if (filterValue === '') {
            if (this.propagateDisabledOnFilter) {
                this.propagateSelectionUp = true;
                this.propagateDisabledOnFilter = false;
            }
            this.filteredNodes = null;
        }
        else {
            if (this.propagateSelectionUp) {
                this.propagateSelectionUp = false;
                this.propagateDisabledOnFilter = true;
            }
            this.filteredNodes = [];
            const searchFields: string[] = this.filterBy.split(',');
            const filterText = ObjectUtils.removeAccents(filterValue).toLowerCase();
            const isStrictMode = this.filterMode === 'strict';
            for (let node of this.value) {
                let copyNode = { ...node };
                let paramsWithoutNode = { searchFields, filterText, isStrictMode };
                if ((isStrictMode && (this.findFilteredNodes(copyNode, paramsWithoutNode) || this.isFilterMatched(copyNode, paramsWithoutNode))) ||
                    (!isStrictMode && (this.isFilterMatched(copyNode, paramsWithoutNode) || this.findFilteredNodes(copyNode, paramsWithoutNode)))) {
                    this.filteredNodes.push(copyNode);
                }
            }
        }
    }

    findFilteredNodes(node, paramsWithoutNode) {
        if (node) {
            let matched = false;
            if (node.children) {
                let childNodes = [...node.children];
                node.children = [];
                for (let childNode of childNodes) {
                    let copyChildNode = { ...childNode };
                    if (this.isFilterMatched(copyChildNode, paramsWithoutNode)) {
                        matched = true;
                        node.children.push(copyChildNode);
                    }
                }
            }

            if (matched) {
                node.expanded = true;
                return true;
            }
        }
    }

    isFilterMatched(node, { searchFields, filterText, isStrictMode }) {
        let matched = false;
        for (let field of searchFields) {
            let fieldValue = ObjectUtils.removeAccents(String(ObjectUtils.resolveFieldData(node, field))).toLowerCase();
            if (fieldValue.indexOf(filterText) > -1) {
                matched = true;
            }
        }

        if (!matched || (isStrictMode && !this.isNodeLeaf(node))) {
            matched = this.findFilteredNodes(node, { searchFields, filterText, isStrictMode }) || matched;
        }

        return matched;
    }

    getBlockableElement(): HTMLElement {
        return this.el.nativeElement.children[0];
    }

    ngOnDestroy() {
        if (this.dragStartSubscription) {
            this.dragStartSubscription.unsubscribe();
        }

        if (this.dragStopSubscription) {
            this.dragStopSubscription.unsubscribe();
        }
    }

    toggleSelectedAll() {
        this.allSelected = !this.allSelected;
        if (this.allSelected) {
            this.selectAll();
        } else {
            this.unSelectAll();
        }
    }

    unSelectAll() {
        this.selection = [];
        setTimeout(() => {
            this.selectionChange.emit(this.selection);
        }, 100);
    }

    selectAll() {
        let selected: TreeNode[] = [];

        this.value.forEach(entity => {
            selected.push(entity);
            if (entity.children) {
                selected.push(...this.selectAllChildren(entity.children));
            }
        });

        this.selection = selected;
        setTimeout(() => {
            this.selectionChange.emit(this.selection);
        }, 100);
    }

    selectAllChildren(entities: TreeNode[]) {
        let selected: TreeNode[] = [];

        entities.forEach(entity => {
            selected.push(entity);

            selected.push(...this.selectAllChildren(entity.children));
        });

        return selected;
    }

    select() {
        this.onSelect.emit();
    }

    toggleAll() {
        if (this.isAnyExpanded()) {
            this.collapseAll();
        } else {
            this.expandAll();
        }
    }

    collapseAll() {
        let value = this.filteredNodes ? this.filteredNodes : this.value;
        this.toggleNodes(value, false);
    }

    expandAll() {
        let value = this.filteredNodes ? this.filteredNodes : this.value;
        this.toggleNodes(value, true);
    }

    toggleNodes(nodes: TreeNode[], expanded: boolean) {
        nodes.map((node: TreeNode) => {
            node.expanded = expanded;
            if (node.children) {
                this.toggleNodes(node.children, expanded);
            }
        });
    }

    isAnyExpanded() {
        let res = this.value.filter((o: TreeNode) =>
            o.expanded
        );

        return res.length > 0;
    }

    toggleCheckedview() {
        if (this.filteredNodes) {
            this.viewAll();
        } else {
            this.viewChecked();
        }
    }

    toggleExpanded() {
        if (!this.isAnyExpanded()) {
            this.expandAll();
        } else {
            this.collapseAll();
        }
    }

    toggleSearch() {
        if (this.filter) {
            this.filteredNodes = undefined;
        }
        this.filter = !this.filter;
    }

    viewChecked() {
        if (this.selection && this.selection.length > 0) {
            this.filteredNodes = [...this.setCheckedPermissionsTree()];
        } else {
            this.filteredNodes = [];
        }
    }

    setCheckedPermissionsTree() {
        let selectedPermissionsTree = [];
        let copyValue = Object.create(this.value);
        for (let node of copyValue) {
            let copyNode = { ...node };
            if (this.checkNode(copyNode)) {
                selectedPermissionsTree.push(copyNode);
            }
        }
        return selectedPermissionsTree;
    }

    checkNode(node: TreeNode) {
        let marked = false;
        if (this.checkNodeSelected(node)) {
            marked = true;
        }
        if (node.children && node.children.length > 0) {
            let nodeChildrenSelected = [];
            for (let children of node.children) {
                let copyChildren = { ...children };
                if (this.checkNode(copyChildren)) {
                    nodeChildrenSelected.push(copyChildren);
                }
            }
            if (nodeChildrenSelected.length > 0) {
                node.children = nodeChildrenSelected;
                marked = true;
            } else {
                node.children = [];
            }
        }
        return marked;
    }

    checkNodeSelected(node: TreeNode) {
        let selected = false;
        let selection = [...this.selection];
        for (let selectedNode of selection) {
            let copySelected = { ...selectedNode };
            if (node.label == copySelected.label) {
                return true;
            }
        }
        return selected;
    }

    viewAll() {
        this.filteredNodes = undefined;
    }

}
