import { Component, OnInit, Input, Self, Optional } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'pplus-input-textarea',
  templateUrl: './pplus-textarea.component.html',
  styleUrls: ['./pplus-textarea.component.scss']
})
export class PplusTextareaComponent implements OnInit, ControlValueAccessor {

  @Input() placeholder = '';
  @Input() value = '';
  @Input() disabled: boolean;
  @Input() rows = 5;
  @Input() columns = 30;
  @Input() label: string;
  @Input() error = false;
  @Input() errors;

  constructor(
    @Self() @Optional() private ngControl: NgControl
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
  }

  /**
   * Write form value to the DOM element (model => view)
   */
  writeValue(value: any): void {
    this.value = value;
  }

  /**
   * Update form when DOM element value changes (view => model)
   */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * Update form when DOM element is blurred (view => model)
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * Write form disabled state to the DOM element (model => view)
   */
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChange(event) { }
  onTouched() { }



}
