export enum SlideColors {
    'ENERGY_YELLOW' = '#f6a700',
    'ENERGY_GREEN' = '#6ca53a',
    'ENERGY_RED' = '#db393f',
    'ENERGY_PURPLE' = '#60379d',
    'ENERGY_BLUE' = '#0b349b',
    'P1' = '#e2eb98',
    'P2' = '#32df8a',
    'P3' = '#a3e8dd',
    'P4' = '#779cab',
    'P5' = '#627c86',
    'P6' = '#35524a',
}
