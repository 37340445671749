import { Component, OnInit } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { BsModalRef } from 'ngx-bootstrap';
import { Subject } from 'rxjs';
import { Entity } from '../pplus/models/entity';


@Component({
  selector: 'app-entity-selector-dialog',
  templateUrl: './entity-selector-dialog.component.html',
  styleUrls: ['./entity-selector-dialog.component.scss']
})
export class EntitySelectorDialogComponent implements OnInit {

    public onClose: Subject<any>;
    public currentEntities: number[] = [];


    entities: Entity[] = [];

    mappedEntities: TreeNode[];
    public selectedEntities: TreeNode[];

  constructor(public bsModalRef: BsModalRef) {
    this.onClose = new Subject();
  }

  ngOnInit() {
  }

  initEntities() {
    this.mappedEntities = this.mapEntities(this.entities);
    this.selectedEntities = this.setEntities(this.currentEntities, this.mappedEntities);
  }

  mapEntities(entities: Entity[]): TreeNode[] {
      return entities.map( entity => {

            const children = this.mapEntities(entity.children);

            return <TreeNode> {
                label: entity.id + '. ' + entity.description,
                data: entity,
                expandedIcon: 'fa fa-folder-open',
                collapsedIcon: 'fa fa-folder',
                children: children,
                selectable: true,
                expanded: true,
                key: 'entity_' + entity.id.toString()
            };
      });
  }


  selectNode() {
    this.onClose.next( this.selectedEntities.map( node => node.data));
    this.bsModalRef.hide();
  }


  setEntities(selectedEntities: number[], entities: TreeNode[]) {
    let selected: TreeNode[] = [];
    if (selectedEntities) {
        entities.forEach( entity => {
            if (selectedEntities.indexOf(entity.data.id) >= 0) {
                selected.push(entity);
            }

            selected.push(...this.setEntities(selectedEntities ,entity.children));

        });
    }
    return selected;
  }

  expandAll() {
      this.mappedEntities.map((node: TreeNode) => node.expanded = true);
  }

  collapseAll() {
    this.mappedEntities.map((node: TreeNode) => node.expanded = false);
 }

 selectAll() {
    let selected: TreeNode[] = [];

    this.mappedEntities.forEach( entity => {
        selected.push(entity);
        selected.push(...this.selectAllChildren(entity.children));
    });

    this.selectedEntities = selected;
 }

 selectAllChildren(entities: TreeNode[]) {
    let selected: TreeNode[] = [];

    entities.forEach( entity => {
        selected.push(entity);

        selected.push(...this.selectAllChildren(entity.children));
    });

    return selected;
 }

 unselectAll() {
    this.selectedEntities = [];
 }

}
