import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-pplus-stepper',
  templateUrl: './pplus-stepper.component.html',
  styleUrls: ['./pplus-stepper.component.scss']
})
export class PplusStepperComponent implements OnInit {

  @Input() insertLine = true;

  constructor() { }

  ngOnInit() {
  }

}
