import { Component, OnInit, Input, Output, EventEmitter, Self, Optional } from '@angular/core';
import { NgControl, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'pplus-switch-input-info',
  templateUrl: './pplus-switch-input-info.component.html',
  styleUrls: ['./pplus-switch-input-info.component.scss']
})
export class PplusSwitchInputInfoComponent implements OnInit, ControlValueAccessor {

    @Input() disabled: boolean;
    @Input() style: any;
    @Input() styleClass: string;
    @Input() ariaLabelledBy: string;
    @Input() dataKey: string;
    @Input() optionLabel: string;
    @Input() leftIcon: string;
    @Input() rightIcon: string;
    @Input() leftLabel: string;
    @Input() rightLabel: string;
    @Input() options: any[];
    @Input() label: string;

    @Output() onOptionClick: EventEmitter<any> = new EventEmitter();
    @Output() onChange: EventEmitter<any> = new EventEmitter();
    @Output() onFocus: EventEmitter<any> = new EventEmitter();
    @Output() onBlur: EventEmitter<any> = new EventEmitter();
    @Output() onClick: EventEmitter<any> = new EventEmitter();
    @Output() onShow: EventEmitter<any> = new EventEmitter();
    @Output() onHide: EventEmitter<any> = new EventEmitter();

    value: any;
    selectedType: any;
    defaultIconValue = 'fa fa-circle no-icon-switch';

    constructor(
      @Self() @Optional() private ngControl: NgControl
    ) {
      if (this.ngControl) {
        this.ngControl.valueAccessor = this;
      }
    }

    onChangeFn = (_: any) => { };

    ngOnInit() {
      this.setDefaultIconValue();
    }

    /**
     * Write form value to the DOM element (model => view)
     */
    writeValue(value: any): void {
      this.selectedType = value;
    }

    /**
     * Write form disabled state to the DOM element (model => view)
     */
    setDisabledState(isDisabled: boolean): void {
      this.disabled = isDisabled;
    }

    /**
     * Update form when DOM element value changes (view => model)
     */
    registerOnChange(fn: any): void {
      // Store the provided function as an internal method.
      this.onChangeFn = fn;
    }

    /**
     * Update form when DOM element is blurred (view => model)
     */
    registerOnTouched(fn: any): void {
      // Store the provided function as an internal method.
      this.onTouched = fn;
    }

    onChangeEvent(event) {
      this.onChangeFn(this.selectedType);
      this.onChange.emit(this.selectedType);
    }

    setDefaultIconValue() {
      if (this.leftIcon == null) {
        this.leftIcon = this.defaultIconValue;
      }
      if (this.rightIcon == null) {
        this.rightIcon = this.defaultIconValue;
      }
    }

    private onTouched() { }

}
