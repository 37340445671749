import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { InstallationSelectorDialogComponent } from '../installation-selector-dialog/installation-selector-dialog.component';
import { Installation } from '../pplus/models/installation';
import { Plant } from '../pplus/models/plant';
import { Subscription } from 'rxjs';
import { PplusPlantsService } from '../pplus/services/pplus-plants.services';

@Component({
  selector: 'pplus-installation-selector',
  templateUrl: './installation-selector.component.html',
  styleUrls: ['./installation-selector.component.scss']
})
export class InstallationSelectorComponent implements OnInit, OnDestroy, OnChanges {
    @Input() installationId: number;
    @Output() selectInstallation = new EventEmitter<number>();

    private plantsSubscription: Subscription;
    private modalSubscription: Subscription;
    public modalRef: BsModalRef;
    private modalConfig = {
        animated: true,
        keyboard: true,
        backdrop: true,
        ignoreBackdropClick: false,
        class: 'modal-lg'
    };
    private plants: Plant[] = [];
    public installation: Installation;
    public plant: Plant;

    constructor(private modalService: BsModalService, private plantsService: PplusPlantsService) { }

    ngOnInit() {
        this.plantsSubscription = this.plantsService.getPlants().subscribe(plants => {
            this.plants = plants;
            if (this.installationId) {
                this.setInstallationAndPlantById(this.installationId);
            }
        });
    }

    ngOnDestroy() {
        this.plantsSubscription.unsubscribe();
    }

    openSelector() {
        this.modalRef = this.modalService.show(InstallationSelectorDialogComponent, this.modalConfig);
        this.modalRef.content.setInstallation(this.installation);
        this.modalSubscription = this.modalRef.content.onClose.subscribe(result => {
            this.installation = result.installation;
            this.plant = result.plant;
            this.installationId = this.installation.id;
            this.selectInstallation.emit(this.installation.id);
            this.modalSubscription.unsubscribe();
            delete this.modalRef;
        });
    }

    setInstallationAndPlantById(installationId: number) {
        this.plants.forEach( plant => {
            const installation = plant.installations.find(ins => ins.id === installationId);
            if (installation) {
                this.installation = installation;
                this.plant = plant;
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.installationId !== null) {
            this.setInstallationAndPlantById(this.installationId);
        }
    }
}
