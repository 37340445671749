import { TabsService } from '@app/shared/common/tabs/tabs.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
// import { InterceptorSkipAdalHeader, InterceptorSkipHeader } from './token.constants';

@Injectable()
export class TabInterceptor implements HttpInterceptor {

  constructor(
    private tabsService: TabsService
  ) {}


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    /**
     * This was causing that all requests done were done again whenever a new tab was created or when switching
     * between tabs, repeating actions such as creation, deletion, etc.
     */
    if (!this.tabsService.hasTabs()) {
        return next.handle(req.clone());
    } else {
        const currentSelected = this.tabsService.selected;
        if (currentSelected === null || currentSelected === undefined) {
            // const headers = req.headers.delete(InterceptorSkipAdalHeader);
            return next.handle(req.clone());
        } else {
            const currentTab = this.tabsService.tabList[currentSelected];
            let copiedReq = req.clone({ headers: req.headers.set('X-Module-Id', currentTab.instance.tabI.id) });

            if (currentTab && currentTab.instance.tabI.ref) {
                const sharedData = currentTab.instance.tabI.ref.instance.sharedData;
                if (sharedData.headers && sharedData.headers.length) {
                    sharedData.headers.forEach(header => {
                        copiedReq = copiedReq.clone( {headers: copiedReq.headers.set(header.key, header.value)});
                    });
                }
            }

            return next.handle(copiedReq);
        }
    }

  }
}
