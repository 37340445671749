import { Component } from '@angular/core';
import { AgFilterComponent } from 'ag-grid-angular';
import { IFilterParams, IDoesFilterPassParams, RowNode } from 'ag-grid-community';

@Component({
  selector: 'pplus-bool-filter',
  templateUrl: './pplus-bool-filter.component.html',
})
export class PplusBoolFilterComponent implements AgFilterComponent {

  private params: IFilterParams;
  private valueGetter: (rowNode: RowNode) => any;

  all: boolean = true;
  state: boolean = true;

  constructor() {}

  agInit(params: IFilterParams): void {
    this.params = params;
    this.valueGetter = params.valueGetter;
  }

  isFilterActive(): boolean {
    return !this.all;
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    return this.valueGetter(params.node) === this.state;
  }

  getModel(): any {
    return !this.all ? { filter: this.state } : undefined;
  }

  setModel(model: any): void {
    this.state = model ? model.filter : true;
  }

  onChange() {
    this.params.filterChangedCallback();
  }

}
