import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PPlusUIModule} from 'shared/common/ui/components/pplus-ui.module';
import { AccordionModule} from 'primeng/accordion';
import { InputTextModule} from 'primeng/inputtext';
import { ReactiveFormsModule } from '@angular/forms';
import { FilterOrganizationFormComponent } from './filter-organization-form/filter-organization-form.component';
import { UtilsModule } from '@shared/utils/utils.module';


@NgModule({
  declarations: [FilterOrganizationFormComponent],
  imports: [
    CommonModule,
    PPlusUIModule,
    AccordionModule,
    InputTextModule,
    ReactiveFormsModule,
    UtilsModule
  ],
  exports:[
    FilterOrganizationFormComponent
  ]
})
export class FilterOrganizationFormModule { }
