import { Component, AfterViewInit } from '@angular/core';
import { DialogReportData, ReportMode } from '../models/IReport';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/api';

declare var Stimulsoft: any;
@Component({
    selector: 'app-stimulsoft',
    templateUrl: './stimulsoft.component.html',
    styleUrls: ['./stimulsoft.component.css']
})
export class StimulsoftComponent implements AfterViewInit {
    viewerId = 'viewer_id';
    error = false;
    data: DialogReportData;
    constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
        this.data = this.config.data;
    }

    ngAfterViewInit(): void {
        if (this.data.source !== null) {
            if (this.data.mode === ReportMode.VIEWER) {
                this.generateViewer();
            } else if (this.data.mode === ReportMode.DESIGNER) {
                this.generateDesigner();
            }
        } else {
            this.error = true;
        }
    }

    private generateViewer(): void {
        Stimulsoft.Base.StiLicense.key = '6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHkcgIvwL0jnpsDqRpWg5FI5kt2G7A0tYIcUygBh1sPs7rE7BAeUEkpkjUKhl6/j24S6yxsIWZIRjJksEoLVUjBueVKUbrngXOqKSPJ8HE3n1pShqAKcqrYW8MlF8pB4nnRnYzLWJ/P+/p8zFGywvfSWm7L6hGvJFWozdlx5wLTj4K5UuclS2XfPNkIDrt7BY5X2KVdt42NBLZbM5RdUB8iJFobpp0HzoKZI8TSn++9s0y2cM/uGn0zHRcz/b8P/PiiOJkRkm0XlFrXG19KuA6eBAUfWiHYAgTMZq2UCyOdCbDZEcF8SqCGjboFuTyI7OHTQ4PVFQY8uEmsqhes9jqiz7u7Ts7Ndy88rVAe10GiHrBdyAGf4AR4G9DFrA10fnTGIVLixX8GpNTGgsLFIOf+IQOUvdcV39PeCf2JA2vEhSqbiaiftgGwxxgbc8ENPXijj+wYztDzMBeTJUwZBheNLcD2Rqwrc//HYvbuG6aZSjPCA5DvD3QJMvdBdHM3HWvlyU0tN6xVAiECAvWQdSOks';

        const options = new Stimulsoft.Viewer.StiViewerOptions();
        options.height = '100%';
        options.appearance.scrollbarsMode = true;
        options.toolbar.showPrintButton = false;
        options.toolbar.showSaveButton = false;

        const viewer = new Stimulsoft.Viewer.StiViewer(options, 'StiViewer', false);

        const report = new Stimulsoft.Report.StiReport();

        report.loadDocument(this.data.source);
        viewer.report = report;
        viewer.renderHtml(this.viewerId);
    }

    private generateDesigner(): void {
        const options = new Stimulsoft.Designer.StiDesignerOptions();
        options.height = '100%';
        options.appearance.scrollbarsMode = true;

        const designer = new Stimulsoft.Designer.StiDesigner(options, 'StiDesigner', false);

        designer.report = new Stimulsoft.Report.StiReport();

        designer.report.loadFile('assets/sampleFiles/ReportSample.mrt');

        designer.renderHtml(this.viewerId);
    }
}
