import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConsts } from '@shared/AppConsts';
import { map, tap } from 'rxjs/operators';
import { Installation } from '@app/shared/common/pplus/models/installation';
import { LossesResults } from '../models/sizeCalcConfiguration';
import { Consumer, ConsumptionRate, ConsumptionContract, ConsumptionContracts, ConsumptionTemplate, ConsumptionTemplateUsage, EstimatedBillingResults } from '../models/sizeCalcConsumer';
import { DimensioningResults, ResultItem, DimComparativeResults, SizeComparativeInputData, Result } from '../models/sizeCalcDimensioning';
import { SizeCalcField } from '../models/sizeCalcField';
import { SizeResultsFinancial } from '../models/sizeCalcResults';
import { Calculation, MyCalculation } from '../models/sizeCalculation';
import { ApiCallResponse } from '@app/pplus-admin/pages/application-admin/models/application.interface';
import { PplusGridRequest } from '@shared/common/ui/components/pplus-table/pplus-table.model';

/**
 * Injectable
 */
@Injectable({
    providedIn: 'root'
})
export class PplusSizeService {

    dimensioningresults$: BehaviorSubject<DimensioningResults> = new BehaviorSubject<DimensioningResults>(null);
    dimResultSelected$: BehaviorSubject<ResultItem> = new BehaviorSubject<ResultItem>(null);
    dimAccumulationResultSelected$: BehaviorSubject<ResultItem> = new BehaviorSubject<ResultItem>(null);
    dimRowSelected$: BehaviorSubject<ResultItem[]> = new BehaviorSubject<ResultItem[]>(null);
    dimensioningComparative$: BehaviorSubject<DimComparativeResults> = new BehaviorSubject<DimComparativeResults>(null);
    configurationAutoResults$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    configurationManualResults$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    lossesResults$: BehaviorSubject<LossesResults> = new BehaviorSubject<LossesResults>(null);


    constructor(private http: HttpClient) {
    }


    /**
     * Obtiene la configuración inicial para un nuevo calculo
    */
    newSizeCalculation(): Observable<Calculation> {
        return this.http.get<Calculation>(AppConsts.remoteServiceBaseUrl + '/api/services/app/SizeCalculations/New').pipe(
            map((response: any) => response.result)
        );
    }

    /**
     * Obtiene un calculo por id
    */
    getSizeCalculation(id: number): Observable<Calculation> {
        return this.http.get<Calculation>(AppConsts.remoteServiceBaseUrl + `/api/services/app/SizeCalculations/GetById?calculationId=${id}`).pipe(
            map((response: any) => response.result)
        );
    }




    /**
     * Obtiene la configuración inicial para un nuevo calculo
    */
    createOrEditSizeCalculation(calculation: Calculation): Observable<Calculation> {
        return this.http.post<Calculation>(AppConsts.remoteServiceBaseUrl + '/api/services/app/SizeCalculations/CreateOrEdit', calculation).pipe(
            map((response: any) => response.result)
        );
    }

    /**
     * Obtiene la configuración inicial para una nueva instalación
    */
    newSizeInstallation(): Observable<Installation> {
        return this.http.get<Installation>(AppConsts.remoteServiceBaseUrl + '/api/services/app/SizeCalculations/NewInstallation').pipe(
            map((response: any) => response.result)
        );
    }

    /**
     * Obtiene la configuración inicial para un nuevo campo fotovoltaico
    */
    newField(): Observable<SizeCalcField> {
        return this.http.get<SizeCalcField>(AppConsts.remoteServiceBaseUrl + '/api/services/app/SizeCalculations/NewField').pipe(
            map((response: any) => response.result)
        );
    }

    /**
     * Obtiene la configuración inicial para un nuevo consumer
    */
    newSizeConsumer(): Observable<Consumer> {
        return this.http.get<Consumer>(AppConsts.remoteServiceBaseUrl + '/api/services/app/SizeCalculations/NewConsumer').pipe(
            map((response: any) => response.result)
        );
    }

    /**
     * Obtiene las tarifas de consumo disponibles
     * @returns comsumption rates
     */
    getComsumptionRates(): Observable<ConsumptionRate[]> {
        return this.http.get<ConsumptionRate[]>(AppConsts.remoteServiceBaseUrl + '/api/services/app/ConsumptionRates/GetAll').pipe(
            map((response: any) => response.result)
        );
    }

    /**
     * Obtiene las tarifas de consumo disponibles por tipo
     * @returns comsumption rates
     */
    getComsumptionRatesByType(type: number, marketType: number): Observable<ConsumptionRate[]> {
        return this.http.get<ConsumptionRate[]>(AppConsts.remoteServiceBaseUrl + `/api/services/app/ConsumptionRates/GetByType?type=${type}&marketType=${marketType}`).pipe(
            map((response: any) => response.result)
        );
    }

    /**
     * Obtiene una tarifa por id
     * @returns comsumption rate
     */
    getRate(id: number): Observable<ConsumptionRate> {
        return this.http.get<ConsumptionRate>(AppConsts.remoteServiceBaseUrl + '/api/services/app/ConsumptionRates/GetByRateId').pipe(
            map((response: any) => response.result)
        );
    }


    /**
     * Gets contracts
     * @returns contracts
     */
    getContracts(): Observable<ConsumptionContract[]> {
        return this.http.get<ConsumptionContract[]>(AppConsts.remoteServiceBaseUrl + '/api/services/app/ConsumptionContracts/GetAll').pipe(
            map((response: any) => response.result)
        );
    }

    /**
     * Gets contracts by rate
     * @param rateId
     * @returns contracts by rate
     */
    getContractsByRate(rateId: number): Observable<ConsumptionContracts> {
        const params = {
            'rateId': rateId.toString()
        };

        return this.http.get<ConsumptionContracts>(AppConsts.remoteServiceBaseUrl + '/api/services/app/AllConsumptionContracts/GetByRateId', { params }).pipe(
            map((response: any) => response.result)
        );
    }

    /**
     * Obtiene contratos por id de organización e id de tarifa
     * @param organizationId
     * @param rateId
     * @returns contracts
     */
    getContractsByOrganizationAndRate(organizationId: number, rateId: number): Observable<ConsumptionContract[]> {

        const params = {
            'organizationId': organizationId.toString(),
            'rateId': rateId.toString()
        };

        return this.http.get<ConsumptionContract[]>(AppConsts.remoteServiceBaseUrl + '/api/services/app/ConsumptionContracts/GetByOrganizationIdAndRateId', { params }).pipe(
            map((response: any) => response.result)
        );
    }

    /**
     * Gets consumption templates
     * @returns consumption templates
     */
    getConsumptionTemplates(): Observable<ConsumptionTemplate[]> {
        return this.http.get<ConsumptionTemplate[]>(AppConsts.remoteServiceBaseUrl + '/api/services/app/SizeConsumptionTemplates/GetAll').pipe(
            map((response: any) => response.result)
        );
    }

    /**
     * Gets consumption usages
     * @returns consumption templates
     */
    getConsumptionUsages(): Observable<ConsumptionTemplateUsage[]> {
        return this.http.get<ConsumptionTemplateUsage[]>(AppConsts.remoteServiceBaseUrl + '/api/services/app/SizeConsumptionTemplateUsages/GetAll').pipe(
            map((response: any) => response.result)
        );
    }

    /**
     * Gets usage data
     * @param usageId
     * @returns usage data
     */
    getUsageData(usageId: number): Observable<ConsumptionTemplateUsage> {
        const params = {
            'usageId': usageId.toString()
        };
        return this.http.get<ConsumptionTemplateUsage>(AppConsts.remoteServiceBaseUrl + '/api/services/app/SizeConsumptionTemplateUsages/GetById', { params }).pipe(
            map((response: any) => response.result)
        );
    }

    /**
     * Gets template usages
     * @param templateId
     * @returns template usages
     */
    getTemplateUsages(templateId: number): Observable<ConsumptionTemplateUsage[]> {
        const params = {
            'templateId': templateId !== null ? templateId.toString() : null
        };
        return this.http.get<ConsumptionTemplateUsage[]>(AppConsts.remoteServiceBaseUrl + '/api/services/app/SizeConsumptionTemplateUsages/GetByTemplateId', { params }).pipe(
            map((response: any) => response.result)
        );
    }

    /**
     * Get dimensioning results
     * @return Dimensioning results
     */
    getDimensioningResults(calculation: Calculation): Observable<DimensioningResults> {
        this.dimensioningresults$.next(null);
        this.updateDimensioningResultSelected(null);
        return this.http.post<DimensioningResults>(AppConsts.remoteServiceBaseUrl + '/api/services/app/SizeProEngine/Calculate?InstallationIndex=0&ConsumerIndex=0&CalcType=3', calculation).pipe(
            map((response: any) => response.result),
            tap((result: DimensioningResults) => this.dimensioningresults$.next(result))
        );
    }

    /**
     * Get dimensioning comparative results
     * @return Dimensioning comparative results
     */
    getDimensioningComparative(comparativeData: SizeComparativeInputData): Observable<DimComparativeResults> {
        return this.http.post<DimComparativeResults>(AppConsts.remoteServiceBaseUrl + '/api/services/app/SizeProEngine/CalculateComparative?InstallationIndex=0&ConsumerIndex=0', comparativeData).pipe(
            map((response: any) => response.result),
            tap((result: DimComparativeResults) => this.dimensioningComparative$.next(result))
        );
    }

    /**
     * Update dimensioning result selected
     * @param resultItem new result selected
     */
    updateDimensioningResultSelected(result: Result) {
        this.dimResultSelected$.next(result);
    }

    /**
     * Update dimensioning accumulation result selected
     * @param resultItem new result selected
     */
    updateDimensioningAccumulationResultSelected(resultItem: ResultItem) {
        this.dimAccumulationResultSelected$.next(resultItem);
    }

    /**
     * Update dimensioning row selected
     * @param rowSelected new row selected
     */
    updateDimensioningRowSelected(rowSelected: ResultItem[]) {
        this.dimRowSelected$.next(rowSelected);
    }

    getConfigurationCalculate(calculation: any): Observable<any> {
        this.configurationAutoResults$.next(null);
        return this.http.post<any>(AppConsts.remoteServiceBaseUrl + '/api/services/app/SizeProEngine/Calculate?InstallationIndex=0&CalcType=4', calculation).pipe(
            map((response: any) => response.result),
            tap((result: any) => this.configurationAutoResults$.next(result))
        );
    }

    addInverterToConfig(calculation: Calculation, installation: number, inverterId: number) {
        return this.http.post<any>(AppConsts.remoteServiceBaseUrl + `/api/services/app/SizeProEngine/AddInverterInConfig?InstallationIndex=${installation}&InverterId=${inverterId}`, calculation).pipe(
            map((response: any) => response.result)
        );
    }


    calculateManualConfiguration(calculation: Calculation, installation: number = 0) {
        this.configurationManualResults$.next(null);
        return this.http.post<any>(AppConsts.remoteServiceBaseUrl + `/api/services/app/SizeProEngine/CalculateManualConfig?InstallationIndex=${installation}`, calculation).pipe(
            map((response: any) => response.result),
            tap((result: any) => this.configurationManualResults$.next(result))
        );
    }

    getConfigurationLosses(calculation: any): Observable<LossesResults> {
        this.lossesResults$.next(null);
        return this.http.post<LossesResults>(AppConsts.remoteServiceBaseUrl + '/api/services/app/SizeProEngine/Calculate?ConsumerIndex=0&InstallationIndex=0&CalcType=5', calculation).pipe(
            map((response: any) => response.result),
            tap((result: any) => this.lossesResults$.next(result))
        );
    }
    /**
     * Calculates estimated billing
     * @param calculation
     * @returns estimated billing
     */
    calculateEstimatedBilling(calculation: Calculation): Observable<EstimatedBillingResults> {
        return this.http.post<EstimatedBillingResults>(AppConsts.remoteServiceBaseUrl + '/api/services/app/SizeProEngine/Calculate?InstallationIndex=0&ConsumerIndex=0&CalcType=1', calculation).pipe(
            map((response: any) => response.result)
        );
    }

    getOptimalDimensioningResult(dimensioningResults: DimensioningResults): Result {
        return dimensioningResults.peakPowerResults.find(result => result.isOptimal);
    }

    getOptimalAccumulationResult(result: Result): ResultItem {
        const accumulationResult = result.accumulationResults.find(ar => ar.isOptimal);
        if (accumulationResult) {
            return accumulationResult;
        } else {
            return result.accumulationResults[0];
        }
    }

    getGlobalOptimalAccumulationResult(dimensioningResults: DimensioningResults): ResultItem {

        const result = this.getOptimalDimensioningResult(dimensioningResults);
        if (result) {
            return this.getOptimalAccumulationResult(result);
        }

        return null;
    }

    getIndexForDimensioningResult(dimensioningResults: DimensioningResults, result: Result): number {
        return dimensioningResults.peakPowerResults.indexOf(result);
    }

    getIndexforGlobalOptimalAccumulationResult(dimensioningResults: DimensioningResults, result: ResultItem): number[] {
        let index: number[] = null;

        dimensioningResults.peakPowerResults.forEach((r, i) => {
            const _index = r.accumulationResults.indexOf(result);
            if (_index > -1) {
                index = [i, _index];
            }
        });

        return index;
    }


    /**
     * Get balance results
     * @return Balance results
     */
    getBalanceResults(calculation: any): Observable<any> {
        return this.http.post<any>(AppConsts.remoteServiceBaseUrl + '/api/services/app/SizeProEngine/Calculate?InstallationIndex=0&ConsumerIndex=0&CalcType=2', calculation).pipe(
            map((response: any) => response.result)
        );
    }


    getEconomicSummary(calculation: any): Observable<any> {
        return this.http.post<any>(AppConsts.remoteServiceBaseUrl + '/api/services/app/SizeProEngine/Calculate?InstallationIndex=0&ConsumerIndex=0&CalcType=6', calculation).pipe(
            map((response: any) => response.result)
        );
    }

    getFinancialSummary(calculation: any): Observable<SizeResultsFinancial> {
        return this.http.post<SizeResultsFinancial>(AppConsts.remoteServiceBaseUrl + '/api/services/app/SizeProEngine/Calculate?InstallationIndex=0&ConsumerIndex=0&CalcType=7', calculation).pipe(
            map((response: any) => response.result)
        );
    }


    saveCalculation(calculation: any): Observable<Boolean> {
        return this.http.post<Boolean>(AppConsts.remoteServiceBaseUrl + '/api/services/app/SizeCalculations/CreateOrEdit', calculation).pipe(
            map((response: any) => response.result)
        );
    }

    /** MIS CALCULOS */

    /**
     * Obtiene mis calculos
    */
    getSizeMyCalculations(filter: PplusGridRequest): Observable<any> {
        return this.http.post(AppConsts.remoteServiceBaseUrl + `/api/services/app/SizeCalculations/GetMyCalculations`, filter).pipe(
            map((response: ApiCallResponse<null>) => response.result)
        );
    }
    /*
            return of({
                totalCount: 2,
                items: [
                    {
                        id: 37,
                        description: 'Mi cálculo',
                        creationTime: '2021-04-09T16:40:54.2989086+00:00',
                        lastModificationTime: '2021-04-09T16:40:54.2989086+00:00',
                        creatorUserId: 22,
                        location: 'Madrid',
                        shared: false,
                        locked: true
                    },
                    {
                        id: 38,
                        description: 'Mi cálculo 2',
                        creationTime: '2021-04-09T16:40:54.2989086+00:00',
                        lastModificationTime: '2021-04-09T16:40:54.2989086+00:00',
                        creatorUserId: 22,
                        location: 'Madrid',
                        shared: false,
                        locked: true
                    }
                ]
            });
        }
    */

    getSizeMyCalculationsColumnsDef(): Observable<any> {
        return of([
            {
                "headerName": "Id",
                "field": "id",
                "type": "number",
                "width": 100,
                "hide": false,
                "pinned": false,
                "sortable": true,
                "filter": true,
                "sortedAt": 0
            },
            {
                "headerName": "Nombre",
                "field": "description",
                "type": "string",
                "width": null,
                "hide": false,
                "pinned": false,
                "sortable": true,
                "filter": true,
                "sortedAt": 1
            },
            {
                "headerName": "Fecha de creación",
                "field": "creationTime",
                "type": "date",
                "width": null,
                "hide": false,
                "pinned": false,
                "sortable": true,
                "filter": true,
                "sortedAt": 2
            },
            {
                "headerName": "Fecha de última modificación",
                "field": "lastModificationTime",
                "type": "date",
                "width": null,
                "hide": false,
                "pinned": false,
                "sortable": true,
                "filter": true,
                "sortedAt": 3
            },
            {
                "headerName": "Usuario",
                "field": "userName",
                "type": "string",
                "width": null,
                "hide": false,
                "pinned": false,
                "sortable": true,
                "filter": true,
                "sortedAt": 4
            },
            {
                "headerName": "Ubicación",
                "field": "ubication",
                "type": "string",
                "width": null,
                "hide": false,
                "pinned": false,
                "sortable": true,
                "filter": true,
                "sortedAt": 5
            },
            {
                "headerName": "Compartido",
                "field": "shared",
                "type": "boolean",
                "width": null,
                "hide": false,
                "pinned": false,
                "sortable": true,
                "filter": true,
                "sortedAt": 6
            },
            {
                "headerName": "Bloquear",
                "field": "locked",
                "type": "boolean",
                "width": null,
                "hide": false,
                "pinned": false,
                "sortable": true,
                "filter": true,
                "sortedAt": 7
            }
        ]);
    }

    saveMyCalculation(myCalculation: MyCalculation): Observable<any> {
        return this.http.post<MyCalculation>(AppConsts.remoteServiceBaseUrl + '/api/services/app/SizeCalculations/EditMyCalculation', myCalculation).pipe(
            map((response: any) => response.result)
        );
    }

    saveMyCalculationAs(myCalculation: MyCalculation): Observable<any> {
        return this.http.post<MyCalculation>(AppConsts.remoteServiceBaseUrl + '/api/services/app/SizeCalculations/SaveAsMyCalculation', myCalculation).pipe(
            map((response: any) => response.result)
        );
    }

    assignMyCalculations(calculations: number[], userId: number): Observable<any> {

        let calculationids = '';
        calculations.forEach(x => {
            calculationids += '&calculationIds=' + x;
        });

        return this.http.get<any>(AppConsts.remoteServiceBaseUrl + `/api/services/app/SizeCalculations/AssignToUser?userId=${userId}${calculationids}`).pipe(
            map((response: any) => response.result)
        );
    }

    shareMyCalculations(calculations: number[], users: number[]): Observable<any> {

        let userids = '';
        users.forEach(x => {
            userids += '&userIds=' + x;
        });

        return this.http.get(AppConsts.remoteServiceBaseUrl + `/api/services/app/SizeCalculationsSharing/Share?calculationId=${calculations}${userids}`).pipe(
            map((response: any) => response.result)
        );
    }

    lockMyCalculations(calculations: number[]): Observable<any> {
        return this.http.get(AppConsts.remoteServiceBaseUrl + `/api/services/app/SizeCalculations/Lock?calculationId=${calculations}`).pipe(
            map((response: any) => response.result)
        );
    }

    getMyCalculationById(id: number): Observable<MyCalculation> {

        return this.http.get(AppConsts.remoteServiceBaseUrl + `/api/services/app/SizeCalculations/GetMyCalculationById?calculationId=${id}`).pipe(
            map((response: ApiCallResponse<null>) => response.result)
        );
    }


    getSizeMyCalculationUsers(): Observable<any> {
        return this.http.get(AppConsts.remoteServiceBaseUrl + `/api/services/app/User/GetAll`).pipe(
            map((response: ApiCallResponse<null>) => response.result)
        );
    }


    getInstallationFieldCostsByCostType(technology: number, ubication:number, costType: number): Observable<any> {
        return this.http.get(AppConsts.remoteServiceBaseUrl + `/api/services/app/SizeInstallationFieldCosts/GetByCostType?technology=${technology}&ubication=${ubication}&costType=${costType}`).pipe(
            map((response: ApiCallResponse<null>) => response.result)
        );
    }
}


