import { Component, AfterViewInit, Input } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
const uuidv4 = require('uuid/v4');

declare var Stimulsoft: any;


@Component({
  selector: 'pplus-report-viewer',
  templateUrl: './pplus-report-viewer.component.html',
  styleUrls: ['./pplus-report-viewer.component.scss']
})
export class PPlusReportViewerComponent implements AfterViewInit {

  @Input('report') report: any;
  @Input('data') data: any;
  @Input('options') options:  any;

  defaultOptions: any;

  viewerId = 'pplus_viewer' + uuidv4();

  constructor() {
    this.defaultOptions = new Stimulsoft.Viewer.StiViewerOptions();
    this.defaultOptions.toolbar.showPrintButton = true;
    this.defaultOptions.toolbar.showSaveButton = true;
    this.defaultOptions.toolbar.showOpenButton = false;
  }

  ngAfterViewInit(): void {
    this.showViewer();
  }


  private showViewer(): void {
    Stimulsoft.Base.StiLicense.key = AppConsts.stimulSoftKey;

    let options = this.defaultOptions;
    if (this.options) {
        options =  this.options;
    }

    const viewer = new Stimulsoft.Viewer.StiViewer(options, 'StiViewer', false);
    const report = new Stimulsoft.Report.StiReport();
    report.load(this.report);

    const dataSet = new Stimulsoft.System.Data.DataSet();
    dataSet.readJson(this.data);
    report.regData('', '', dataSet);
    viewer.report = report;
    viewer.renderHtml(this.viewerId);
  }

}
