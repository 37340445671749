import { InjectionToken, TemplateRef } from '@angular/core';

export class AlertData {
  type: AlertType;
  text?: string;
  title?: string;
  template?: TemplateRef<any>;
  templateContext?: {};
}

export type AlertType = 'warning' | 'info' | 'success';

export interface AlertConfig {
    position?: {
        top: number;
        right: number;
        bottom: number;
        left: number;
    };
    animation?: {
        fadeOut: number;
        fadeIn: number;
    };
}

export const defaultAlertConfig: AlertConfig = {
    position: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
    },
    animation: {
        fadeOut: 2500,
        fadeIn: 300,
    },
};

export const ALERT_CONFIG_TOKEN = new InjectionToken('alert-config');
