import { Component, EventEmitter, Input, OnInit, Optional, Output, Self, ViewChild, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { SlideColors } from './pplus-slide-colors-enum';

@Component({
  selector: 'pplus-slide-toggle',
  templateUrl: './pplus-slide-toggle.component.html',
  styleUrls: ['./pplus-slide-toggle.component.scss']
})
export class PplusSlideToggleComponent implements ControlValueAccessor {

  @Input() color: SlideColors = SlideColors.ENERGY_YELLOW;
  @Input() checked = false;
  @Input() disabled = false;
  @Input() label: string;

  @Output() onSwitchChanged: EventEmitter<any> = new EventEmitter();

  defaultColor = '#a0a5ba';

  constructor(
    @Self() @Optional() private ngControl: NgControl
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }

  }
  writeValue(checked: any): void {
    this.checked = checked;
  }

  onClick() {
    if (!this.disabled) {
      this.checked = !this.checked;
      this.onChange(this.checked);
      this.onSwitchChanged.emit(this.checked);
    }
  }

  /**
   * Write form disabled state to the DOM element (model => view)
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Update form when DOM element value changes (view => model)
   */
  registerOnChange(fn: any): void {
    // Store the provided function as an internal method.
    this.onChange = fn;
  }

  /**
   * Update form when DOM element is blurred (view => model)
   */
  registerOnTouched(fn: any): void {
    // Store the provided function as an internal method.
    this.onTouched = fn;
  }

  onChange(event) { }
  onTouched() { }

}
