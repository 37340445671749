import { Component, ViewChild, OnInit, Optional, OnDestroy, ViewContainerRef, ComponentFactoryResolver, ComponentRef } from '@angular/core';
import { TabsService } from './tabs.service';
import { ActivatedRoute, Router, NavigationEnd, RouterEvent } from '@angular/router';
import { filter, take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Tab } from './tabs';
import { TabContentComponent } from './tab.component';
import { AppNavigationService } from '../../layout/nav/app-navigation.service';
import { AppMenu } from '@app/shared/layout/nav/app-menu';
import { MenuItem } from 'primeng/api';
import { AppMenuItem } from '@app/shared/layout/nav/app-menu-item';
const uuidv4 = require('uuid/v4');

@Component({
    selector: 'app-tabset',
    styleUrls: ['tabset.component.less'],
    templateUrl: 'tabset.component.html'
  })
export class TabSetComponent  implements OnInit, OnDestroy {
    @ViewChild('container2', { static: true, read: ViewContainerRef }) entry: ViewContainerRef;

    tabList: any[];
    selectedTab: number = null;
    public destroyed = new Subject<any>();
    menu: AppMenu = null;


    constructor(
        @Optional() private tabsService: TabsService,
        private route: ActivatedRoute,
        private router: Router,
        private resolver: ComponentFactoryResolver,
        private _appNavigationService: AppNavigationService
    ) {

    }

    ngOnInit(): void {

        this._appNavigationService.getMenu().subscribe( menu => {
            if (menu && menu.items.length && !this.menu) {
                this.menu = menu;
                //first-time
                this.createTab();

                this.router.events.pipe(
                    filter((event: RouterEvent) => event instanceof NavigationEnd),
                    takeUntil( this.destroyed)
                ).subscribe(() => {
                    this.createTab();
                });

                this.initTabs();
            }
        });


    }

    ngOnDestroy(): void {
        this.destroyed.next();
        this.destroyed.complete();
    }

    /**
    * Function to Track by tab uuid
    */
    trackById = (index: number , tab: any) => tab.instance.tabI.uuid;


    initTabs() {
        this.tabsService._tabList.subscribe(tabList => {
            if (tabList) {
                this.tabList = tabList;
            }
        });

        this.tabsService._selectedTab.subscribe(selected => {
            this.selectedTab = selected;
        });
    }

    createTab() {
        if (this.route.snapshot.firstChild && this.route.snapshot.firstChild.data && this.route.snapshot.firstChild.data.component && this.route.snapshot.firstChild.data.name) {
            const menuItem: AppMenuItem = this.findMenuItem(this.menu.items, this.router.url);
            if (menuItem) {
                this.createNewTab(this.route.snapshot.firstChild.data.component, menuItem.name , menuItem.id);
            } else {
                this.createNewTab(this.route.snapshot.firstChild.data.component, this.route.snapshot.firstChild.data.name , 0);
            }

            if (!this.tabsService.currentAppUrl) {
                this.tabsService.currentAppUrl = this.route.snapshot.parent.pathFromRoot.filter(o => o.url.length).map(o => o.url[0]).join('/');
            }

        } else if ( !this.route.snapshot.firstChild ) { //is tabset
            // set app as active if is empty
            if (!this.tabsService.currentAppUrl){
                this.tabsService.currentAppUrl = this.router.url;
            }

        }
    }

    newTab() {
        if (this.selectedTab !== null) {
            const currentTab = this.tabsService.getSelectedTab();
            this.createNewTab(currentTab.instance.tabI.component, currentTab.instance.tabI.name, currentTab.instance.tabI.id);
        }
    }

    selectTab(index: number) {
        this.tabsService.selectTab(index);
    }

    closeTab(tab) {
        this.tabsService.removeTab(tab);
    }

    deleteCurrentTab() {
        if (this.selectedTab !== null) {
            this.tabsService.deleteCurrentTab();
        }
    }

    cloneTab() {
        if (this.selectedTab !== null) {
            const currentTab: ComponentRef<TabContentComponent>  = this.tabsService.getSelectedTab();
            this.createNewTab(currentTab.instance.tabI.component, currentTab.instance.tabI.name, currentTab.instance.tabI.id, currentTab.instance.tabI.ref.instance.sharedData);
        }
    }

    editTabName(name: string, tab) {
        tab.instance.tabI.ref.instance.setName(name);
    }


    createNewTab(component: any, name: string, id: number, sharedData: any = null) {
        const factory = this.resolver.resolveComponentFactory<TabContentComponent>(TabContentComponent);
        const tabComponentRef = factory.create(this.entry.parentInjector);
        const uuid = uuidv4();
        tabComponentRef.instance.tabI = {
            uuid,
            component,
            name,
            id
        };
        this.tabsService.addTab(tabComponentRef);
        this.createNewTabChild(tabComponentRef , component, name, id, sharedData);
    }

    createNewTabChild(tabRef: ComponentRef<TabContentComponent>, component: any, name: string, id: number, sharedData: any = null): ComponentRef<TabContentComponent> {

        this.entry.insert(tabRef.hostView);

        const factoryChild = this.resolver.resolveComponentFactory<Tab>(component);
        const childComponentRef = tabRef.instance.entry.createComponent<Tab>(factoryChild);
        childComponentRef.instance.uuid = tabRef.instance.tabI.uuid;
        childComponentRef.instance.id = id;
        childComponentRef.instance.name = name;
        childComponentRef.instance.updatePermissions();

        if (sharedData) {
            childComponentRef.instance.sharedData = JSON.parse(JSON.stringify(sharedData));
            childComponentRef.changeDetectorRef.detectChanges();
        }

        tabRef.instance.tabI.ref = childComponentRef;
        tabRef.instance.isNameEditable = childComponentRef.instance.isNameEditable;

        return tabRef;
    }


    findMenuItem(items: AppMenuItem[], route: string) {
        let menuItem = null;
        items.forEach(item => {
            if (item.items && item.items.length && !menuItem) {
                menuItem = this.findMenuItem(item.items, route);
            } else {
                if (item.route === route && !menuItem) {
                    menuItem = item;
                }
            }
        });
        return menuItem;
    }

}
