import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ChartComponent } from 'ng-apexcharts';

@Component({
  selector: 'pplus-horizontal-stacked-bar-chart',
  templateUrl: './horizontal-stacked-bar-chart.component.html',
  styleUrls: ['./horizontal-stacked-bar-chart.component.scss']
})
export class PplusHorizontalStackedBarChartComponent implements OnInit, OnChanges {
  @ViewChild('chartObj', {static: false}) chart: ChartComponent;

  @Input() labels: string[];
  @Input() colors: string[];
  @Input() data: any[];

  chartOptions = {
    series: [],
    colors: [],
    chart: {
      animations: {
        enabled: false
      },
      type: 'bar',
      height: 350,
      width: '100%',
      stacked: true,
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '30%'
      }
    },
    xaxis: {
      categories: [],
      labels: {
        formatter: function(val) {
          return val + "kW";
        }
      }
    },
    yaxis: {
      title: {
        text: undefined
      }
    },
    tooltip: {
      y: {
        formatter: function(val) {
          return val + "K";
        }
      }
    },
    fill: {
      opacity: 1
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false
    }
  };


  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    this.chartOptions.xaxis.categories = this.labels;
    this.chartOptions.colors = this.colors;
    this.chartOptions.series = this.getSeriesData(this.data);
    if (this.chart) {
        this.data.forEach(d => {
            if (d.visible) {
                this.chart.showSeries(d.name);
            } else {
                this.chart.hideSeries(d.name);
            }
        });
    }
  }

  ngOnInit() {
  }

  getSeriesData(data: any[]){
    let series = [];
    data.forEach((dataRow: any) => {
      series.push({
        name: dataRow.name,
        data: dataRow.value
      });
    });
    return series;
  }

}
