import { Component, EventEmitter, forwardRef, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TreeNode } from 'primeng/api';
import { PplusTreeMode } from '../pplus-tree/pplus-tree.interfaces';

@Component({
  selector: 'pplus-tree-step',
  templateUrl: './pplus-tree-step.component.html',
  styleUrls: ['./pplus-tree-step.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PplusTreeStepComponent),
      multi: true
    }
  ]
})
export class PplusTreeStepComponent implements ControlValueAccessor, OnChanges {

  @Input() title = '';
  @Input() step: number;
  @Input() treeData: TreeNode[];
  @Input() expanded = false;
  @Input() selectionMode: string;
  @Input() propagateDown = false;
  @Input() propagateUp = false;
  @Input() nextButton = true;
  @Input() disabled = false;
  @Input() isValid = false;
  @Input() error = false;

  selectionNames = '';
  selection: any[] = [];
  TreeMode = PplusTreeMode;

  @Output() onNextClicked = new EventEmitter();
  @Output() onHeaderClicked = new EventEmitter();
  @Output() onSelectionChange = new EventEmitter();

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.treeData) {
      if (!changes.treeData.firstChange) {
        this.isValid = false;
        this.selection = [];
        this.updateSelection();
      }
    }
    if (changes.expanded) {
      if (!changes.expanded.firstChange) {
        this.expanded = changes.expanded.currentValue;
      }
    }
  }

  toggleShowTree() {
    if (!this.disabled) {
      this.expanded = !this.expanded;
    }
  }

  onNext() {
    if (this.hasSelection()) {
      let selectionIds = [];
      this.selection.forEach((selectedNode) => {
        selectionIds.push(selectedNode.data);
      });
      this.isValid = true;
      this.propagateChange(selectionIds);
      this.onSelectionChange.emit(selectionIds);
    }
    this.onNextClicked.emit();
  }

  updateSelection() {
    if (this.selection === null) {
      this.selection = [];
    }
    this.selection = [...this.selection];
    this.updateSelectionNames();
  }

  updateSelectionNames() {
    const separator = '/';
    this.selectionNames = '';
    if (this.selection.length > 0) {
      this.selection.forEach((selectionItem: TreeNode) => {
        this.selectionNames += selectionItem.label + separator;
      });
      this.selectionNames = this.selectionNames.slice(0, -1);
    }
  }

  hasSelection() {
    if (this.selection === null) {
      return false;
    }
    return this.selection.length > 0;
  }

  setSelection(tree: TreeNode[], ids: number[]) {
    tree.forEach((node) => {
      if (ids.includes(node.data)) {
        this.selection.push(node);
      }
      if (node.children) {
        this.setSelection(node.children, ids);
      }
    });
    this.updateSelection();
  }

  writeValue(value: any): void {
    if (!Array.isArray(value)) {
      value = [value];
    }
    if (this.treeData) {
      this.setSelection(this.treeData, value);
    }
    if (this.selection.length > 0) {
      this.isValid = true;
    }
  }

  propagateChange = (_: any) => { };
  propagateTouch = (_: any) => { };

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void { }
}
