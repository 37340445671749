import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppModel } from './appModel';

@Component({
  selector: 'pplus-application-card',
  templateUrl: './pplus-application-card.component.html',
  styleUrls: ['./pplus-application-card.component.scss']
})
export class PplusApplicationCardComponent implements OnInit {

  @Input() app: AppModel;
  @Input() clickable: boolean;

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

  openApp() {
    if (this.clickable) {
      this.router.navigate([this.app.src]);
    }
  }

}
