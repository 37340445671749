import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, IAfterGuiAttachedParams } from 'ag-grid-community';

@Component({
  selector: 'pplus-table-edit-renderer',
  templateUrl: './pplus-table-edit-renderer.component.html',
  styleUrls: ['./pplus-table-edit-renderer.component.scss']
})
export class PplusTableEditRendererComponent implements ICellRendererAngularComp {

  params: any;
  editActive = false;

  constructor() { }
  refresh(params: any): boolean {
    let editingCells = params.api.getEditingCells();
    this.editActive = editingCells.some((cell) => {
      return cell.rowIndex === params.node.rowIndex;
    });

    return true;
  }
  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    //TODO
  }

  doEdit() {
    if (!this.editActive) {
      //this.params.api.setFocusedCell(this.params.node.rowIndex, 'period');
      //this.params.api.startEditingCell({ rowIndex: this.params.node.rowIndex, colKey: 'period' });
      this.params.api.startEditingCell({ rowIndex: this.params.node.rowIndex, colKey: this.params.columnKey});
      this.editActive = true;
    } else {
      this.editActive = false;
      this.params.api.stopEditing();
    }
  }

}
