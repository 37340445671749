import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterApplicationsFormComponentComponent } from './filter-applications-form-component/filter-applications-form-component.component';
import { PPlusUIModule} from 'shared/common/ui/components/pplus-ui.module';
import { ReactiveFormsModule } from '@angular/forms';
import { UtilsModule } from '@shared/utils/utils.module';


@NgModule({
  declarations: [FilterApplicationsFormComponentComponent],
  imports: [
    CommonModule,
    PPlusUIModule,
    ReactiveFormsModule,
    UtilsModule
  ],
  exports: [FilterApplicationsFormComponentComponent]
})
export class FilterApplicationsFormModuleModule { }
