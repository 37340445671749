import { Component, OnInit, Input, Output, EventEmitter, Self, Optional } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'pplus-multiselect',
  templateUrl: './pplus-multiselect.component.html',
  styleUrls: ['./pplus-multiselect.component.scss']
})
export class PplusMultiselectComponent implements OnInit, ControlValueAccessor {

    @Input() disabled: boolean;
    @Input() readonly = false;
    @Input() maxSelectedLabels = 1;
    @Input() options: any[];
    @Input() label: string;
    @Input() filter = false;
    @Output() onChange: EventEmitter<any> = new EventEmitter();

    value: any = '';

    constructor(
        @Self() @Optional() private ngControl: NgControl
    ) {
        if (this.ngControl) {
        this.ngControl.valueAccessor = this;
        }

    }

    writeValue(obj: any): void {
        this.value = obj;
    }

    registerOnChange(fn: any): void {
        this.onChangeFn = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    ngOnInit() {
    }

    onOptionClicked(event: any) {
        console.log(this.value);
        this.onChangeFn(this.value);
        this.onChange.emit(this.value);
    }

    onChangeFn = (_: any) => { };
    onTouched = (_: any) => { };
}
