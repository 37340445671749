import { Component, OnInit, Input, Self, Optional, EventEmitter, Output } from '@angular/core';
import { FormGroup, NgControl, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'pplus-entity-selector',
  templateUrl: './pplus-entity-selector.component.html',
  styleUrls: ['./pplus-entity-selector.component.scss']
})
export class PplusEntitySelectorComponent implements OnInit, ControlValueAccessor {

  @Input() labelField = 'name';
  @Input() elements: FormGroup[];
  @Input() current: FormGroup;
  @Output() selectElement: EventEmitter<any> = new EventEmitter<any>();
  showElementSelector = false;
  value: any;
  onChangeFn = (_: any) => { };
  onTouched = (_: any) => { };

    constructor(
    @Self() @Optional() private ngControl: NgControl
    ) {
        if (this.ngControl) {
        this.ngControl.valueAccessor = this;
        }

    }

    /**
     * Write form value to the DOM element (model => view)
     */
    writeValue(value: any): void {
        this.value = value;
    }

    /**
     * Write form disabled state to the DOM element (model => view)
     */
    setDisabledState(isDisabled: boolean): void {
    }

    /**
     * Update form when DOM element value changes (view => model)
     */
    registerOnChange(fn: any): void {
        this.onChangeFn = fn;
    }

    /**
     * Update form when DOM element is blurred (view => model)
     */
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }




  ngOnInit() {
  }

  getCurrentElementIndex() {
    if (this.current) {
        return this.elements.indexOf(this.current) + 1;
    } else {
        return 0;
    }

  }

  onSelectElement(element: number) {
    this.selectElement.emit(element);
    this.showElementSelector = false;
  }

  toggleElementSelector() {
    this.showElementSelector = !this.showElementSelector;
  }


}
