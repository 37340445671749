import { Component, Input, OnInit } from '@angular/core';
import { BarChartGradient } from './bar-chart-model';

@Component({
  selector: 'pplus-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class PplusBarChartComponent implements OnInit {

  @Input() data: BarChartGradient;

  separators = [0, 20, 40, 60, 80];

  constructor() { }

  ngOnInit() {
  }

}
