import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { EChartOption } from 'echarts';
import * as echarts from 'echarts';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';

@Component({
    selector: 'pplus-vertical-bar-chart',
    templateUrl: './vertical-bar-chart.component.html',
    styleUrls: ['./vertical-bar-chart.component.scss']
})
export class PplusVerticalBarChartComponent implements OnChanges {

    @Input() data: VerticalChartData;
    @Input() toolboxVisible = false;
    @Input() isGradient = false;
    @Input() joined = false;

    joinedBarWidth = 15;

    chartOptions: EChartOption = {
        tooltip: {
            trigger: 'item',
            axisPointer: {
                type: 'line'
            }
        },
        legend: {
            show: false,
        },
        grid: {
            left: '1%',
            right: '1%',
            bottom: '3%',
            containLabel: true
        },
        xAxis:
        {
            type: 'category',
            data: [],
        },
        yAxis:
        {
            type: 'value',
            splitLine: {
                lineStyle: {
                    type: 'dashed'
                }
            }
        }
        ,
        toolbox: {},
        series: [],
    };


    constructor(
        private _appLocalizationService: AppLocalizationService
    ) { }


    ngOnChanges(changes: SimpleChanges): void {
        this.chartOptions.legend = {...this.chartOptions.legend, ...this.data.legend};
        this.chartOptions.series = this.getSeries();
        this.chartOptions.xAxis.data = this.data.steps ? this.data.steps : [];
        this.chartOptions.toolbox = this.setToolbox();
        this.chartOptions = {...this.chartOptions, ...this.chartOptions};
    }

    getSeries() {
        const series = [];
        let serie: SerieInterface;
        this.data.data.forEach((type, index) => {
            let opacity = 1;
            type.sections.forEach((section, i) => {
                serie = {
                    id: type.name + '.' + section.name,
                    name: section.name,
                    data: section.data,
                    stack: type.stack,
                    type: 'bar',
                    barGap: this.joined ? '0%' : null,
                    barWidth: this.joined ? this.joinedBarWidth : null,
                    itemStyle: {
                        color: this.getItemColor(type.colors.length === 1 ? type.colors[0] : type.colors[i], opacity),
                    },
                };
                series.push(serie);
                opacity -= 0.12;
            });
        });
        return series;
    }

    getItemColor(color: string, opacity: number) {
        let op = this.isGradient ? opacity : 1;
        let colorRGB = this.getrgbColor(color);

        return new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 1,
            color: `rgb(${colorRGB.r},${colorRGB.g},${colorRGB.b}, ${op})`
        }])
    }

    getrgbColor(color: string) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }

    setToolbox() {
        return this.toolboxVisible ? {
            feature: {
                dataZoom: {
                    yAxisIndex: 'none',
                    title: {
                        zoom: this._appLocalizationService.l('Ampliar'),
                        back: this._appLocalizationService.l('Reducir')
                    }
                },
                restore: {
                    title: this._appLocalizationService.l('Volver al estado inicial'),
                },
                saveAsImage: {
                    title: this._appLocalizationService.l('Guardar'),
                }
            },
            emphasis: {
                iconStyle: {
                    borderColor: '#ff9400'
                }
            }
        } : null;
    }

    onChartInit(e: any) {
    }
}

export interface SerieInterface {
    id?: string;
    name?: string;
    type?: string;
    stack?: string;
    data?: number[];
    barGap?: string;
    barWidth?: number;
    itemStyle?: { color: any };
}

export interface VerticalChartData {
    legend?: any;
    data: VerticalItemChartData[];
    steps?: string[];
}

export interface VerticalItemChartData {
    name?: string;
    sections?: VerticalChartSection[];
    colors?: string[];
    stack?: string;
}

export interface VerticalChartSection {
    name?: string;
    data: number[];
}
