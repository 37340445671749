import { Component, OnInit, Input, Self, Optional } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'pplus-input-password',
  templateUrl: './pplus-password-input.component.html',
  styleUrls: ['./pplus-password-input.component.scss']
})
export class PplusPasswordInputComponent implements OnInit, ControlValueAccessor {
  @Input() disabled: boolean;
  @Input() placeholder = '';
  @Input() iconClass = '';
  @Input() styleClass = '';
  @Input() showPassword = false;
  @Input() label: string;
  @Input() error = false;
  @Input() errors;

  value: any = '';

  constructor(
    @Self() @Optional() private ngControl: NgControl
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }

  }

  ngOnInit() {}

  /**
   * Write form value to the DOM element (model => view)
   */
  writeValue(value: any): void {
    this.value = value;
  }

  /**
   * Write form disabled state to the DOM element (model => view)
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Update form when DOM element value changes (view => model)
   */
  registerOnChange(fn: any): void {
    // Store the provided function as an internal method.
    this.onChange = fn;
  }

  /**
   * Update form when DOM element is blurred (view => model)
   */
  registerOnTouched(fn: any): void {
    // Store the provided function as an internal method.
    this.onTouched = fn;
  }

  onChange(event) {}
  onTouched() {}

}
