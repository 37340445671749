import { HttpClient } from '@angular/common/http';
import { Component, AfterViewInit, Input } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
const uuidv4 = require('uuid/v4');


declare var Stimulsoft: any;


@Component({
  selector: 'lib-pplus-report-designer',
  templateUrl: './pplus-report-designer.component.html',
  styleUrls: ['./pplus-report-designer.component.scss']
})
export class PPlusReportDesignerComponent implements AfterViewInit {
  @Input('report') report: any;
  @Input('data') data: any;
  @Input('options') options:  any;

  designerId = 'pplus_designer' + uuidv4();

  defaultOptions: any;

  constructor() {
    this.defaultOptions = new Stimulsoft.Designer.StiDesignerOptions();
    //TODO: ver si ponemos alguna option por defecto en el designer
  }

  ngAfterViewInit(): void {
    this.showDesigner();
  }



  private showDesigner(): void {

    Stimulsoft.Base.StiLicense.key = AppConsts.stimulSoftKey;

    let options = this.defaultOptions;
    if (this.options) {
        options =  this.options;
    }
    const designer = new Stimulsoft.Designer.StiDesigner(options, 'StiDesigner', false);



    const report = new Stimulsoft.Report.StiReport();
    report.load(this.report);

    const dataSet = new Stimulsoft.System.Data.DataSet();
    dataSet.readJson(this.data);
    report.dictionary.databases.clear();
    report.regData('', '', dataSet);
    designer.report = report;
    designer.renderHtml(this.designerId);

  }
}
