import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'pplus-selector-button',
  templateUrl: './pplus-selector-button.component.html',
  styleUrls: ['./pplus-selector-button.component.scss']
})
export class PplusSelectorButtonComponent implements OnInit {

    @Input() type: string;

    @Input() iconPos = 'right';

    @Input() icon = 'pplus-icon-right';

    @Input() label: string;

    @Input() disabled: boolean;

    @Input() style: any;

    @Input() styleClass: string;

    @Output() onClick: EventEmitter<any> = new EventEmitter();

    @Output() onFocus: EventEmitter<any> = new EventEmitter();

    @Output() onBlur: EventEmitter<any> = new EventEmitter();


  constructor() { }

  ngOnInit() {
  }

}
