import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'pplus-donut-rounded-chart',
  templateUrl: './donut-rounded-chart.component.html',
  styleUrls: ['./donut-rounded-chart.component.scss']
})
export class PplusDonutRoundedChartComponent implements OnInit, OnChanges {

  @Input() legend: string;
  @Input() value = 0;
  @Input() color = '#f2f2f2';
  @Input() unitValue = '';

  donutChart = {
    series: [this.value],
    chart: {
        height: 200,
        type: 'radialBar',
    },
    plotOptions: {
        radialBar: {
            offsetY: 0,
            startAngle: 0,
            endAngle: 360,
            hollow: {
                margin: 5,
                size: '30%',
                background: 'transparent',
                image: undefined,
            },
            track: {
                show: true,
                startAngle: 0,
                endAngle: 360,
                background: '#f2f2f2',
                strokeWidth: '97%',
                opacity: 1,
                margin: 12,
                dropShadow: {
                    enabled: false,
                    top: 0,
                    left: 0,
                    blur: 3,
                    opacity: 0.5
                }
            },
            dataLabels: {
                name: {
                    show: false,
                },
                value: {
                    show: false,
                },
                total: {
                    show: false,
                }
            }
        }
    },
    stroke: {
        lineCap: 'round'
    },
    colors: [this.color],
    legend: {
        show: false,
    },
};

  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    this.donutChart.series = [this.value];
    this.donutChart.colors = [this.color];
  }

  ngOnInit() {
  }

}
