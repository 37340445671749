import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'pplus-horizontal-stepper',
  templateUrl: './pplus-horizontal-stepper.component.html',
  styleUrls: ['./pplus-horizontal-stepper.component.scss']
})
export class PplusHorizontalStepperComponent implements OnInit {

  @Input() steps: any[];
  @Input() currentStep = 0;
  @Output() changeStep: EventEmitter<number> = new EventEmitter<number>();
  @Output() clickDisabledStep: EventEmitter<number> = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {

  }

  goToStep(index: number) {
      if (!this.isStepDisabled(index)) {
        this.currentStep = index;
        this.changeStep.emit(this.currentStep);
      } else {
        this.clickDisabledStep.emit(index);
      }
  }

  isStepDisabled(index: number): boolean {
    if (!this.steps[index].disabled || (this.steps[index].disabled && !this.steps[index].disabled())) {
        return false;
    } else {
        return true;
    }
  }
}
