import { Component, OnInit, Input, Output, EventEmitter, Self, Optional } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'pplus-dropdown',
  templateUrl: './pplus-dropdown.component.html',
  styleUrls: ['./pplus-dropdown.component.scss']
})
export class PplusDropdownComponent implements OnInit, ControlValueAccessor {


  @Input() scrollHeight = '200px';
  @Input() styleClass: string;
  @Input() readonly = false;
  @Input() appendTo: any;
  @Input() placeholder: string;
  @Input() dataKey: string;
  @Input() optionLabel: string;
  @Input() autoDisplayFirst = false;
  @Input() disabled: boolean;
  @Input() options: any[];
  @Input() label: string;
  @Input() autoWidth = false;
  @Input() style: any;
  @Input() clearable = false;
  @Input() showTooltip = false;
  @Input() tooltipText = ''; 

  @Output() onChange: EventEmitter<any> = new EventEmitter();
  @Output() onFocus: EventEmitter<any> = new EventEmitter();
  @Output() onBlur: EventEmitter<any> = new EventEmitter();
  @Output() onClick: EventEmitter<any> = new EventEmitter();
  @Output() onShow: EventEmitter<any> = new EventEmitter();
  @Output() onHide: EventEmitter<any> = new EventEmitter();

  /** Inputs del componente original de primeng que de momento no mapeamos */
  /*
  @Input() filter: boolean;
  @Input() name: string;
  @Input() panelStyle: any;
  @Input() panelStyleClass: string;
  @Input() required: boolean;
  @Input() editable: boolean;
  @Input() tabindex: number;
  @Input() filterPlaceholder: string;
  @Input() filterLocale: string;
  @Input() inputId: string;
  @Input() selectId: string;
  @Input() filterBy = 'label';
  @Input() autofocus: boolean;
  @Input() resetFilterOnHide = false;
  @Input() dropdownIcon = 'pi pi-chevron-down';
  @Input() group: boolean;
  @Input() showClear: boolean;
  @Input() emptyFilterMessage = 'No results found';
  @Input() virtualScroll: boolean;
  @Input() itemSize: number;
  @Input() autoZIndex = true;
  @Input() baseZIndex = 0;
  @Input() showTransitionOptions = '225ms ease-out';
  @Input() hideTransitionOptions = '195ms ease-in';
  @Input() ariaFilterLabel: string;
  @Input() ariaLabelledBy: string;
  @Input() filterMatchMode = 'contains';
  @Input() maxlength: number;
  @Input() tooltip = '';
  @Input() tooltipPosition = 'right';
  @Input() tooltipPositionStyle = 'absolute';
  @Input() tooltipStyleClass: string;
  @Input() autofocusFilter = true;
  */

  value: any = '';


  onChangeFn = (_: any) => { };
  onTouched = (_: any) => { };


  constructor(
    @Self() @Optional() private ngControl: NgControl
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }

  }

  ngOnInit() { }

  /**
   * Write form value to the DOM element (model => view)
   */
  writeValue(value: any): void {
    this.value = value;
  }

  /**
   * Write form disabled state to the DOM element (model => view)
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Update form when DOM element value changes (view => model)
   */
  registerOnChange(fn: any): void {
    // Store the provided function as an internal method.
    this.onChangeFn = fn;
  }

  /**
   * Update form when DOM element is blurred (view => model)
   */
  registerOnTouched(fn: any): void {
    // Store the provided function as an internal method.
    this.onTouched = fn;
  }

  onChangeValue(event) {
    this.onChangeFn(this.value);
    this.onChange.emit(this.value);
  }

}
