import { Component, OnInit, Input, Output, Self, Optional, EventEmitter } from '@angular/core';
import { NgControl, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'pplus-radio-button-item',
  templateUrl: './pplus-radio-button-item.component.html',
  styleUrls: ['./pplus-radio-button-item.component.scss']
})
export class PplusRadioButtonItemComponent implements OnInit, ControlValueAccessor {

  @Input() label;
  @Input() name: string;
  @Input() disabled: boolean;
  @Input() value;
  @Input() formGroupParent = null;
  @Input() formControlName = null;

  selected = null;

  @Output() onClick: EventEmitter<any> = new EventEmitter();
  @Output() onFocus: EventEmitter<any> = new EventEmitter();
  @Output() onBlur: EventEmitter<any> = new EventEmitter();


  onChangeFn = (_: any) => { };
  onTouched = (_: any) => { };

  constructor(
    @Self() @Optional() private ngControl: NgControl
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }

  }

  ngOnInit() {
  }

     /**
      * Write form value to the DOM element (model => view)
      */
     writeValue(value: any): void {
        // this.value = value;
      }

      /**
       * Write form disabled state to the DOM element (model => view)
       */
      setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
      }

      /**
       * Update form when DOM element value changes (view => model)
       */
      registerOnChange(fn: any): void {
        // Store the provided function as an internal method.
        this.onChangeFn = fn;
      }

      /**
       * Update form when DOM element is blurred (view => model)
       */
      registerOnTouched(fn: any): void {
        // Store the provided function as an internal method.
        this.onTouched = fn;
      }

      onClickValue(event) {
        this.onChangeFn(this.value);
        this.onClick.emit(this.value);
     }

     onChange() {
        this.onChangeFn(this.selected);
        this.onClick.emit(this.selected);
     }
}
