import { Component, Input, OnInit } from '@angular/core';
import { MirrorHorizontalBarData } from './mirrorHorizontalBarData';

@Component({
  selector: 'pplus-mirror-horizontal-bar-chart',
  templateUrl: './mirror-horizontal-bar-chart.component.html',
  styleUrls: ['./mirror-horizontal-bar-chart.component.scss']
})
export class PplusMirrorHorizontalBarChartComponent implements OnInit {

  @Input() leftColor = '#a3e8dd';
  @Input() rightColor = '#e3eb98';
  @Input() data: MirrorHorizontalBarData;
  separators = [0, 40, 80, 120, 160];
  errorSeparator = 95;
  separatorsDistance = 150;

  constructor() { }

  ngOnInit() {
  }

  getSeparatorsWidth(i: number) {
    return i * this.separatorsDistance;
  }

  calcBarWidth(data: number) {
    let hundredWidthPx = (100 * this.separatorsDistance) / 40;
    return (hundredWidthPx * data) / 100;
  }

  calculateErrorSeparatorPosition() {
    return (this.errorSeparator * this.separatorsDistance) / 40;
  }

  isBarError(data: number) {
    return data > this.errorSeparator;
  }

}
