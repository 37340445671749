import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class CultureInterceptor implements HttpInterceptor {

  constructor(
  ) {}


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.headers.has('X-Skip-CultureInterceptor')) {
        const headers = req.headers.delete('X-Skip-CultureInterceptor');
        return next.handle(req.clone({ headers }));
    }

    if (abp) {

        let culture = abp.localization.currentLanguage.name || abp.utils.getCookieValue('Abp.Localization.CultureName');
        if (!culture) {
            return next.handle(req);
        } else {
            let headers = req.headers;
            headers = headers.set('X-Culture', culture);
            return next.handle(req.clone({headers}));
        }

    } else {
        return next.handle(req);
    }


  }
}
