import { Component, OnInit, AfterViewInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'pplus-table-edit-number',
  templateUrl: './pplus-table-edit-number.component.html',
  styleUrls: ['./pplus-table-edit-number.component.scss']
})
export class PplusTableEditNumberComponent implements ICellEditorAngularComp, AfterViewInit {
  private params: any;
  public value: number;
  private cancelBeforeStart = false;

  @ViewChild('input', { read: ViewContainerRef, static: false }) public input;

  agInit(params: any): void {
    this.params = params;
    this.value = this.params.value;
  }
  clear() {
    this.value = 0;
    return this.value;
  }

  getValue(): any {
    return this.value;
  }

  // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
  ngAfterViewInit() {
    window.setTimeout(() => {
      this.input.element.nativeElement.focus();
    });
  }
}
