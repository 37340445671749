import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'pplus-input-errors',
  templateUrl: './pplus-input-errors.component.html',
  styleUrls: ['./pplus-input-errors.component.scss']
})
export class PplusInputErrorsComponent implements OnInit {

  @Input() errors;

  constructor() { }

  ngOnInit() {
  }

}
