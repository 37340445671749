import { Component, ElementRef, EventEmitter, Input, OnInit, Optional, Output, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { stat } from 'fs';
import { SelectionModeEnum } from './pplus-calendar-enums';

@Component({
  selector: 'pplus-input-calendar',
  templateUrl: './pplus-calendar-input.component.html',
  styleUrls: ['./pplus-calendar-input.component.scss']
})
export class PplusCalendarInputComponent implements OnInit, ControlValueAccessor {

  //Spanish locale
  es = {
    firstDayOfWeek: 1,
    dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
    dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
    dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
    monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
    monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
    today: 'Hoy',
    clear: 'Borrar'
  };

  @Input() disabled: boolean;
  @Input() placeholder = '';
  @Input() value = '';
  @Input() decimals = false;
  @Input() defaultDate = null;
  @Input() selectionMode: SelectionModeEnum = SelectionModeEnum.SINGLE;
  @Input() dateFormat = 'dd/mm/yy';
  @Input() minDate = null;
  @Input() maxDate = null;
  @Input() locale = this.es;
  @Input() disabledDates = null;
  @Input() disabledDays = null;
  @Input() label: string;
  @Input() readonlyInput = true;


  @Output() onSelect = new EventEmitter();

  SelectionModeEnum = SelectionModeEnum;


  @ViewChild('calendar', { static: false }) calendar;

  constructor(
    @Self() @Optional() private ngControl: NgControl
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }

  }

  ngOnInit() { }

  /**
   * Write form value to the DOM element (model => view)
   */
  writeValue(value: any): void {
    this.value = value;
  }

  /**
   * Write form disabled state to the DOM element (model => view)
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Update form when DOM element value changes (view => model)
   */
  registerOnChange(fn: any): void {
    // Store the provided function as an internal method.
    this.onChange = fn;
  }

  /**∫
   * Update form when DOM element is blurred (view => model)
   */
  registerOnTouched(fn: any): void {
    // Store the provided function as an internal method.
    this.onTouched = fn;
  }

  onIconClicked() {
    this.calendar.inputfieldViewChild.nativeElement.focus();
  }

  onChange(event) { }
  onTouched() { }
}
