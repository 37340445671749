import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PplusTableRangeCellRendererComponent } from '@shared/common/ui/components/pplus-table/pplus-table-range-cell-renderer/pplus-table-range-cell-renderer.component';
import { UtilsModule } from '@shared/utils/utils.module';
import { AgGridModule } from 'ag-grid-angular';
import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { SplitButtonModule} from 'primeng/splitbutton';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { PasswordModule } from 'primeng/password';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButton } from 'primeng/selectbutton';
import { SliderModule } from 'primeng/slider';
import { TabViewModule } from 'primeng/tabview';
import { ToolbarModule } from 'primeng/toolbar';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { PplusChartsModule } from '../charts/pplus-charts.module';
import { PplusAccordion, PplusAccordionTab } from './pplus-accordion/pplus-accordion.component';
import { PplusButtonComponent } from './pplus-button/pplus-button.component';
import { PplusCalendarInputComponent } from './pplus-calendar-input/pplus-calendar-input.component';
import { PplusCheckboxComponent } from './pplus-checkbox/pplus-checkbox.component';
import { PplusChipComponent } from './pplus-chip/pplus-chip.component';
import { PplusComparativeTableComponent } from './pplus-comparative-table/pplus-comparative-table.component';
import { PplusDropdownInfoComponent } from './pplus-dropdown-info/pplus-dropdown-info.component';
import { PplusDropdownComponent } from './pplus-dropdown/pplus-dropdown.component';
import { PplusEnergyCardComponent } from './pplus-energy-card/pplus-energy-card.component';
import { PplusEnergyStickyCardComponent } from './pplus-energy-sticky-card/pplus-energy-sticky-card.component';
import { PplusEntitySelectorComponent } from './pplus-entity-selector/pplus-entity-selector.component';
import { PplusHeaderSelectComponent } from './pplus-header-select/pplus-header-select.component';
import { PplusHorizontalStepperComponent } from './pplus-horizontal-stepper/pplus-horizontal-stepper.component';
import { PplusListTableComponent } from './pplus-list-table/pplus-list-table.component';
import { PplusMatrixCollapsedComponent } from './pplus-matrix-collapsed/pplus-matrix-collapsed.component';
import { PplusMatrixExpandedComponent } from './pplus-matrix-expanded/pplus-matrix-expanded.component';
import { AuthorizationModalComponent } from './pplus-modal/authorization-modal/authorization-modal.component';
import { DeleteModalComponent } from './pplus-modal/delete-modal/delete-modal.component';
import { PplusModalComponent } from './pplus-modal/pplus-modal.component';
import { SuggestionsModalComponent } from './pplus-modal/suggestions-modal/suggestions-modal.component';
import { UploadModalComponent } from './pplus-modal/upload-modal/upload-modal.component';
import { PplusMultiselectComponent } from './pplus-multiselect/pplus-multiselect.component';
import { PplusNumberInputComponent } from './pplus-number-input/pplus-number-input.component';
import { PplusPasswordInputComponent } from './pplus-password-input/pplus-password-input.component';
import { PplusRadioButtonItemComponent } from './pplus-radio-button-item/pplus-radio-button-item.component';
import { PplusRadioButtonComponent } from './pplus-radio-button/pplus-radio-button.component';
import { PplusSelectorButtonComponent } from './pplus-selector-button/pplus-selector-button.component';
import { PplusSlideToggleComponent } from './pplus-slide-toggle/pplus-slide-toggle.component';
import { PplusSliderComponent } from './pplus-slider/pplus-slider.component';
import { PplusStepperComponent } from './pplus-stepper/pplus-stepper.component';
import { PplusSwitchIconsComponent } from './pplus-switch-icons/pplus-switch-icons.component';
import { PplusSwitchInputInfoComponent } from './pplus-switch-input-info/pplus-switch-input-info.component';
import { PplusSwitchInputComponent } from './pplus-switch-input/pplus-switch-input.component';
import { PplusTabPanel, PplusTabView } from './pplus-tabbar/pplus-tabbar.component';
import { PplusBoolFilterComponent } from './pplus-table/pplus-checkbox-floating-filter/pplus-bool-filter/pplus-bool-filter.component';
import { PplusCheckboxFloatingFilter } from './pplus-table/pplus-checkbox-floating-filter/pplus-checkbox-floating-filter';
import { PplusTableButtonLabelSelectedRendererComponent } from './pplus-table/pplus-table-button-label-selected-renderer/pplus-table-button-label-selected-renderer.component';
import { PplusTableCheckboxSelectRendererComponent } from './pplus-table/pplus-table-checkbox-select-renderer/pplus-table-checkbox-select-renderer.component';
import { PplusTableEditCheckboxComponent } from './pplus-table/pplus-table-edit-checkbox/pplus-table-edit-checkbox.component';
import { PplusTableEditInputComponent } from './pplus-table/pplus-table-edit-input/pplus-table-edit-input.component';
import { PplusTableEditNumberComponent } from './pplus-table/pplus-table-edit-number/pplus-table-edit-number.component';
import { PplusTableEditRangeSelectComponent } from './pplus-table/pplus-table-edit-range-select/pplus-table-edit-range-select.component';
import { PplusTableEditRendererComponent } from './pplus-table/pplus-table-edit-renderer/pplus-table-edit-renderer.component';
import { PplusTableEditSelectComponent } from './pplus-table/pplus-table-edit-select/pplus-table-edit-select.component';
import { PplusTableIconRendererComponent } from './pplus-table/pplus-table-icon-renderer/pplus-table-icon-renderer.component';
import { PplusTableLabelValueRendererComponent } from './pplus-table/pplus-table-label-value-cell-renderer/pplus-table-label-value-cell-renderer.component';
import { PplusTableToggleMasterDetailRendererComponent } from './pplus-table/pplus-table-toggle-master-detail-renderer/pplus-table-toggle-master-detail-renderer.component';
import { PplusTableComponent } from './pplus-table/pplus-table.component';
import { PplusTextInputComponent } from './pplus-text-input/pplus-text-input.component';
import { PplusTextareaComponent } from './pplus-textarea/pplus-textarea.component';
import { PplusToastComponent } from './pplus-toast/pplus-toast.component';
import { PplusToggleComponent } from './pplus-toggle/pplus-toogle.component';
import { PplusRoundedToolbarComponent, PplusSquaredToolbarComponent, PplusToolbarComponent } from './pplus-toolbar/pplus-toolbar.component';
import { PplusTooltipComponent } from './pplus-tooltip/pplus-tooltip.component';
import { PplusTreeComponent, PPlusTreeNodeComponent } from './pplus-tree/pplus-tree.component';
import { PplusTristateCheckboxComponent } from './pplus-tristate-checkbox/pplus-tristate-checkbox.component';
import { PPlusReportViewerComponent } from './pplus-report-viewer/pplus-report-viewer.component';
import { PPlusReportDesignerComponent } from './pplus-report-designer/pplus-report-designer.component';
import { PplusTableMoreOptionsRendererComponent } from './pplus-table/pplus-table-more-options-renderer/pplus-table-more-options-renderer.component';
import { PplusDropdownTreeviewComponent } from './pplus-dropdown-treeview/pplus-dropdown-treeview.component';
import { PplusApplicationCardComponent } from './pplus-application-card/pplus-application-card.component';
import { PplusTableHeaderCheckboxComponent } from './pplus-table/pplus-table-header-checkbox/pplus-table-header-checkbox.component';
import { PplusInputTextTranslateComponent } from './pplus-input-text-translate/pplus-input-text-translate.component';
import { PplusSplitButtonComponent } from './pplus-splitbutton/pplus-splitbutton.component';
import { PplusInputErrorsComponent } from './pplus-input-errors/pplus-input-errors.component';
import { PplusFileInputComponent } from './pplus-file-input/pplus-file-input.component';
import { PplusScrollTopComponent } from './pplus-scroll-top/pplus-scroll-top.component';
import { PplusTableDeleteRendererComponent } from './pplus-table/pplus-table-delete-renderer/pplus-table-delete-renderer.component';
import { PplusImageInputComponent } from './pplus-image-input/pplus-image-input.component';
import { PplusTreeStepComponent } from './pplus-tree-step/pplus-tree-step.component';
import { PplusTableTooltipComponent } from './pplus-table/pplus-table-tooltip/pplus-table-tooltip.component';
import { TooltipModule } from 'primeng/tooltip';
import { PplusTableButtonRendererComponent } from './pplus-table/pplus-table-button-renderer/pplus-table-button-renderer.component';
import { PplusStyleViewerComponent } from './pplus-style-viewer/pplus-style-viewer.component';

@NgModule({
  declarations: [
    PplusTextInputComponent,
    PplusFileInputComponent,
    PplusPasswordInputComponent,
    PplusButtonComponent,
    PplusSplitButtonComponent,
    PplusSelectorButtonComponent,
    PplusChipComponent,
    PplusSliderComponent,
    PplusDropdownComponent,
    PplusSwitchInputComponent,
    SelectButton,
    PplusToastComponent,
    PplusDropdownInfoComponent,
    PplusSwitchInputInfoComponent,
    PplusRadioButtonComponent,
    PplusStepperComponent,
    PplusTooltipComponent,
    PplusModalComponent,
    AuthorizationModalComponent,
    UploadModalComponent,
    SuggestionsModalComponent,
    DeleteModalComponent,
    PplusTextareaComponent,
    PplusCheckboxComponent,
    PplusTableComponent,
    PplusTableEditRendererComponent,
    PplusTableDeleteRendererComponent,
    PplusTableEditInputComponent,
    PplusTableEditSelectComponent,
    PplusTableEditRangeSelectComponent,
    PplusTableEditNumberComponent,
    PplusTableEditCheckboxComponent,
    PplusCheckboxFloatingFilter,
    PplusBoolFilterComponent,
    PplusTableToggleMasterDetailRendererComponent,
    PplusTableRangeCellRendererComponent,
    PplusTableLabelValueRendererComponent,
    PplusTableCheckboxSelectRendererComponent,
    PplusTableButtonLabelSelectedRendererComponent,
    PplusTableIconRendererComponent,
    PplusTableButtonRendererComponent,
    PplusMatrixCollapsedComponent,
    PplusMatrixExpandedComponent,
    PplusAccordion,
    PplusAccordionTab,
    PplusMultiselectComponent,
    PplusHorizontalStepperComponent,
    PplusToggleComponent,
    PplusEntitySelectorComponent,
    PplusToolbarComponent,
    PplusSquaredToolbarComponent,
    PplusRoundedToolbarComponent,
    PplusTabView,
    PplusTabPanel,
    PplusRadioButtonItemComponent,
    PplusHeaderSelectComponent,
    PplusEnergyCardComponent,
    PplusSlideToggleComponent,
    PplusSwitchIconsComponent,
    PplusNumberInputComponent,
    PplusCalendarInputComponent,
    PplusComparativeTableComponent,
    PplusListTableComponent,
    PplusTreeComponent,
    PPlusTreeNodeComponent,
    PplusEnergyStickyCardComponent,
    PplusTristateCheckboxComponent,
    PPlusReportViewerComponent,
    PPlusReportDesignerComponent,
    PplusTableMoreOptionsRendererComponent,
    PplusDropdownTreeviewComponent,
    PplusApplicationCardComponent,
    PplusTableHeaderCheckboxComponent,
    PplusInputTextTranslateComponent,
    PplusInputErrorsComponent,
    PplusScrollTopComponent,
    PplusImageInputComponent,
    PplusTreeStepComponent,
    PplusTableTooltipComponent,
    PplusStyleViewerComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    InputTextModule,
    ButtonModule,
    SplitButtonModule,
    SliderModule,
    DropdownModule,
    PasswordModule,
    RadioButtonModule,
    InputTextareaModule,
    CheckboxModule,
    TriStateCheckboxModule,
    AccordionModule,
    MultiSelectModule,
    AgGridModule.withComponents(
      [
        PplusTableEditRendererComponent,
        PplusTableDeleteRendererComponent,
        PplusTableEditInputComponent,
        PplusTableEditCheckboxComponent,
        PplusTableLabelValueRendererComponent,
        PplusTableIconRendererComponent,
        PplusTableRangeCellRendererComponent,
        PplusTableEditSelectComponent,
        PplusTableEditRangeSelectComponent,
        PplusTableToggleMasterDetailRendererComponent,
        PplusCheckboxFloatingFilter,
        PplusBoolFilterComponent,
        PplusTableEditNumberComponent,
        PplusTableCheckboxSelectRendererComponent,
        PplusTableButtonLabelSelectedRendererComponent,
        PplusTableMoreOptionsRendererComponent,
        PplusTableHeaderCheckboxComponent,
        PplusTableButtonRendererComponent
      ]
      ),
    ToolbarModule,
    TabViewModule,
    UtilsModule,
    InputSwitchModule,
    CalendarModule,
    PplusChartsModule,
    TooltipModule
  ],
  exports: [
    PplusTextInputComponent,
    PplusFileInputComponent,
    PplusButtonComponent,
    PplusSplitButtonComponent,
    PplusSelectorButtonComponent,
    PplusChipComponent,
    PplusSliderComponent,
    PplusDropdownComponent,
    PplusPasswordInputComponent,
    PplusSwitchInputComponent,
    PplusToastComponent,
    PplusDropdownInfoComponent,
    PplusSwitchInputInfoComponent,
    PplusRadioButtonComponent,
    PplusStepperComponent,
    PplusTooltipComponent,
    PplusModalComponent,
    PplusTextareaComponent,
    PplusCheckboxComponent,
    PplusTableComponent,
    PplusMatrixCollapsedComponent,
    PplusMatrixExpandedComponent,
    PplusAccordion,
    PplusAccordionTab,
    PplusMultiselectComponent,
    PplusHorizontalStepperComponent,
    PplusToggleComponent,
    PplusEntitySelectorComponent,
    PplusTabView,
    PplusTabPanel,
    PplusRadioButtonItemComponent,
    PplusHeaderSelectComponent,
    PplusEnergyCardComponent,
    PplusSlideToggleComponent,
    PplusSwitchIconsComponent,
    PplusNumberInputComponent,
    PplusCalendarInputComponent,
    PplusComparativeTableComponent,
    PplusListTableComponent,
    PplusTreeComponent,
    PplusSquaredToolbarComponent,
    PplusRoundedToolbarComponent,
    PplusChartsModule,
    PplusEnergyStickyCardComponent,
    PplusTristateCheckboxComponent,
    PPlusReportViewerComponent,
    PPlusReportDesignerComponent,
    PplusDropdownTreeviewComponent,
    PplusApplicationCardComponent,
    PplusInputTextTranslateComponent,
    PplusInputErrorsComponent,
    PplusScrollTopComponent,
    PplusImageInputComponent,
    PplusTreeStepComponent,
    TooltipModule,
    PplusStyleViewerComponent
  ],
  providers: [
    DecimalPipe
  ],
  entryComponents: [
    PplusTextInputComponent,
    PplusPasswordInputComponent,
    PplusButtonComponent,
    PplusSliderComponent,
    PplusDropdownComponent,
    PplusSwitchInputComponent,
    PPlusReportViewerComponent,
    PPlusReportDesignerComponent,
    PplusModalComponent
  ]
})
export class PPlusUIModule { }
