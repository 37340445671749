import { Component, OnInit, Input, Output, EventEmitter, Self, Optional } from '@angular/core';
import { NgControl, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'pplus-radio-button',
  templateUrl: './pplus-radio-button.component.html',
  styleUrls: ['./pplus-radio-button.component.scss']
})
export class PplusRadioButtonComponent implements OnInit, ControlValueAccessor {

  @Input() options: any[];
  @Input() radioButtonName: string;
  @Input() disabled: boolean;
  @Input() value = '';
  @Input() orientation = 'horizontal';

  @Output() onClick: EventEmitter<any> = new EventEmitter();
  @Output() onFocus: EventEmitter<any> = new EventEmitter();
  @Output() onBlur: EventEmitter<any> = new EventEmitter();


  onChangeFn = (_: any) => { };
  onTouched = (_: any) => { };


  constructor(
    @Self() @Optional() private ngControl: NgControl
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }

  }

  ngOnInit() {
  }

     /**
      * Write form value to the DOM element (model => view)
      */
     writeValue(value: any): void {
        this.value = value;
      }

      /**
       * Write form disabled state to the DOM element (model => view)
       */
      setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
      }

      /**
       * Update form when DOM element value changes (view => model)
       */
      registerOnChange(fn: any): void {
        // Store the provided function as an internal method.
        this.onChangeFn = fn;
      }

      /**
       * Update form when DOM element is blurred (view => model)
       */
      registerOnTouched(fn: any): void {
        // Store the provided function as an internal method.
        this.onTouched = fn;
      }

      onClickValue(event) {
        this.onChangeFn(this.value);
        this.onClick.emit(this.value);
     }
}
