import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { HorizontalBarData } from './horizontalBarData';

@Component({
  selector: 'pplus-view-horizontal-bar-chart',
  templateUrl: './horizontal-bar-chart.component.html',
  styleUrls: ['./horizontal-bar-chart.component.scss']
})
export class PplusHorizontalBarChartComponent implements OnInit, OnChanges {

  @Input() data: HorizontalBarData;
  separators = [0, 20, 40, 60, 80];
  legendValue;
  maxValue;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.calcMaxValue();
  }

  calcMaxValue() {
    let arrayValues = [];
    this.data.data.forEach((row) => {
      arrayValues.push(row.reduce((a, b) => a + b));
    });
    let max = Math.max(...arrayValues);
    this.maxValue = Math.round(max / 1000) * 1000;
    if (max > this.maxValue) {
      this.maxValue += 1000;
    }
    this.legendValue = this.maxValue / 4;
  }

  getBarWidth(value: number) {
    return (value / (this.legendValue * 5)) * 100;
  }

}
