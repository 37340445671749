import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pplus-toolbar',
  template: `
      <p-toolbar class="rounded-toolbar">
        <ng-content></ng-content>
      </p-toolbar>
  `,
  styleUrls: ['./pplus-toolbar.component.scss']
})
export class PplusToolbarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

@Component({
    selector: 'pplus-squared-toolbar',
    template: `
        <div class="squared-toolbar">
          <ng-content></ng-content>
        </div>
    `,
    styleUrls: ['./pplus-toolbar.component.scss']
  })
  export class PplusSquaredToolbarComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}

@Component({
    selector: 'pplus-rounded-toolbar',
    template: `
        <div class="rounded-toolbar">
          <ng-content></ng-content>
        </div>
    `,
    styleUrls: ['./pplus-toolbar.component.scss']
  })
  export class PplusRoundedToolbarComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

  }

@Component({
  selector: 'pplus-toolbar-content-left',
  template: `
    <div class="p-toolbar-group-left">
        <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./pplus-toolbar.component.scss']
})
export class PplusToolbarLeftComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

@Component({
  selector: 'pplus-toolbar-content-right',
  template: `
    <div class="p-toolbar-group-right">
        <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./pplus-toolbar.component.scss']
})
export class PplusToolbarRightComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
