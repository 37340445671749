import { Component, Input, OnInit } from '@angular/core';
import { ComparativeDataTypeEnum, ComparativeTableDataModel } from './pplusComparativeDataModel';

@Component({
  selector: 'pplus-comparative-table',
  templateUrl: './pplus-comparative-table.component.html',
  styleUrls: ['./pplus-comparative-table.component.scss']
})
export class PplusComparativeTableComponent implements OnInit {

  @Input() horizontalHeaders: string[];
  @Input() verticalHeaders: string[];
  @Input() data: any[][];
  @Input() dataType: ComparativeDataTypeEnum[];

  ComparativeDataTypeEnum = ComparativeDataTypeEnum;

  constructor() { }

  ngOnInit() {
  }

}
