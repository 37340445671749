import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'pplus-chip',
  templateUrl: './pplus-chip.component.html',
  styleUrls: ['./pplus-chip.component.scss']
})
export class PplusChipComponent implements OnInit {

    @Input() label: string;
    @Input() closable = true;

    @Output() onClick: EventEmitter<any> = new EventEmitter();

    @Output() onClose: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

}
