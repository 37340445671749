import { Component, ElementRef, HostListener, Input, OnChanges, OnInit, Optional, Self, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { TreeNode } from 'primeng/api';

@Component({
    selector: 'pplus-dropdown-treeview',
    templateUrl: './pplus-dropdown-treeview.component.html',
    styleUrls: ['./pplus-dropdown-treeview.component.scss']
})
export class PplusDropdownTreeviewComponent implements OnInit, ControlValueAccessor, OnChanges {

    @ViewChild('dropdownHeader', { static: false }) dropdownHeader: ElementRef;
    @Input() treeData: TreeNode[];
    @Input() selectionData: TreeNode[] = [];
    @Input() selectionMode: string;
    @Input() optionLabel: string;
    @Input() disabled: boolean;
    @Input() options: any[];
    @Input() label: string;
    @Input() style: any;
    @Input() propagateSelectionDown: boolean;
    @Input() propagateSelectionUp: boolean;
    @Input() propagateSelectionOnClick: boolean = false;
    @Input() filter: boolean = false;
    @Input() error = false;
    @Input() errors;
    @Input() separator = false;

    dropdownVisible: boolean = false;
    id: any = Math.floor(Math.random() * 1000);
    firstTimeLoad = true;
    dropDownEl;
    value: number[];
    selection: TreeNode[];



    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (!this._eref.nativeElement.contains(event.target) && !this.dropdownHeader.nativeElement.contains(event.target)) {
            this.dropdownVisible = false;
        }
    }

    constructor(
        @Self() @Optional() private ngControl: NgControl,
        private _eref: ElementRef,
    ) {
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.treeData) {
            this.checkOrganizations(this.treeData, this.value);
        }
    }

    ngOnInit() { }

    toggleDropdown() {
        this.dropdownVisible = !this.dropdownVisible;
    }

    labelFormatter() {
        let string = '';
        if (this.selectionMode === 'single' && this.selectionData !== undefined) {
            return this.selectionData[0] ? this.selectionData[0].label : string;
        } else if (this.selectionMode === 'checkbox' && this.selectionData !== undefined) {
            this.selectionData.forEach(node => string += node.label + ', ');
        }
        if (string.charAt(string.length - 1) === ' ') {
            string = string.slice(0, -2);
        }
        return string;
    }

    checkOrganizations(tree: TreeNode[], ids: number[]) {
        let count = tree.length;
        for (const node of tree) {
            if (ids.includes(node.data) || this.selectionData.includes(node.parent)) {
                this.selectionData.push(node);
                count--;
            }
            if (node.children)
                this.checkOrganizations(node.children, ids);
        }

        if (tree.length > 0 && tree[0].parent) tree[0].parent.partialSelected = (count > 0 && count != tree.length);

    }

    /**
     * Write form value to the DOM element (model => view)
     */
    writeValue(value: number | number[]): void {
        this.selectionData = [];
        this.value = Array.isArray(value) ? value : [value];
        if (this.treeData) {
            this.checkOrganizations(this.treeData, this.value);
        }
    }

    /**
     * Write form disabled state to the DOM element (model => view)
     */
    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    /**
     * Update form when DOM element value changes (view => model)
     */
    registerOnChange(fn: any): void {
        // Store the provided function as an internal method.
        this.onChangeFn = fn;
    }

    /**
     * Update form when DOM element is blurred (view => model)
     */
    registerOnTouched(fn: any): void {
        // Store the provided function as an internal method.
        this.onTouched = fn;
    }

    onChangeValue(newSelection) {
        this.selectionData = newSelection;
        if (!Array.isArray(this.selectionData)) {
            this.selection = [this.selectionData];
        } else {
            this.selection = [...this.selectionData];
        }
        this.selectionData = [...this.selection];
        this.onChangeFn(this.getSelectionIds());
    }

    getSelectionIds(): number | number[] {
        let ids = [];
        this.selection.forEach((node) => {
            if(node !== null){
                ids.push(node.data);
            }
        })
        if (this.selectionMode === 'single') {
            if(ids[0] === undefined){
                return null;
            }
            return ids[0];
        }
        return ids;
    }

    getSelectionData() {
        if (this.selectionMode === 'single') {
            return this.selectionData[0];
        }
        else if (this.selectionMode === 'checkbox') {
            return this.selectionData;
        }
    }

    onChangeFn = (_: any) => { };
    onTouched = (_: any) => { };

}
