import { Component, OnInit, Input, Self, Optional, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'pplus-slider',
  templateUrl: './pplus-slider.component.html',
  styleUrls: ['./pplus-slider.component.scss']
})
export class PplusSliderComponent implements OnInit, ControlValueAccessor {
    @Input() disabled = false;
    @Input() min = 0;
    @Input() max = 100;
    @Input() step = 1;
    @Input() orientation = 'horizontal';
    @Input() styleClass = '';
    @Output() onChange: EventEmitter<any> = new EventEmitter();
    @Output() onSlideEnd: EventEmitter<any> = new EventEmitter();

    value: any = '';


    onChangeFn = (_: any) => { };
    onTouched = (_: any) => { };


    constructor(
        @Self() @Optional() private ngControl: NgControl
      ) {
        if (this.ngControl) {
          this.ngControl.valueAccessor = this;
        }

      }

      ngOnInit() {
        this.ngControl.control.valueChanges.subscribe(v => {
            this.value = v;
        });
      }

      /**
       * Write form value to the DOM element (model => view)
       */
      writeValue(value: any): void {
        this.value = value;
      }

      /**
       * Write form disabled state to the DOM element (model => view)
       */
      setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
      }

      /**
       * Update form when DOM element value changes (view => model)
       */
      registerOnChange(fn: any): void {
        // Store the provided function as an internal method.
        this.onChangeFn = fn;
      }

      /**
       * Update form when DOM element is blurred (view => model)
       */
      registerOnTouched(fn: any): void {
        // Store the provided function as an internal method.
        this.onTouched = fn;
      }

      change(event) {
        this.onChangeFn(this.value);
        this.onChange.emit(this.value);
      }

      slideEnd(event) {
        this.onSlideEnd.emit(event);
      }


}
