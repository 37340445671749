import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';

@Injectable({
    providedIn: 'root'
})
export class PplusSettingsService {

    logo: string;
    baseUrl: string;
    private customColors: CustomColors;
    constructor(
        private http: HttpClient,
        @Inject(DOCUMENT) private _document: HTMLDocument,) {
    }

    loadSettings(): Promise<any> {


        return this.http.get(AppConsts.remoteServiceBaseUrl + '/api/services/app/CustomTheme/GetSettings')
            .toPromise()
            .then((response: any) => {
                if (response.result) {
                    this.logo = response.result.logoBase64;
                    // this.configSubject.next(this.config);
                    // this.loadStyles(response.result.styleSheet);
                    this.loadColors(response.result.customColors);
                    if (response.result.favicon) {
                        this.loadFavicon(response.result.favicon);
                    }
                }
            })
            .catch((err: any) => {
                this.logo = null;
                // this.configSubject.next(this.config);
            });

    }

    private loadColors(customColors?: CustomColors) {
        if (customColors === null || customColors === undefined) {
            customColors = {
                primary: '#ff9400',
                secondary: '#1f2027',
                tertiary: '#a0a5ba'
            };
        } else {
            if (customColors.secondary === null || customColors.secondary === undefined) {
                customColors.secondary = '#1f2027';
            }
            if (customColors.tertiary === null || customColors.tertiary === undefined) {
                customColors.tertiary = '#a0a5ba';
            }
        }
        this.customColors = customColors;
        let colorsStyle = this.createColorsStyle(customColors);
        let colors = document.createElement('style');
        colors.appendChild(document.createTextNode(colorsStyle));
        document.head.appendChild(colors);
    }

    private loadStyles(styles: string) {
        let style = document.createElement('style');
        style.appendChild(document.createTextNode(styles));
        document.head.appendChild(style);
    }

    private loadFavicon(favicon: string) {
        this._document.getElementById('favicon').setAttribute('href', favicon);
    }

    setLogo(base64String: string) {
        this.logo = base64String;
    }

    getLogo() {
        return this.logo;
    }

    getThemeColors() {
        return this.customColors;
    }

    resetLocalStorage() {
        localStorage.removeItem(AppConsts.localStorageKeys.Organizations);
    }

    createColorsStyle(customColors: CustomColors) {
        const amountLightenDarken = 20;
        const amountDarkestLightest = 30;
        return `:root,#root {
            --pplus-primary-color: ${customColors.primary}!important;
            --pplus-secondary-color: ${customColors.secondary}!important;
            --pplus-tertiary-color: ${customColors.tertiary}!important;
            --pplus-primary-color-dark: ${this.lightenDarkenColor(customColors.primary, - amountLightenDarken)}!important;
            --pplus-secondary-color-dark: ${this.lightenDarkenColor(customColors.secondary, - amountLightenDarken)}!important;
            --pplus-tertiary-color-dark: ${this.lightenDarkenColor(customColors.tertiary, - amountLightenDarken)}!important;
            --pplus-primary-color-light: ${this.lightenDarkenColor(customColors.primary, amountLightenDarken)}!important;
            --pplus-secondary-color-light: ${this.lightenDarkenColor(customColors.secondary, amountLightenDarken)}!important;
            --pplus-tertiary-color-light: ${this.lightenDarkenColor(customColors.tertiary, amountLightenDarken)}!important;
            --pplus-primary-color-darkest: ${this.lightenDarkenColor(customColors.primary, - amountDarkestLightest)}!important;
            --pplus-secondary-color-darkest: ${this.lightenDarkenColor(customColors.secondary, - amountDarkestLightest)}!important;
            --pplus-tertiary-color-darkest: ${this.lightenDarkenColor(customColors.tertiary, - amountDarkestLightest)}!important;
            --pplus-primary-color-lightest: ${this.lightenDarkenColor(customColors.primary, amountDarkestLightest)}!important;
            --pplus-secondary-color-lightest: ${this.lightenDarkenColor(customColors.secondary, amountDarkestLightest)}!important;
            --pplus-tertiary-color-lightest: ${this.lightenDarkenColor(customColors.tertiary, amountDarkestLightest)}!important;
        }`;
    }

    lightenDarkenColor(color, amount) {

        var usePound = false;

        if (color[0] == "#") {
            color = color.slice(1);
            usePound = true;
        }

        var num = parseInt(color, 16);

        var r = (num >> 16) + amount;

        if (r > 255) r = 255;
        else if (r < 0) r = 0;

        var b = ((num >> 8) & 0x00FF) + amount;

        if (b > 255) b = 255;
        else if (b < 0) b = 0;

        var g = (num & 0x0000FF) + amount;

        if (g > 255) g = 255;
        else if (g < 0) g = 0;

        return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);

    }
}

export interface CustomColors {
    primary: string;
    secondary?: string;
    tertiary?: string;
}