import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UtilsModule } from '@shared/utils/utils.module';
import * as echarts from 'echarts';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgxEchartsModule } from 'ngx-echarts';
import { PplusAreaChartComponent } from './area-chart/area-chart.component';
import { PplusBarChartComponent } from './bar-chart/bar-chart.component';
import { PplusBarLineComponent } from './bar-chart/period-bars/bar-line/bar-line.component';
import { PplusPeriodBarsComponent } from './bar-chart/period-bars/period-bars.component';
import { PplusDonutChartComponent } from './donut-chart/donut-chart.component';
import { PplusDonutRoundedChartComponent } from './donut-rounded-chart/donut-rounded-chart.component';
import { PplusHorizontalBarChartComponent } from './horizontal-bar-chart/horizontal-bar-chart.component';
import { PplusHorizontalStackedBarChartComponent } from './horizontal-stacked-bar-chart/horizontal-stacked-bar-chart.component';
import { PplusLineChartComponent } from './line-chart/line-chart.component';
import { PplusMirrorHorizontalBarChartComponent } from './mirror-horizontal-bar-chart/mirror-horizontal-bar-chart.component';
import { PplusStepLineChartComponent } from './step-line-chart/step-line-chart.component';
import { PplusVerticalBarChartComponent } from './vertical-bar-chart/vertical-bar-chart.component';



@NgModule({
  declarations: [
    PplusLineChartComponent,
    PplusAreaChartComponent,
    PplusBarChartComponent,
    PplusDonutRoundedChartComponent,
    PplusMirrorHorizontalBarChartComponent,
    PplusHorizontalBarChartComponent,
    PplusHorizontalStackedBarChartComponent,
    PplusPeriodBarsComponent,
    PplusBarLineComponent,
    PplusVerticalBarChartComponent,
    PplusDonutChartComponent,
    PplusStepLineChartComponent
  ],
  imports: [
    CommonModule,
    NgApexchartsModule,
    UtilsModule,
    NgxEchartsModule.forRoot({
      echarts,
    }),
  ],
  exports: [
    PplusLineChartComponent,
    PplusAreaChartComponent,
    PplusBarChartComponent,
    PplusDonutRoundedChartComponent,
    PplusMirrorHorizontalBarChartComponent,
    PplusHorizontalBarChartComponent,
    PplusHorizontalStackedBarChartComponent,
    PplusVerticalBarChartComponent,
    PplusDonutChartComponent,
    PplusStepLineChartComponent
  ]
})
export class PplusChartsModule { }
