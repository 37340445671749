import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, IAfterGuiAttachedParams, RowNode } from 'ag-grid-community';

@Component({
  selector: 'pplus-table-button-label-selected-renderer',
  templateUrl: './pplus-table-button-label-selected-renderer.component.html',
  styleUrls: ['./pplus-table-button-label-selected-renderer.component.scss']
})
export class PplusTableButtonLabelSelectedRendererComponent implements ICellRendererAngularComp {

  params: any;
  selected = false;


  constructor() { }
  refresh(params: any): boolean {
    return false;
  }
  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.selected = this.params.node.isSelected();
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    //TODO
  }

  toggle() {
    const row: RowNode = this.params.node;
    row.setSelected(!row.isSelected());
    this.params.api.redrawRows();
  }

}
