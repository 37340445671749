import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, IAfterGuiAttachedParams } from 'ag-grid-community';

@Component({
  selector: 'pplus-table-delete-renderer',
  templateUrl: './pplus-table-delete-renderer.component.html',
  styleUrls: ['./pplus-table-delete-renderer.component.scss']
})
export class PplusTableDeleteRendererComponent implements ICellRendererAngularComp {

  params: any;
  editActive = false;

  constructor() { }
  refresh(params: any): boolean {
    //TODO
    return false;
  }
  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    //TODO
  }

  doDelete() {
      this.params.api.updateRowData({ remove: [this.params.node.data] });
  }

}
