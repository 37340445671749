import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { ModalType } from '@app/shared/common/pplus/models/modalType';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-pplus-modal',
  templateUrl: './pplus-modal.component.html',
  styleUrls: ['./pplus-modal.component.scss']
})
export class PplusModalComponent implements OnInit, OnDestroy {

  @Input() type: ModalType;
  @Input() title: string;
  @Input() text: string;
  @Input() explanation: string;

  @Output() onClose = new EventEmitter();
  buttonSubmitLabel;
  buttonCancelLabel;
  headerIcon: string;
  modalType = ModalType;

  buttonClicked = false;


  constructor(
    private _bsModalRef: BsModalRef,
    private appLocalizationService: AppLocalizationService
    ) { 
      this.buttonCancelLabel = this.appLocalizationService.l('T_CANCEL');
      this.buttonSubmitLabel = this.appLocalizationService.l('T_SUBMIT');
    }

  ngOnInit() {
    this.getModalConfig();
  }
  
  setType(type: ModalType) {
    this.type = type;
    this.getModalConfig();
  }

  getModalConfig() {
    switch (this.type) {
      case ModalType.AUTHORIZATION:
        this.headerIcon = 'pplus-icon-save';
        this.buttonSubmitLabel = 'SUBMIT PASSWORD';
        this.title = 'You need an authorization.';
        this.text = 'Please enter your password below.';
        break;
      case ModalType.DELETE:
        this.headerIcon = 'pplus-icon-trash';
        this.buttonSubmitLabel = this.appLocalizationService.l('T_SUBMIT');
        this.title = 'Permanentaly delete file';
        this.text = 'Are you sure you want to delete file permanently?';
        break;
      case ModalType.SUGGESTION:
        this.headerIcon = 'pplus-icon-pencil';
        this.buttonSubmitLabel = 'LEAVE SUGGESTIONS';
        break;
      case ModalType.UPLOAD:
        this.headerIcon = 'pplus-icon-file';
        this.buttonSubmitLabel = 'UPLOAD FILES';
        this.title = 'Upload your attachments';
        this.text = 'Drag and drop your files here';
        break;
      case ModalType.INFO:
        this.headerIcon = 'pplus-icon-info';
        this.buttonSubmitLabel = this.appLocalizationService.l('T_SUBMIT');
        break;
    }
  }

  onCancelClicked() {
    this.buttonClicked = true;
    this.onClose.emit(false);
    this._bsModalRef.hide();
  }

  onConfirmClicked() {
    this.buttonClicked = true;
    this.onClose.emit(true);
    this._bsModalRef.hide();
  }

  ngOnDestroy(): void {
    if (!this.buttonClicked) {
      this.onClose.emit(false);
    }
  }
}


