import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'pplus-splitbutton',
  templateUrl: './pplus-splitbutton.component.html',
  styleUrls: ['./pplus-splitbutton.component.scss']
})
export class PplusSplitButtonComponent implements OnInit {

    @Input() label: string;

    @Input() disabled: boolean;

    @Input() items: any[];

    @Input() style: any;

    @Input() styleClass: string;

    @Output() onClick: EventEmitter<any> = new EventEmitter();


  constructor() { }

  ngOnInit() {
  }

}
