import { Component, Input, OnInit } from '@angular/core';
import { CustomColors } from '@app/shared/common/pplus/services/pplus-settings.service';

@Component({
  selector: 'pplus-style-viewer',
  templateUrl: './pplus-style-viewer.component.html',
  styleUrls: ['./pplus-style-viewer.component.scss']
})
export class PplusStyleViewerComponent implements OnInit {

  @Input() colors: CustomColors;
  expanded = false;

  constructor() { }

  ngOnInit() {
  }

}
