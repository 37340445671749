import { Component, ElementRef } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-pplus-table-header-checkbox',
  templateUrl: './pplus-table-header-checkbox.component.html',
  styleUrls: ['./pplus-table-header-checkbox.component.scss']
})
export class PplusTableHeaderCheckboxComponent implements IHeaderAngularComp {

  public params: any;
  public sorted: string;
  private elementRef: ElementRef;
  allSelected: boolean;

  constructor(elementRef: ElementRef) {
    this.elementRef = elementRef;
  }

  agInit(params: any): void {
    this.params = params;
    this.params.api.addEventListener('selectionChanged', () => {
      this.checkSelection();
      
    });

  }

  toggleSelection(allSelected: boolean) {
    this.params.api.forEachNode((row, index) => {
      this.params.api.getRowNode(row.id).selectThisNode(allSelected);
    });
  }

  checkSelection() {
    let all = true;
    this.params.api.forEachNode((row) => {
      if(!row.selected) {
        all = false;
      }
      
    });
    this.allSelected = all;
  }


  refresh(params): boolean {
    return true;
  }

}
