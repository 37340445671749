import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'pplus-image-input',
  templateUrl: './pplus-image-input.component.html',
  styleUrls: ['./pplus-image-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PplusImageInputComponent),
      multi: true
    }
  ]
})
export class PplusImageInputComponent implements ControlValueAccessor {

  @Input() placeholder = 'T_IMAGE';
  @Input() disabled = false;
  @Input() showTooltip = false;
  @Input() tooltipText = '';
  accept = 'image/*';

  @Output() load = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();

  files: any = [];
  url: string;

  imgURL;

  constructor(
    private _sanitizer: DomSanitizer,
  ) { }

  uploadFile(event) {
    if (!event) {
      return;
    }
    const reader = new FileReader();
    const readerBase64 = new FileReader();
    readerBase64.readAsDataURL(event); // read file as data url

    readerBase64.onload = () => { // called once readAsDataURL is completed
      this.url = readerBase64.result as string;
      const base64 = readerBase64.result as string;
      this.files = [];
      this.files.push(event.name);
      this.propagateChange(base64);
      reader.readAsBinaryString(event);
    };

    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      this.load.emit(bstr);
    };
    setTimeout(() => {
      this.imgURL = this._sanitizer.bypassSecurityTrustResourceUrl(this.url);
    }, 150);
  }

  deleteAttachment(event) {
    event.stopPropagation();
    this.files.splice(0, 1);
    this.imgURL = undefined;
    this.propagateChange(null);
    this.delete.emit();
  }

  writeValue(value: any): void {
    if (value) {
      if (!value.includes('data:image')) {
        this.imgURL = this._sanitizer.bypassSecurityTrustUrl('data:image/svg+xml;base64,' + value);
      }
      this.imgURL = this._sanitizer.bypassSecurityTrustResourceUrl(value);
    } else {
      this.imgURL = undefined;
      this.files = [];
    }
  }

  propagateChange = (_: any) => { };
  propagateTouch = (_: any) => { };

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}
