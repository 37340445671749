import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';

@Component({
  selector: 'pplus-step-line-chart',
  templateUrl: './step-line-chart.component.html',
  styleUrls: ['./step-line-chart.component.scss']
})
export class PplusStepLineChartComponent implements OnChanges {

  @Input() data: StepLineChartData;
  @Input() toolboxVisible = false;

  chartOptions = {
    tooltip: {
      trigger: 'axis'
    },
    grid: {
      left: '1%',
      right: '1%',
      bottom: '3%',
      containLabel: true
    },
    toolbox: {
      feature: {
        saveAsImage: {}
      }
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: []
    },
    yAxis: {
      type: 'value'
    },
    series: [],
    color: []
  };

  constructor(
    private _appLocalizationService: AppLocalizationService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.chartOptions.series = this.getSeries();
    this.chartOptions.xAxis.data = this.data.steps ? this.data.steps : [];
    this.chartOptions.toolbox = this.setToolbox();
  }

  getSeries() {
    const series = [];
    let serie: StepLineSeriesItem;
    this.data.data.forEach((line, index) => {
      serie = {
        data: line.data,
        step: 'middle', // start, middle or end
        type: 'line',
        name: line.name,
        color: line.color,
        lineStyle: {
          width: 4
        }
      }
      series.push(serie);
    });
    return series;
  }

  setToolbox() {
    return this.toolboxVisible ? {
      feature: {
        dataZoom: {
          yAxisIndex: 'none',
          title: {
            zoom: this._appLocalizationService.l('Ampliar'),
            back: this._appLocalizationService.l('Reducir')
          }
        },
        restore: {
          title: this._appLocalizationService.l('Volver al estado inicial'),
        },
        saveAsImage: {
          title: this._appLocalizationService.l('Guardar'),
        }
      },
      emphasis: {
        iconStyle: {
          borderColor: '#ff9400'
        }
      }
    } : null;
  }


}

export interface StepLineChartData {
  data: StepLineItemChartData[],
  steps?: string[]
}

export interface StepLineItemChartData {
  name?: string,
  data: number[],
  color: string

}

export interface StepLineSeriesItem {
  name?: string,
  type: string,
  step: string,
  data: number[],
  color: string,
  lineStyle: any
}
