import { Component, Input } from '@angular/core';

@Component({
    selector: 'pplus-toggle',
    styleUrls: ['./pplus-toggle.component.scss'],
    template: `
        <div class="pplus-toggle">
            <div class="pplus-toggle-header">
                <h2 *ngIf="title">{{title}}</h2>
                <a (click)="toogle()" class="horizontal-toggle"><span *ngIf="!opened">{{buttonCollapsedText}}</span><span *ngIf="opened">{{buttonOpenedText}}</span><i class="fa" [ngClass]="{'fa-angle-down':!opened, 'fa-angle-up':opened}"></i></a>
            </div>
            <div class="pplus-toggle-content" *ngIf="opened">
                <ng-content></ng-content>
            </div>
        </div>
    `
})
export class PplusToggleComponent {

    @Input() title: string;
    @Input() opened = true;
    @Input() buttonCollapsedText = 'Ocultar';
    @Input() buttonOpenedText = 'Mostrar';

    toogle() {
        this.opened = !this.opened;
    }
}
