import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'pplus-file-input',
  templateUrl: './pplus-file-input.component.html',
  styleUrls: ['./pplus-file-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PplusFileInputComponent),
      multi: true
    }
  ]
})
export class PplusFileInputComponent implements ControlValueAccessor {

  @Input() placeholder = 'T_LOADTEMPLATE';
  @Input() accept = '*/*';

  @Output() load = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();

  files: any = [];
  url: string;

  uploadFile(event) {

    const reader = new FileReader();
    const readerBase64 = new FileReader();

    readerBase64.readAsDataURL(event); // read file as data url

    readerBase64.onload = () => { // called once readAsDataURL is completed
      this.url = readerBase64.result as string;
      const base64 = readerBase64.result as string;
      this.files = [];
      this.files.push(event.name);
      this.propagateChange(base64);
      reader.readAsBinaryString(event);
    };

    reader.onload = (e: any) => {
        const bstr: string = e.target.result;
        this.load.emit(bstr);
    };
  }

  deleteAttachment(event) {
    event.stopPropagation();
    this.files.splice(0, 1);
    this.delete.emit();
  }

  writeValue(obj: any): void {

  }

  propagateChange = (_: any) => {};
  propagateTouch = (_: any) => {};

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {

  }

}
