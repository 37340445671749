import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-pplus-tooltip',
  templateUrl: './pplus-tooltip.component.html',
  styleUrls: ['./pplus-tooltip.component.scss']
})
export class PplusTooltipComponent implements OnInit {

  @Input() message: any; //two properties: title and text
  @Input() style: string; //orange or black

  constructor() { }

  ngOnInit() {
  }

}
