import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-bar-line',
  templateUrl: './bar-line.component.html',
  styleUrls: ['./bar-line.component.scss']
})
export class PplusBarLineComponent implements OnInit {

  @Input() data: number[];
  @Input() color = '#E34A51';
  maxHeight = 300;
  opacity = 0.3;

  constructor() { }

  ngOnInit() {
  }

  getHeight(item: number) {
    return (item * this.maxHeight) / 100 + 'px';
  }

  getOpacity(index: number) {
    return ((100 - (index * 20)) / 100);
  }
  getBackgroundColor(index: number) {
    let backgroundColor = this.color;
    switch (index) {
      case 0:
        backgroundColor = this.color + 'FF';
        break;
      case 1:
        backgroundColor = this.color + 'E5';
        break;
      case 2:
        backgroundColor = this.color + 'CC';
        break;
      case 3:
        backgroundColor = this.color + 'B2';
        break;
      case 4:
        backgroundColor = this.color + '99';
        break;
      case 5:
        backgroundColor = this.color + '7F';
        break;

    }
    return backgroundColor;
  }
}
