export enum ReportMode {
  VIEWER,
  DESIGNER
}

export interface DialogReportData {
  mode: ReportMode;
  source: any;
  options: any;
}
