import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, IAfterGuiAttachedParams } from 'ag-grid-community';

@Component({
  selector: 'pplus-table-label-value-cell-renderer',
  templateUrl: './pplus-table-label-value-cell-renderer.component.html',
  styleUrls: ['./pplus-table-label-value-cell-renderer.component.scss']
})
export class PplusTableLabelValueRendererComponent implements ICellRendererAngularComp {

  params: any;

  constructor() { }
  refresh(params: any): boolean {
    //TODO
    return false;
  }
  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    //TODO
  }

}


