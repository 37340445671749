
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { AnimationEvent } from '@angular/animations';

import { AlertData, ALERT_CONFIG_TOKEN, AlertConfig } from './alert-config';
import { AlertRef } from './alert-ref';
import { alertAnimations, AlertAnimationState } from './alert-animation';

@Component({
  selector: 'pplus-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['alert.component.scss'],
  animations: [alertAnimations.fadeAlert],
})
export class AlertComponent implements OnInit, OnDestroy {
  animationState: AlertAnimationState = 'default';
  iconType: string;

  private intervalId: any;

  constructor(
    readonly data: AlertData,
    readonly ref: AlertRef,
    @Inject(ALERT_CONFIG_TOKEN) private alertConfig: AlertConfig
    ) {
      this.iconType = data.type === 'success' ? 'done' : data.type;
  }

  ngOnInit() {
    this.intervalId = setTimeout(() => this.animationState = 'closing', 5000);
  }

  ngOnDestroy() {
    clearTimeout(this.intervalId);
  }

  close() {
    this.ref.close();
  }

  onFadeFinished(event: AnimationEvent) {
    const { toState } = event;
    const isFadeOut = (toState as AlertAnimationState) === 'closing';
    const itFinished = this.animationState === 'closing';

    if (isFadeOut && itFinished) {
      this.close();
    }
  }
}
