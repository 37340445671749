import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SlideColors } from '../../components/pplus-slide-toggle/pplus-slide-colors-enum';

@Component({
  selector: 'pplus-area-chart',
  templateUrl: './area-chart.component.html',
  styleUrls: ['./area-chart.component.scss']
})
export class PplusAreaChartComponent implements OnInit, OnChanges {

  @Input() data: LineChartData;

  lineWidth = 1;

  chartOptions = {
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'cross',
            label: {
                backgroundColor: '#6a7985'
            }
        }
    },
    xAxis: {
      type: 'category',
      data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
    },
    yAxis: {
      type: 'value',
      splitLine: {
      }
    },
    legend: [{
        show: false
    }],
    series: [],
    color: ['#ffffff', '#ffffff']
  };


  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    this.setData();
  }

  setData() {
    this.chartOptions.xAxis.data = this.data.divisors;
    let series: LineEchartSerie[] = [];
    this.data.series.forEach((serie) => {
      let echartSerie: any = {
        showSymbol: false,
        data: serie.data,
        type: 'line',
        smooth: true,
        emphasis: {
            focus: 'series'
        },
        areaStyle: {
            color: serie.color,
            opacity: 0.5
        },
        lineStyle: {
          color: serie.color,
          width: 1,
          type: 'solid'
        },
        name: serie.name ? serie.name : ''
      };
      series.push(echartSerie);
    });
    this.chartOptions.series = series;
    this.chartOptions.legend[0] =  {...this.chartOptions.legend[0], ...this.data.legend};
    this.chartOptions = {...this.chartOptions, ...this.chartOptions};
  }

  ngOnInit() {
  }

  setSerie() {

  }

}

export interface LineChartData {

  series: LineChartSerie[];
  divisors: string[];
  legend?: any;

}

export interface LineChartSerie {
  data: number[];
  color: string;
  width?: number;
  name?: string;
}

export interface LineEchartSerie {
  data: number[];
  type: string;
  name?: string;
  areaStyle?: any;
  lineStyle: {
    width?: number,
    color: string,
    type?: string
  }
}
