import { NgModule, ModuleWithProviders } from '@angular/core'
import { OverlayModule } from '@angular/cdk/overlay';
import { MatIconModule } from '@angular/material/icon';

import { AlertComponent } from './alert.component';
import { defaultAlertConfig, ALERT_CONFIG_TOKEN } from './alert-config';
import { PPlusUIModule } from '../../../../shared/common/ui/components/pplus-ui.module';

@NgModule({
  imports: [OverlayModule, PPlusUIModule],
  declarations: [AlertComponent],
  entryComponents: [AlertComponent]
})
export class PPlusAlertModule {
  public static forRoot(config = defaultAlertConfig): ModuleWithProviders {
        return {
            ngModule: PPlusAlertModule,
            providers: [
                {
                    provide: ALERT_CONFIG_TOKEN,
                    useValue: { ...defaultAlertConfig, ...config },
                },
            ],
        };
    }
 }
