import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';

@Component({
  selector: 'pplus-filter-organization-form',
  templateUrl: './filter-organization-form.component.html',
  styleUrls: ['./filter-organization-form.component.css']
})
export class FilterOrganizationFormComponent implements OnInit {

    @Output() splitOptions = new EventEmitter();

    columns: any;
    selectedAll: any = false;

    templateForm = this.formBuilder.group({
      templateName: ['', [Validators.required]]
    });

    constructor(
        private formBuilder: FormBuilder,
        private _appLocalizationService: AppLocalizationService) {
      this.columns = [
        { name: this._appLocalizationService.l('ID'), selected: false },
        { name: this._appLocalizationService.l('NAME'), selected: false },
        { name: this._appLocalizationService.l('CODE'), selected: false },
        { name: this._appLocalizationService.l('DESCRIPTION'), selected: false },
        { name: this._appLocalizationService.l('CREATIONDATE'), selected: false },
      ];
    }

    selectAll(event: any) {
      this.selectedAll = event;
      for ( let i = 0; i < this.columns.length; i++) {
        this.columns[i].selected = this.selectedAll;
      }
    }
    checkIfAllSelected() {
      this.selectedAll = this.columns.every(function (item: any) {
        return item.selected === true;
      });
    }

    close() {
      this.closeSplit('update');
    }

    closeSplit(mode) {
      const opt = {
        open: true,
        direction: 'horizontal',
        contentSize: 100,
        formSize: 0,
      };
      this.splitOptions.emit({ opt, mode });
    }

    ngOnInit() {}

    onSubmit() {
        // TODO: Use EventEmitter with form value
        console.warn('this.templateForm.value');
    }

}
