import { Component, OnInit, Input, Self, Optional } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'pplus-input-text',
  templateUrl: './pplus-text-input.component.html',
  styleUrls: ['./pplus-text-input.component.scss']
})
export class PplusTextInputComponent implements OnInit, ControlValueAccessor {
  @Input() disabled: boolean;
  @Input() readonly = false;
  @Input() placeholder = '';
  @Input() iconClass = '';
  @Input() styleClass: object;
  @Input() value = '';
  @Input() labelFixed = null;
  @Input() labelFixedPosition = '';
  @Input() closable = false;
  @Input() label: string;
  @Input() error = false;
  @Input() errors;

  constructor(
    @Self() @Optional() private ngControl: NgControl
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }

  }

  ngOnInit() {
    if (this.ngControl) {
        this.ngControl.control.valueChanges.subscribe(v => {
            this.value = v;
        });
    }
  }

  /**
   * Write form value to the DOM element (model => view)
   */
  writeValue(value: any): void {
      this.value = value;
  }

  /**
   * Write form disabled state to the DOM element (model => view)
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Update form when DOM element value changes (view => model)
   */
  registerOnChange(fn: any): void {
    // Store the provided function as an internal method.
    this.onChange = fn;
  }

  /**
   * Update form when DOM element is blurred (view => model)
   */
  registerOnTouched(fn: any): void {
    // Store the provided function as an internal method.
    this.onTouched = fn;
  }

  getStyle() {
    return {
      ...this.styleClass,
      'p-filled': this.value !== '' || this.value.length > 0
    };
  }

  onChange(event) { }
  onTouched() { }

}
