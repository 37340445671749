import { Component, Input, OnInit } from '@angular/core';
import { BarChartGradient } from '../bar-chart-model';

@Component({
  selector: 'app-period-bars',
  templateUrl: './period-bars.component.html',
  styleUrls: ['./period-bars.component.scss']
})
export class PplusPeriodBarsComponent implements OnInit {

  @Input() data: BarChartGradient;
  colors = [
    '#f6a700', '#db393f', '#0b349b', '#6ca53a', '#60379d'
  ];

  constructor() { }

  ngOnInit() {
  }

  getColor(index: number) {
    let i = index % this.colors.length;
    return this.colors[i];
  }
}
