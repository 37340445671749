import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'pplus-button',
  templateUrl: './pplus-button.component.html',
  styleUrls: ['./pplus-button.component.scss']
})
export class PplusButtonComponent implements OnInit {

    @Input() type: string;

    @Input() iconPos = 'left';

    @Input() icon: string;

    @Input() label: string;

    @Input() disabled: boolean;

    @Input() style: any;

    @Input() styleClass: string;

    @Output() onClick: EventEmitter<any> = new EventEmitter();

    @Output() onFocus: EventEmitter<any> = new EventEmitter();

    @Output() onBlur: EventEmitter<any> = new EventEmitter();


  constructor() { }

  ngOnInit() {
  }

}
