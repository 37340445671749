import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'pplus-scroll-top',
  templateUrl: './pplus-scroll-top.component.html',
  styleUrls: ['./pplus-scroll-top.component.scss']
})
export class PplusScrollTopComponent implements OnInit {

  @Input() viewScrolled: boolean;
  @Output() goTop = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  getYPosition(e: Event): number {
    return (e.target as Element).scrollTop;
  }

  scrollToTop() {
    this.goTop.emit();
  }

}
