import { Component, OnInit } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { BsModalRef } from 'ngx-bootstrap';
import { Subject } from 'rxjs';
import { Plant } from '../pplus/models/plant';
import { Installation } from '../pplus/models/installation';
import { PplusPlantsService } from '../pplus/services/pplus-plants.services';


@Component({
  selector: 'pplus-installation-selector-dialog',
  templateUrl: './installation-selector-dialog.component.html',
  styleUrls: ['./installation-selector-dialog.component.scss']
})
export class InstallationSelectorDialogComponent implements OnInit {

    public onClose: Subject<any>;

    mappedPlants: TreeNode[] = null;
    public selectedInstallation: TreeNode;
    public installation: Installation = null;

  constructor(public bsModalRef: BsModalRef, private plantsService: PplusPlantsService ) {
    plantsService.getPlants().subscribe( (plants: Plant[]) => {
        this.mappedPlants = this.mapPlants(plants);

        if (this.installation && this.installation.id !== null) {
            this.mappedPlants.forEach( plant => {
                plant.children.forEach( installation => {
                    if (installation.data.id === this.installation.id) {
                        this.selectedInstallation = installation;
                    }
                });
            });
        }
    });
    this.onClose = new Subject();
  }

  ngOnInit() {
  }

  mapPlants(plants: Plant[]): TreeNode[] {
      return plants.map( plant => {

            const children = this.mapInstallations(plant.installations);

            return <TreeNode> {
                label: plant.name,
                data: plant,
                expandedIcon: 'fa fa-folder-open',
                collapsedIcon: 'fa fa-folder',
                children: children,
                selectable: false,
                emptyMessage: 'Sin instalaciones',
                expanded: true,
                key: 'plant_' + (plant.id !== null ? plant.id.toString() : '')
            };
      });
  }

  mapInstallations(installations: Installation[]): TreeNode[] {
    return installations.map( installation => {

        return <TreeNode> {
            label: installation.name,
            icon: 'fa fa-file-image-o',
            key: 'inst_' + installation.id !== null ? installation.id.toString() : '',
            data: installation

        };
    });
  }

  selectNode() {
      this.onClose.next({
          plant: this.selectedInstallation.parent.data,
          installation: this.selectedInstallation.data,
        });
      this.bsModalRef.hide();
  }

  setInstallation(installation: Installation) {
    this.installation = installation;
  }

  expandAll(){
      this.mappedPlants.map((node: TreeNode) => node.expanded = true);
  }

  collapseAll(){
    this.mappedPlants.map((node: TreeNode) => node.expanded = false);
 }

}
