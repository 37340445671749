import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StimulsoftComponent } from './stimulsoft/stimulsoft.component';
import { DynamicDialogModule } from 'primeng/components/dynamicdialog/dynamicdialog';

@NgModule({
  declarations: [
    StimulsoftComponent,
  ],
  imports: [
    CommonModule,
    DynamicDialogModule
  ],
  entryComponents: [
    StimulsoftComponent
  ]
})
export class PPlusReportsModule { }
