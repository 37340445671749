import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SlideColors } from '../pplus-slide-toggle/pplus-slide-colors-enum';

@Component({
  selector: 'pplus-energy-card',
  templateUrl: './pplus-energy-card.component.html',
  styleUrls: ['./pplus-energy-card.component.scss']
})
export class PplusEnergyCardComponent implements OnInit {

  @Input() label = '';
  @Input() value = 0;
  @Input() unit = '';
  @Input() switchColor;
  @Input() selectOptions;
  @Input() active = true;
  selectedValue;

  @Output() toggleChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

}
