import { PermissionCheckerService } from '@abp/auth/permission-checker.service';
import { AppSessionService } from '@shared/common/session/app-session.service';

import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';
import { HttpClient } from '@angular/common/http';
import { AppConsts } from '@shared/AppConsts';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppAvailableModel, AppModel } from '@shared/common/ui/components/pplus-application-card/appModel';
import { result } from 'lodash';

@Injectable()
export class AppNavigationService {
    menu: AppMenu = new AppMenu('MainMenu', 'MainMenu', []);
    menu$: BehaviorSubject<AppMenu>;
    constructor(
        private http: HttpClient,
        private _permissionCheckerService: PermissionCheckerService,
        private _appSessionService: AppSessionService
    ) {
        this.menu$ = new BehaviorSubject(this.menu);
        this.getNavigationMenu().subscribe( menu => {
            this.menu = menu;
            this.menu$.next(this.menu);
        });
    }

    getNavigationMenu(): Observable<AppMenu> {
        return this.http.get(AppConsts.remoteServiceBaseUrl + '/api/services/app/Applications/GetTree').pipe(
            map((response: any) => {
                const items: AppMenuItem[] = this.generateMenuItemsFromJson(response.result.applications);
                return new AppMenu('MainMenu', 'MainMenu', items);
                }
            ));
    }

    getAvailableMenu(): Observable<AppAvailableModel[]> {
        return this.http.get(AppConsts.remoteServiceBaseUrl + '/api/services/app/Applications/GetAvailable').pipe(map((data: any) => {
            return data.result.items;
        }));
    }

    getMenu(): Observable<AppMenu> {
        return this.menu$;



        /*
        return new AppMenu('MainMenu', 'MainMenu', [
            new AppMenuItem('Dashboard', 'Pages.Administration.Host.Dashboard', 'flaticon-line-graph', '/app/admin/hostDashboard'),
            new AppMenuItem('Dashboard', 'Pages.Tenant.Dashboard', 'flaticon-line-graph', '/app/main/dashboard'),
            new AppMenuItem('Tenants', 'Pages.Tenants', 'flaticon-list-3', '/app/admin/tenants'),
            new AppMenuItem('Editions', 'Pages.Editions', 'flaticon-app', '/app/admin/editions'),
            new AppMenuItem('Administration', '', 'flaticon-interface-8', '', [
                new AppMenuItem('OrganizationUnits', 'Pages.Administration.OrganizationUnits', 'flaticon-map', '/app/admin/organization-units'),
                new AppMenuItem('Roles', 'Pages.Administration.Roles', 'flaticon-suitcase', '/app/admin/roles'),
                new AppMenuItem('Users', 'Pages.Administration.Users', 'flaticon-users', '/app/admin/users'),
                new AppMenuItem('Languages', 'Pages.Administration.Languages', 'flaticon-tabs', '/app/admin/languages'),
                new AppMenuItem('AuditLogs', 'Pages.Administration.AuditLogs', 'flaticon-folder-1', '/app/admin/auditLogs'),
                new AppMenuItem('Maintenance', 'Pages.Administration.Host.Maintenance', 'flaticon-lock', '/app/admin/maintenance'),
                new AppMenuItem('Subscription', 'Pages.Administration.Tenant.SubscriptionManagement', 'flaticon-refresh', '/app/admin/subscription-management'),
                new AppMenuItem('VisualSettings', 'Pages.Administration.UiCustomization', 'flaticon-medical', '/app/admin/ui-customization'),
                new AppMenuItem('Settings', 'Pages.Administration.Host.Settings', 'flaticon-settings', '/app/admin/hostSettings'),
                new AppMenuItem('Settings', 'Pages.Administration.Tenant.Settings', 'flaticon-settings', '/app/admin/tenantSettings')
            ]),
            new AppMenuItem('DemoUiComponents', 'Pages.DemoUiComponents', 'flaticon-shapes', '/app/admin/demo-ui-components'),
            new AppMenuItem('Selfview', '', 'flaticon-interface-8', '', [
                new AppMenuItem('Active', 'Pages.DemoUiComponents', 'flaticon-shapes', '/app/admin/selfview/active'),
                new AppMenuItem('Totalizer' , 'Pages.DemoUiComponents', 'flaticon-shapes', '/app/admin/selfview/totalizer'),
                new AppMenuItem('Weather Station' , 'Pages.DemoUiComponents', 'flaticon-shapes', '/app/admin/selfview/weather-station')
            ]),
        ]);
        */
    }

    checkChildMenuItemPermission(menuItem): boolean {

        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName === '' || subMenuItem.permissionName === null || subMenuItem.permissionName && this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                return true;
            } else if (subMenuItem.items && subMenuItem.items.length) {
                return this.checkChildMenuItemPermission(subMenuItem);
            }
        }

        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' && this._appSessionService.tenant && !this._appSessionService.tenant.edition) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (this._appSessionService.tenant || !abp.multiTenancy.ignoreFeatureCheckForHostUsers) {
            if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
                hideMenuItem = true;
            }
        }

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }

    /**
     * Returns all menu items recursively
     */
    getAllMenuItems(): AppMenuItem[] {
        let menu = this.menu;
        let allMenuItems: AppMenuItem[] = [];
        menu.items.forEach(menuItem => {
            allMenuItems = allMenuItems.concat(this.getAllMenuItemsRecursive(menuItem));
        });

        return allMenuItems;
    }

    private getAllMenuItemsRecursive(menuItem: AppMenuItem): AppMenuItem[] {
        if (!menuItem.items) {
            return [menuItem];
        }

        let menuItems = [menuItem];
        menuItem.items.forEach(subMenu => {
            menuItems = menuItems.concat(this.getAllMenuItemsRecursive(subMenu));
        });

        return menuItems;
    }

    generateMenuItemsFromJson(items: any[]): AppMenuItem[] {
        const appMenuItems:  AppMenuItem[] = [];
        items.forEach( item => {
            let childs = [];
            if (item.items){
                childs = this.generateMenuItemsFromJson(item.items);
            }
            const appMenuItem: AppMenuItem = new AppMenuItem(item.name, item.permissionName, item.icon, item.route, childs);
            appMenuItem.id = item.id;
            appMenuItems.push(appMenuItem);
        });

        return appMenuItems;
    }
}
