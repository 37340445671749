import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { PplusInputTextTranslateModel, PplusTranslationModel } from './translations';

@Component({
  selector: 'pplus-input-text-translate',
  templateUrl: './pplus-input-text-translate.component.html',
  styleUrls: ['./pplus-input-text-translate.component.scss']
})
export class PplusInputTextTranslateComponent implements OnInit, ControlValueAccessor {

  @Input() value: PplusInputTextTranslateModel = { defaultValue: ''};
  @Input() disabled = false;
  @Input() label;
  @Input() error = false;
  @Input() errors;
  defaultValue: PplusInputTextTranslateModel;
  editMode = false;

  constructor(
    @Self() @Optional() private ngControl: NgControl,
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }


  ngOnInit() {
  }

  enterEditMode() {
    this.setDefaultValue();
    this.editMode = true;
  }

  setDefaultValue() {
    let defaultTranslations: PplusTranslationModel[] = [];
    this.value.translations.forEach((translation) => {
      defaultTranslations.push(
        {
          language: `${translation.language}`,
          value: `${translation.value}`,
          icon: `${translation.icon}`
        }
      );
    })
    this.defaultValue = {
      defaultValue: `${this.value.defaultValue}`,
      translations: defaultTranslations
    };
  }

  save() {
    this.editMode = false;
  }

  emitChanges() {
    this.onChange(this.value);
  }

  cancel() {
    this.value = this.defaultValue;
    this.onChange(this.defaultValue);
    this.editMode = false;
  }



  /**
   * Write form value to the DOM element (model => view)
   */
  writeValue(value: any): void {
    this.value = value;
  }

  /**
   * Write form disabled state to the DOM element (model => view)
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Update form when DOM element value changes (view => model)
   */
  registerOnChange(fn: any): void {
    // Store the provided function as an internal method.
    this.onChange = fn;
  }

  /**
   * Update form when DOM element is blurred (view => model)
   */
  registerOnTouched(fn: any): void {
    // Store the provided function as an internal method.
    this.onTouched = fn;
  }

  onChange(event) { }
  onTouched() { }

}

