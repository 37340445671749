import { Component, OnInit, AfterViewInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';

@Component({
  selector: 'pplus-table-edit-range-select',
  templateUrl: './pplus-table-edit-range-select.component.html',
  styleUrls: ['./pplus-table-edit-range-select.component.scss']
})
export class PplusTableEditRangeSelectComponent implements ICellEditorAngularComp, AfterViewInit {
  private params: any;
  public value: number;
  private cancelBeforeStart = false;
  options: any[] = [];
  @ViewChild('input', { read: ViewContainerRef, static: false }) public input;

  agInit(params: any): void {
    this.params = params;
    this.getOptions();
    this.value = this.params.value;
  }
  clear() {
    this.value = 0;
    return this.value;
  }

  getValue(): any {
    console.log(this.value);
    return this.value;
  }

  isPopup(){
    return false;
  }

  ngAfterViewInit() {

  }

  getOptions() {
    const options = [];
    const min = +this.params.node.data[this.params.min];
    const max = +this.params.node.data[this.params.max];
    for (let i = min; i <= max; i++  ) {
        options.push({label: i, value: i});
    }

    this.options = options;
  }
}
