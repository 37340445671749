import { Component, EventEmitter, Input, OnDestroy, OnInit, Optional, Output } from "@angular/core";
import { Subject } from "rxjs";

@Component({
    selector: 'app-tab-wrapper-item',
    styleUrls: ['tab-wrapper-item.component.scss'],
    templateUrl: 'tab-wrapper-item.component.html'
  })
export class TabWrapperItemComponent  implements OnInit, OnDestroy {

    @Input() tab: any;
    @Input() active: boolean;
    @Output() onClose = new EventEmitter<null>();
    @Output() onEdit = new EventEmitter<string>();
    @Output() onSelect = new EventEmitter<null>();

    isEditMode = false;
    name: string;
    previousName: string = null;
    public destroyed = new Subject<any>();

    constructor(
    ) {

    }

    ngOnInit(): void {
        this.name = this.tab.instance.tabI.name;

        if (this.tab.instance.isNameEditable) {
            this.tab.instance.tabI.ref.instance.name$.subscribe( name => {
                if (name) {
                    this.name = name;
                }
            });
        }
    }

    ngOnDestroy(): void {
        this.destroyed.next();
        this.destroyed.complete();
    }

    selectTab() {
        this.onSelect.emit();
    }

    closeTab() {
        this.onClose.emit();
    }

    setEditMode() {
        this.previousName = this.name;
        this.isEditMode = true;
    }

    editTabName() {
        if (this.name && this.name.trim().length) {
            this.isEditMode = false;
            this.onEdit.emit(this.name);
        }
    }

    cancelEditMode() {
        this.isEditMode = false;
        this.name = this.previousName;
    }


}
